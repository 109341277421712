//========================================
//TODO cristela ==> clean media queries
.banner-info {
  position: relative;
  display: grid;
  align-items: center;
  border-radius: $radius-default;
  box-sizing: border-box;
  overflow: hidden;
  height: 100px;
  padding: $spacing-1 $spacing-2;
  background: $color-blue--light-ter;
  @include mq('tablet') {
    //border-radius: 100px;
    grid-template-columns: 80px auto;
    grid-template-areas: 'svg text';
    padding: 0 $spacer-16;
  }
  @include mq('desktop') {
    height: 120px;
    grid-template-columns: 15% 85%;
  }

  img {
    display: none;
    @include mq('tablet') {
      display: block;
      width: 100px;
      height: 100px;
      justify-self: start;
      grid-area: svg;
    }
    @include mq('desktop') {
      width: 120px;
      height: 120px;
      justify-self: flex-end;
    }
  }

  p {
    grid-area: text;
    color: $color-text;
    justify-self: stretch;
    font-weight: 500;
    font-family: $font-source-sans-3;
    text-align: center;
    font-size: $text-small;
    line-height: 16px;
    @include mq('tablet') {
      font-size: $text-default;
      line-height: 20px;
    }
    @include mq('desktop') {
      font-size: $text-xl;
      line-height: 24px;
      white-space: pre-line;
    }
    > span {
      display: block;
      @include mq('mobile-tablet') {
        display: inline;
      }
    }
  }
  .btn {
    width: 320px;
    margin: 8px auto 0;
    @include mq('mobile-only') {
      font-size: 12px;
      width: fit-content;
      padding: 2px $spacing-4;
      margin: 4px auto;
    }
  }
}

.search-results {
  .banner-info {
    margin: $spacer-24 0;
    @include mq('tablet-only') {
      display: flex;
    }
    img {
      @include mq('tablet-only') {
        display: none;
      }
    }
    p {
      @include mq('tablet') {
        padding: 0px;
      }
      @include mq('desktop') {
        padding: 0px 20px;
        font-size: $text-default;
      }
    }
  }
}
