.breadcrumb {
  span {
    &:last-child {
      color: color-ui(main);
      font-weight: 500;
    }
  }

  a {
    &:not(:hover) {
      color: $color-text;
    }
    &:hover {
      color: color-ui(main);
    }
  }
}

div[id^='medicaments-claude-bernard'] .breadcrumb {
  span {
    &:last-child {
      color: color-ui(main, darker);
    }
  }

  a {
    &:not(:hover) {
      color: $color-text;
    }
    &:hover {
      color: color-ui(main, darker);
    }
  }
}
