/* Error messages */
.input-error {
  color: $error;
  margin-top: $spacer-4;
  font: 400 12px/16px $font-source-sans-3;
  letter-spacing: 0.5px;
  margin-left: 0;
  margin-right: 0;
  @include mq('mobile-only') {
    max-width: 100%;
  }
  & + .MuiTextField-root,
  & + .input-wrapper {
    margin-top: 4px;
  }
}

.input-wrapper {
  position: relative;
  width: 100%;
  //overflow: hidden;
  border: none;
  background-color: $input-background;
  border-radius: $radius-xs;
  padding-right: 0;
  &.is-small {
    width: 340px;
  }
  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid color-ui(main);
    pointer-events: none;
  }
  &.Mui-error {
    &:after {
      border-bottom: 2px solid $error;
      transform: scaleX(1);
    }
  }
  &:focus,
  &:hover &:not(.Mui-disabled) {
    &:after {
      transform: scaleX(1);
      border-bottom: 2px solid color-ui(main);
    }
  }
  &.address {
    .input-wrapper {
      height: 40px;
      padding: 0;
      .input-field {
        width: 100%;
        height: 40px;
        border: none;
        padding: $spacer-8;
        font-size: $text-default;
        &::placeholder {
          color: $placeholder;
          opacity: 0.6;
        }
      }
    }
  }
  /* SIMPLE ICON */
  &.with-icon {
    .input-field {
      padding-right: 32px;
    }
  }
  /* DOUBLE ICON */
  &.with-icons {
    .input-field {
      padding-right: 54px;
    }
  }
}
.input-error {
  color: $error;
  margin-top: 4px;
  font-family: $font-source-sans-3;
  font-size: 11.2px;
}
/* ################# */
/* SPECIFIQUE INPUT WITH AUTOCOMPLETE PROPOSITIONS LIST (for search and address) */
/* ################# */
.input-dropdown {
  //dropdown select styling
  div[role='listbox'] > * {
    em {
      color: color-ui(accent);
      font-weight: 600;
      font-style: normal;
      text-decoration: underline;
    }
    & > * {
      display: block;
      text-overflow: ellipsis;
      z-index: $zindex-searchbar;

      &:last-child {
        @include radius(bottom, 30px);
        padding-bottom: 10px;
      }
    }
    .MuiMenuItem-root,
    .MuiSelect-select {
      max-width: 100%;
    }
    .input-field {
      padding-right: 30px;
    }
  }
  &.address {
    div[role='listbox'] > * {
      left: 0;
    }
  }
  .right-icon {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 50%;
    right: $spacing-2;
    transform: translateY(-50%);
    color: $color-grey--medium;
    font-size: 20px;

    > *:not(:hover) {
      opacity: 0.7;
    }
    > * + * {
      margin-left: $spacing-1; // Add space between two following icons
    }
    > svg {
      cursor: pointer;
    }
    .btn-location {
      color: color-ui(main);
    }
  }
  /* Address field */
  &.address {
    /* DOUBLE ICON */
    &.with-icons {
      .input-field {
        padding-right: 54px;
      }
    }
  }
}

.input-field,
textarea {
  position: relative;
  overflow: hidden;
  height: 44px;
  line-height: 44px;
  box-sizing: border-box;
  border: none;
  color: $color-text;
  font-size: $text-large; //Needed for ios that zoom in input fields if under 16px
  font-family: $font-source-sans-3;
  padding: 6px 7px $spacer-8;
  border-radius: $radius-xs;
  width: 100%;
  background-color: $input-background;
  &::placeholder {
    color: $placeholder;
    opacity: 0.6;
  }
  @include mq('tablet') {
    font-size: $text-default;
  }
}
.is-optionnal {
  input {
    padding-right: 85px !important; /* TODO !important A retirer lorsqu'on utilisera plus material-ui*/
  }
  &:after {
    content: 'optionnel';
    position: absolute;
    right: 10px;
    top: 13px;
    font-size: $text-small;
    color: colors(grey, 400);
  }
}

textarea {
  height: 100px;
}
.input {
  position: relative;
  width: 100%;
  input {
    font-size: $title-xs;
    color: $color-text;
    text-overflow: ellipsis;
    &::placeholder {
      text-overflow: ellipsis;
    }
  }
  &--with-icon {
    input {
      position: relative;
      padding-right: 25px;
    }
    .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.intl-tel-input {
  @extend .input-wrapper;

  &:not(.disabled):hover:after {
    content: '';
    transform: scaleX(1);
    border-color: color-ui(main);
  }

  &.phone-error {
    &:after {
      content: '';
      transform: scaleX(1);
      border-color: #d32f2f; // Mui theme error color
    }
  }

  input {
    @extend .input-field;
    &::placeholder {
      color: $color-text;
      opacity: 0.6;
    }
  }
  & + .input-error {
    margin-bottom: 10px;
  }
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $color-text;
  -webkit-box-shadow: none;
  background-color: $input-background !important;
  transition: background-color 5000s ease-in-out 0s;
}

ul.password-instructions {
  margin-top: $spacing-3;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: none;
    width: 50%;
    font-size: 12px;
    list-style-type: disc;
    list-style-position: inside;

    span {
      margin-left: -0.5em;
    }

    &.valid {
      color: color-ui(main);
    }
  }
}
