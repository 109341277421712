.rdv-page {
  max-width: 830px;
  background: $blue-grey-background;
  padding: calc(var(--header-bar) + #{$spacing-4}) $spacing-4 $spacing-4;
  @include mq('tablet') {
    padding: calc(var(--header-bar) + #{$spacing-5}) $spacing-5 $spacing-5;
  }
}
.rdv-page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  .wrapper-buttons {
    flex-direction: row;
    justify-content: center;
    margin: $spacer-16 0 $spacer-24;
    .btn--link {
      margin: 0 $spacer-24;
      text-decoration: underline;
    }
  }
  .btn.is-tlc.killondesk {
    width: 100%;
    max-width: 400px;
    margin: $spacer-8 auto $spacer-24;
  }
  .cardwrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: 'cardinfos';
    .access__infos {
      grid-area: cardinfos;
    }

    @include mq('desktop') {
      grid-template-columns: minmax(330px, 1fr) minmax(200px, 285px);
      grid-template-rows: minmax(300px, auto);
      grid-template-areas: 'cardinfos cardmap';
      grid-column-gap: 8px;
      padding-right: 0;
      .access__map {
        display: block;
      }
    }
  }
  .instruction {
    li {
      list-style: none;
      margin-left: $spacer-12;
      position: relative;
      font-size: $text-default;
      word-break: break-word; // Make sure that intructions containing very long words (ex: url) won't break the layout
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:before {
        content: '';
        height: 6px;
        width: 6px;
        border-radius: 100px;
        position: absolute;
        left: -14px;
        background: $color-grey--medium;
        display: block;
        top: 7px;
      }
    }
  }
  .card {
    border-radius: 10px;
    width: 100%;
    @include mq('mobile-only') {
      padding: $spacer-12 $spacer-8 $spacer-12 $spacer-32;
      margin: $spacer-6 0;
    }
    .title-icon {
      color: $login-link;
    }
    &.phone .title-icon {
      svg {
        @include squaresize(16px);
      }
      h2 {
        margin-bottom: 0;
      }
    }
    &.add-calendar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title-icon > svg {
        top: 9px;
      }
      h2 {
        cursor: pointer;
        margin-bottom: 0;
      }
    }
    &.bloc-highlight.is-tlc {
      display: block;
      margin: 0;
      padding: $spacer-12 35px;
      text-align: left;
      @include mq('tablet') {
        display: grid;
        grid-template-columns: 1fr 250px;
        grid-template-rows: 32px 1fr;
        grid-row-gap: 8px;
        align-items: center;
        grid-template-areas:
          'title btn'
          'text text';
      }
      .title-icon {
        grid-area: title;
        color: $color-text;
      }
      .btn {
        grid-area: btn;
        margin: 0;
      }
      p {
        grid-area: text;
      }
      .link-info {
        font-size: $text-small;
        vertical-align: middle;
        line-height: 24px;
      }
    }
    &.access {
      display: grid;
      grid-template-areas: 'title' 'hr' 'map' 'content';

      grid-column-gap: 20px;
      @include mq('tablet') {
        grid-template-areas:
          'title  title'
          'hr  hr' 'content map';
        //grid-template-columns: 310px auto;
        grid-template-columns: minmax(330px, 1fr) minmax(200px, 285px);
        grid-template-rows: auto 1px minmax(300px, auto);
      }
      .title-icon {
        grid-area: title;
      }
      hr {
        grid-area: hr;
        margin: 0;
      }
      .access__content {
        grid-area: content;
        h4 {
          margin: 20px 0 0;
        }
        @include mq('mobile-only') {
          padding-bottom: 10px;
        }
      }

      .access__map {
        grid-area: map;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
        }
        @include mq('mobile-only') {
          height: 200px;
        }
      }
    }
    &.series-info {
      .paragraph {
        & + .paragraph {
          margin-top: 0;
        }

        .highlight {
          color: color-ui(accent);
        }
      }

      ul {
        margin: $spacer-4 0;

        li {
          position: relative;
          font-weight: 500;
          padding-left: $spacer-16;

          &:before {
            content: '\2022'; // Disc caracter
            color: $color-grey--medium;
            position: absolute;
            font-size: 1.75em;
            left: 0;
            top: 0;
            transform: translate(0, -20%);
          }
        }
      }
    }
    &.equipment-test-advice {
      margin-top: 0;
      border-radius: 0 0 8px 8px;
    }
    &.equipment-test-advice,
    &.microphone-camera-test,
    &.network-test {
      padding: $spacing-4;
      @include mq('tablet') {
        padding: $spacing-5;
      }

      .title-icon {
        padding-left: $spacing-5;

        svg {
          left: 0;
        }
      }
    }
    &.microphone-camera-test {
      p.no-equipment {
        color: $color-grey--medium;
        min-height: $spacing-8;
      }
      select,
      .video-wrapper {
        border-radius: $spacing-1;
        overflow: hidden;
      }
      select {
        margin-bottom: $spacing-4;
      }
      .btn {
        width: max-content;
      }
      .video-wrapper {
        video {
          width: 100%;
          height: auto;
        }
      }

      .microphone-test {
        svg {
          transform: scale(1.3);
        }
        .microphone-record-test {
          margin-top: $spacing-4;

          p {
            margin-bottom: $spacing-1;
          }

          .btn {
            display: inline-block;
            min-width: 0;
            margin-top: $spacing-2;

            &:not(:last-of-type) {
              margin-right: $spacing-2;
            }

            & + .btn {
              margin-bottom: 0;
            }
          }
        }
      }
      .camera-test {
        margin-top: $spacing-5;
      }
    }
    &.network-test {
      margin-bottom: $spacing-6;
    }
  }
  .doc {
    .btn {
      margin: 20px auto 0;
      max-width: 270px;
    }
  }

  .button-row {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq('mobile-only') {
      flex-direction: column-reverse;
      > *:not(:first-child) {
        margin-bottom: 10px;
      }
    }
    > .btn {
      padding: 10px 20px;
      font-size: $text-large;
      font-weight: 500;
      margin: 0 auto;
      transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  > a {
    margin: 0 auto $spacer-16;
    &.btn--link {
      text-decoration: underline;
    }
    & + a {
      margin: $spacer-16 auto;
    }
  }
}

.share-document {
  display: flex;
  flex-direction: row;
  > div {
    cursor: pointer;
    width: 50%;
    background-color: var(--background-color);
    transform: all 0.5 ease;
    margin: 5px;
    &:hover {
      .btn--with-icon {
        svg {
          fill: color-ui(accent);
          opacity: 1;
        }
      }
    }
    .btn--with-icon {
      flex-direction: column;
      margin: 0;
      svg {
        @include squaresize(100px);
        padding: 15px 0 0 0;
        border-radius: 50%;
        background-color: var(--background-color);
        fill: $color-text;
        opacity: 0.6;
      }
    }
    .text {
      padding: 20px;
      text-align: center;
      color: $color-text;
      overflow: hidden;
    }
  }
}
.maiia-gallery {
  .document-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 12px;
    margin: 20px 0;
    grid-row-gap: 16px;
    @include mq('tablet') {
      grid-template-columns: repeat(4, 115px);
    }
    .document-card {
      height: 180px;
      .document-card__content {
        height: 100%;
        background: none;
        padding: 0;
        .document__image {
          display: block;
          height: 150px;
          margin: 0;
          border: 2px solid $color-grey--lite;
          @include mq('tablet') {
            width: 115px;
          }
        }
      }
      &.selected {
        position: relative;
        &:before {
          content: '✓';
          width: 25px;
          height: 25px;
          background: color-ui(accent);
          border-radius: 100%;
          color: $white;
          font-size: 16px;
          line-height: 25px;
          position: absolute;
          right: 8px;
          top: 8px;
          text-align: center;
          font-weight: bold;
        }
        .document__image {
          border: 2px solid color-ui(accent);
        }
      }
    }
  }
  .link {
    color: color-ui(main);
    font-size: 14px;
    &:hover {
      text-decoration: none;
    }
  }
  &.shared-list {
    .document-list {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
      margin: 10px 0 28px;
      @include mq('tablet') {
        grid-template-columns: repeat(5, 1fr);
      }
      .document-card {
        .document-card__content {
          .document__image {
            width: 100%;
            height: 160px;
          }
        }
      }
    }
  }
}