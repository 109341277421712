.pre-footer {
  width: 100%;
  max-width: 1024px;
  margin: $spacing-7 auto $spacing-5;

  > * {
    padding: $spacing-5 0;
    border-top: 2px solid $white;
  }
}

// Customization for "TLC"
#tlcList .pre-footer {
  padding: 0 $spacing-5;
}

// Customization for "TlcStaticPage"
.tlc-static .pre-footer {
  margin-top: 0;
  margin-bottom: 0;
  max-width: unset;

  > * {
    border-top: 2px solid $color-grey--background;
  }
}

// Customization for "ArticlePage"
.article.page .pre-footer {
  margin-bottom: 0;

  > * {
    border-top: 2px solid #d2d2d2;
  }
}

// Customization for "Search"
#search .pre-footer {
  max-width: 1180px;
  padding: 0 18px;
}

// Customization for "HCDCard"
.prat .pre-footer {
  max-width: 1180px;
  padding: 0 10px;
}
