.list-number {
  padding: 0 0 0 $spacer-16;
  list-style: none;
  counter-reset: li;
  li {
    counter-increment: li;
    position: relative;
    font: 400 14px/24px $font-source-sans-3;
    &:before {
      content: counter(li) '.';
      font-weight: 700;
      color: color-ui(main);
      display: inline-block;
      margin-left: -18px;
      top: -2px;
      width: 12px;
      text-align: right;
      position: absolute;
    }
  }
  & + .with-icon {
    margin-left: -$spacer-8;
  }
}
