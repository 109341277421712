.article {
  @import '../wordpress_custom';
  background-color: $color-grey--background;

  .article-header {
    color: $white;
    // b3 = 70%, to compensate for the opacity
    background: linear-gradient(to right, #64c9c3b3, #50b3c8b3);

    .reading-time {
      font-size: $spacing-4;
      font-weight: 600;
      margin-bottom: 0;

      @include mq('desktop') {
        font-size: 20px;
      }
    }
  }

  .wrapper-content {
    max-width: 960px;
    margin: 0 auto;
    padding: $spacing-6 $spacing-5;
    font-size: $spacing-4;

    // Some values are hardcoded as per ticket requirements (MPATEGY-15)
    h1 {
      margin-bottom: $spacing-6;
      font-size: 28px;
    }
    h2 {
      font-size: $spacing-5;
      margin-top: $spacer-40;
      margin-bottom: $spacer-12;
      color: color-ui(main);
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: $spacing-4;
      margin-bottom: $spacer-12;
    }
    ul,
    ol {
      padding-left: $spacer-24;
      margin-bottom: $spacer-12;
    }
    ul > li {
      list-style-type: disc;
    }
    ol > li {
      list-style-type: decimal;
    }
    figure {
      margin-top: $spacer-32;
      margin-bottom: $spacer-32;

      &.aligncenter {
        text-align: center;
      }
      img {
        height: auto;
      }
    }

    .pre-footer {
      font-size: 14px;
    }

    a:not(.pre-footer a) {
      color: color-ui(main, darker);
    }

    @include mq('desktop') {
      font-size: 20px;
      h1 {
        font-size: 44px;
      }
      h2 {
        font-size: $spacing-6;
      }
      h3 {
        font-size: 28px;
      }
      p {
        font-size: 20px;
      }
    }
  }
}
