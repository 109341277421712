.sms-confirmation {
  padding-top: 7vmin;
  margin-top: var(--header-bar);
  .aide-support {
    position: absolute;
    bottom: 45px;
    padding-left: $spacing-4;
    padding-right: $spacing-4;

    a {
      display: inline-block; // Make sure phone number doesn't go on 2 separate lines
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      height: auto;
    }
  }
  .sms-input {
    padding: 5vmin 20px;
    text-align: center;
    .alert {
      justify-content: center;
      word-break: break-all;
    }
    .input-wrapper {
      display: inline-block;
      height: 90px;
      width: 60px;
      @include mq('tablet') {
        height: 110px;
        width: 80px;
      }

      & + * {
        margin-left: 10px;
        @include mq('tablet') {
          margin-left: 15px;
        }
      }

      .sms-number {
        height: 100%;
        font-size: 50px;
        text-align: center;
        @include mq('tablet') {
          font-size: 65px;
        }
      }
    }
  }

  .btn {
    padding: 5px 20px;
    margin-top: 3vh;
  }
}
