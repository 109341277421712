.progress-status-bar {
  height: 8px !important;
  border-radius: $radius-xl;
  @include mq('tablet') {
    margin-top: $spacer-4;
    height: 20px !important;
  }
  &.is-ok {
    background-color: $success-med !important;
    .MuiLinearProgress-bar {
      background-color: $success-dark !important;
    }
  }
  &.is-warning {
    background-color: $warning-med !important;
    .MuiLinearProgress-bar {
      background-color: $warning !important;
    }
  }
  &.is-error {
    background-color: $error-med !important;
    .MuiLinearProgress-bar {
      background-color: $error !important;
    }
  }
  .MuiLinearProgress-bar {
    height: 8px !important;
    @include mq('tablet') {
      height: 20px !important;
    }
  }
}
