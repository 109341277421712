.network-speed {
  p {
    .instructions {
      color: $color-grey--medium;
      margin-left: $spacing-2;
    }
  }
  svg {
    margin-right: $spacing-3;
    transform: scale(1.4) translateY(-10%);
  }

  &.good {
    p {
      color: $success-dark;
    }
  }
  &.average {
    p {
      color: $warning;
    }
  }
  &.bad {
    p {
      color: $error;
    }
  }
}
