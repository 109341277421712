.profile-creation {
  padding: 80px 20px 40px;
  @include mq('tablet') {
    padding: 120px 60px 40px;
  }
  form {
    display: block;
    width: 100%;
    margin-top: $spacing-5;

    & > * {
      width: 100%;
    }

    .conditions {
      display: flex;
      align-items: center;
      .checkbox {
        width: 38px;
        align-items: center;
      }
      .label {
        text-decoration: none;
      }
    }
    .paragraph {
      text-align: center;
      font-weight: 500;
      color: $color-text;
    }
  }
  .input-wrapper {
    &.address {
      .input-field {
        height: 100%;
      }
      > svg {
        fill: $color-grey--medium;
        top: 3px;
        opacity: 0.7;
        &:hover {
          fill: color-ui(main);
        }
      }
    }
  }
  .input-dropdown {
    @extend .input-wrapper;
    @extend .input--with-icon;
    &:after {
      display: none;
    }
    .input-field {
      padding-right: $spacer-32 !important;
    }
  }
  .fieldset-title {
    margin: 30px 0 10px;
    font: 600 16px/24px $font-outfit;
    color: color-ui(main);
    text-align: center;
  }
  .aide-support {
    margin-top: auto;
  }
  .note {
    margin-top: $spacing-4;
    font-size: 11.2px;
  }
  .ctas-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $spacing-6 0 0 0;

    .btn--link {
      padding-left: 0;
    }
  }

  &.confirmation {
    .btn {
      margin-top: $spacing-6;
    }
  }

  &.add-insurance {
    .title {
      margin-bottom: $spacing-6;
    }

    .add-insurance {
      &__stepper {
        margin-bottom: $spacing-8;

        .MuiStepper-root {
          width: $spacing-11;
        }
      }

      &__content {
        width: 100%;
        max-width: $spacing-14;

        form,
        .step {
          margin-top: 0;
        }

        .step__input {
          display: flex;
          flex-direction: column;
          margin-bottom: $spacing-6;

          &:last-of-type {
            margin-bottom: $spacing-8;
          }

          > span {
            text-align: left;
            margin-bottom: $spacing-1;
          }
        }

        .step > .submit {
          width: auto;
          margin: $spacing-3 auto;
        }

        .MuiSelect-select {
          height: 44px;
          box-sizing: border-box;
        }
      }

      &__actions {
        > .btn--with-icon--left {
          padding-left: 0;
          margin-top: 16px;
        }
      }
    }

    // MUI stepper customizations
    .MuiStep-horizontal,
    .MuiStepLabel-iconContainer {
      padding: 0;
    }

    .MuiStepConnector-root {
      .MuiStepConnector-line {
        border-color: color-ui(main);
        opacity: 0.5;
      }

      &.Mui-active,
      &.Mui-completed {
        .MuiStepConnector-line {
          opacity: 1;
        }
      }
    }

    .MuiStepIcon-root {
      color: color-ui(main);
      opacity: 0.5;
    }

    .MuiStepIcon-root.Mui-active,
    .MuiStepIcon-root.Mui-completed {
      opacity: 1;
    }

    .MuiStepIcon-text {
      font-weight: 700;
      fill: white;
    }
  }
}

.popup__content {
  .profile-creation {
    padding: 0;
    margin: 0;
    width: 100%;
    background: $white;
    min-height: unset;
    @include mq('tablet') {
      width: 620px;
    }
    form {
      padding: 0;
      margin: 0;
      .input-field,
      .MuiTextField-root .MuiInputBase-input {
        background-color: $input-background;
      }
      .btn {
        margin: 20px 0 0;
      }
    }
  }
}
