.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: $black-opacity-half;
  z-index: 9;
  .is-white {
    background-color: $white-opacity-seventy;
  }
}
