.slider-dialog {
  .MuiBackdrop-root {
    background-color: $black-opacity-sixty;
  }
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    //align-items: center;
    @include mq('mobile-only') {
      justify-content: center;
    }
    .popup__content {
      position: relative;
      overflow: hidden;
      &:last-child {
        padding: 40px 45px;
      }
      .MuiIconButton-root {
        position: absolute;
        top: -20px;
        right: 24px;
        svg {
          fill: $white;
          height: 36px;
          width: 36px;
        }
        &:hover {
          background-color: unset;
        }
      }
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-dots {
    bottom: -50px;
    position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    @include mq('tablet') {
      display: none !important; //overide inline style
    }
    li {
      position: relative;
      display: inline-block;
      border: none !important; //overide inline style
      width: 48px;
      height: 48px;
      padding: 0;
      margin: 0;
      text-align: center;
      button {
        width: 48px;
        height: 48px;
        border: 0;
        background: transparent;
        display: block;
        color: transparent;
        &:before {
          content: '';
          width: 12px;
          height: 12px;
          background: $white;
          border-radius: $radius-full;
          opacity: 1 !important; //overide inline style
          position: absolute;
          left: calc(50% - 6px);
          top: calc(50% - 6px);
        }
      }
      &.slick-active {
        button:before {
          background: color-ui(accent);
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    color: transparent;
    cursor: pointer;
    border: none;
    width: $spacing-6;
    height: $spacing-6;
    background-color: $white;
    -webkit-mask-image: url(/static/icons/next.svg);
    mask-image: url(/static/icons/next.svg);
    background-size: cover;
    right: -50px;
    transform: translateY(-50%); // Center vertically

    &:before {
      display: none;
    }
    &:hover,
    &:focus {
      background-color: $white;
      -webkit-mask-image: url(/static/icons/next.svg);
      mask-image: url(/static/icons/next.svg);
    }
    @include mq('mobile-only') {
      display: none !important; //overide inline style
    }
  }
  .slick-prev {
    left: -50px;
    transform: translateY(-50%) rotate(180deg);
  }
}
