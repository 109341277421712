/*   PULSE EFFECT */
.pulse {
  display: none;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 0 $accent-opacity-hundred;
  @include squaresize(65px);
  transform: scale(1);
  animation: pulse-accent 2s infinite;
  -webkit-animation: pulse-accent 2s infinite;
  &.white {
    box-shadow: 0 0 0 0 $white-opacity-hundred;
    @include squaresize(41px);
    animation: pulse-white 2s infinite;
    -webkit-animation: pulse-accent 2s infinite;
  }
}

@keyframes pulse-accent {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $accent-opacity-seventy;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px $accent-opacity-zero;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $accent-opacity-zero;
  }
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $white-opacity-seventy;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px $white-opacity-zero;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $white-opacity-zero;
  }
}
@keyframes bounce-bubble-cta {
  0%,
  10%,
  20%,
  30%,
  70%,
  90%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(7px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes pulse-tlc {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes blinker {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pendulumLight {
  0% {
    transform: rotateZ(5deg);
  }
  100% {
    transform: rotateZ(-5deg);
  }
}

@keyframes pendulumMedium {
  0% {
    transform: rotateZ(15deg);
  }
  100% {
    transform: rotateZ(-10deg);
  }
}

@keyframes fadeOutAndPause {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes documentsFirstMove {
  0% {
    transform: translate(-43.04%, 9.41%);
  }
  2% {
    transform: translate(-42.83%, 6.42%);
  }
  4% {
    transform: translate(-42.1%, 3.42%);
  }
  6% {
    transform: translate(-40.85%, 0.53%);
  }
  8% {
    transform: translate(-39.19%, -2.03%);
  }
  10% {
    transform: translate(-37.32%, -4.28%);
  }
  12% {
    transform: translate(-35.03%, -6.31%);
  }
  14% {
    transform: translate(-32.43%, -7.81%);
  }
  16% {
    transform: translate(-29.63%, -9.09%);
  }
  18% {
    transform: translate(-26.72%, -9.84%);
  }
  20% {
    transform: translate(-23.7%, -10.16%);
  }
  22% {
    transform: translate(-20.79%, -10.48%);
  }
  24% {
    transform: translate(-17.67%, -10.16%);
  }
  26% {
    transform: translate(-14.76%, -9.63%);
  }
  28% {
    transform: translate(-11.85%, -9.09%);
  }
  30% {
    transform: translate(-8.94%, -7.81%);
  }
  32% {
    transform: translate(-6.44%, -6.42%);
  }
  34% {
    transform: translate(-3.95%, -4.49%);
  }
  36% {
    transform: translate(-1.77%, -2.46%);
  } /* On the line */
  52% {
    transform: translate(-1.77%, -6.46%);
  } /* Go up */
  68% {
    transform: translate(-1.77%, -2.46%);
  } /* Bounce back */
  84% {
    transform: translate(-1.77%, -6.46%);
  } /* Go up */
  100% {
    transform: translate(-1.77%, -2.46%);
  } /* Bounce back */
}

@keyframes documentsSecondMove {
  0% {
    transform: translate(3.53%, -34.22%);
  }
  2.4% {
    transform: translate(4.99%, -31.44%);
  }
  4.8% {
    transform: translate(6.13%, -28.56%);
  }
  7.2% {
    transform: translate(6.96%, -25.56%);
  }
  9.6% {
    transform: translate(7.38%, -22.46%);
  }
  12% {
    transform: translate(7.59%, -19.36%);
  }
  14.4% {
    transform: translate(7.8%, -16.36%);
  }
  16.8% {
    transform: translate(7.17%, -13.26%);
  }
  19.2% {
    transform: translate(6.55%, -10.27%);
  }
  21.6% {
    transform: translate(5.41%, -7.38%);
  }
  24% {
    transform: translate(3.95%, -4.6%);
  }
  26.4% {
    transform: translate(2.08%, -2.25%);
  }
  28.8% {
    transform: translate(0%, 0%);
  }
  31.2% {
    transform: translate(-2.39%, 1.93%);
  }
  33.6% {
    transform: translate(-4.99%, 3.53%);
  }
  36% {
    transform: translate(-7.69%, 4.71%);
  } /* On the line */
  52% {
    transform: translate(-7.69%, 6.71%);
  } /* Go down */
  68% {
    transform: translate(-7.69%, 3.71%);
  } /* Bounce back */
  84% {
    transform: translate(-7.69%, 6.71%);
  } /* Go down */
  100% {
    transform: translate(-7.69%, 4.71%);
  } /* Bounce back */
}

@keyframes documentsThirdMove {
  0% {
    transform: translate(30.15%, 12.94%);
  }
  3.2% {
    transform: translate(27.55%, 11.44%);
  }
  6.4% {
    transform: translate(25.05%, 9.84%);
  }
  9.6% {
    transform: translate(22.56%, 8.13%);
  }
  12.8% {
    transform: translate(19.85%, 6.63%);
  }
  16% {
    transform: translate(17.15%, 5.24%);
  }
  19.2% {
    transform: translate(14.45%, 4.06%);
  }
  22.4% {
    transform: translate(11.64%, 2.78%);
  }
  25.6% {
    transform: translate(8.84%, 1.93%);
  }
  28.8% {
    transform: translate(5.93%, 0.96%);
  }
  32% {
    transform: translate(3.01%, 0.43%);
  }
  35.2% {
    transform: translate(0%, 0%);
  } /* On the line */
  52% {
    transform: translate(0%, -2%);
  } /* Go up */
  68% {
    transform: translate(0%, 1%);
  } /* Bounce back */
  84% {
    transform: translate(0%, -2%);
  } /* Go up */
  100% {
    transform: translate(0%, 1%);
  } /* Bounce back */
}

@keyframes drugsCursorMove {
  0% {
    transform: translate(43.72%, 55.86%);
  }
  3% {
    transform: translate(39.59%, 55.86%);
  }
  6% {
    transform: translate(35.38%, 55.73%);
  }
  9% {
    transform: translate(31.23%, 55.52%);
  }
  12% {
    transform: translate(27.05%, 55%);
  }
  15% {
    transform: translate(22.89%, 54.31%);
  }
  18% {
    transform: translate(18.74%, 53.39%);
  }
  21% {
    transform: translate(14.73%, 52.11%);
  }
  24% {
    transform: translate(10.86%, 50.27%);
  }
  27% {
    transform: translate(7.43%, 47.55%);
  }
  30% {
    transform: translate(5%, 43.52%);
  }
  33% {
    transform: translate(3.44%, 38.98%);
  }
  36% {
    transform: translate(2.38%, 34.25%);
  }
  39% {
    transform: translate(1.55%, 29.41%);
  }
  42% {
    transform: translate(1%, 24.5%);
  }
  45% {
    transform: translate(0.57%, 19.64%);
  }
  48% {
    transform: translate(0.29%, 14.73%);
  }
  51% {
    transform: translate(0.11%, 9.89%);
  }
  54% {
    transform: translate(-0.05%, 4.89%);
  }
  57% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes drugsBottleMove {
  0% {
    transform: rotateZ(0deg);
  }
  60% {
    transform: rotateZ(0deg);
  }
  70% {
    transform: rotateZ(5deg);
  }
  80% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

/*Create ripple effec*/

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, $white 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}
