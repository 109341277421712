// TODO remove after card refactor
.home-connected {
  .bloc-connected {
    .minicard {
      @include mq('tablet-only') {
        width: 368px; // Make sure we have enough space for two minicard on one row
      }
    }
  }
}
