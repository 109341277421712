.not-found,
.page-error {
  margin: 0 auto;
  padding: 160px 20px 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: $white;
  img {
    max-width: 720px;
  }
  p {
    font: 400 16px/16px $font-source-sans-3;
    color: $color-text;
  }
}

.page-error {
  img {
    max-width: 250px;
    margin-bottom: $spacer-40;
  }
  .title {
    margin-bottom: $spacer-24;
  }
  .btn {
    margin: $spacing-7 auto $spacing-4;
    &--link {
      margin: 0 auto;
    }
  }
}
