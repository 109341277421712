// Screen width vars :

$screen-width-min: 320px;
$screen-width-max: 3200px;

$screen-xsmall-width: 375px;
$screen-small-width: 768px;
$screen-medium-width: 1024px;
$screen-large-width: 1890px;
// MQ vars :

$screen-steps: (
  'xsmall-mobile-only': (
    max-width: $screen-xsmall-width - 1,
  ),
  'mobile-only': (
    max-width: $screen-small-width - 1,
  ),
  'tablet-only':
    'only screen and ( min-width: #{$screen-small-width} ) and ( max-width: #{$screen-medium-width - 1} )',
  'mobile-tablet': (
    max-width: $screen-medium-width - 1,
  ),
  'tablet': (
    min-width: $screen-small-width,
  ),
  'desktop': (
    min-width: $screen-medium-width,
  ),
  'large-desktop': (
    min-width: $screen-large-width,
  ),
  'wide': (
    min-width: $screen-width-max,
  ),
) !default;

// To manage responsive breakpoints
@mixin mq($screen) {
  $raw-query: map-get($screen-steps, $screen);
  // If the key exists in the map
  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );
    @media #{$query} {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$screen}`. "
          + "Available breakpoints are: #{map-keys($steps)}.";
  }
}

.hide-phone {
  @include mq('mobile-only') {
    display: none !important;
  }
}
.hide-mobile {
  @include mq('mobile-tablet') {
    display: none !important;
  }
}
.hide-tablet-only {
  @include mq('tablet-only') {
    display: none !important;
  }
}
.hide-tablet {
  @include mq('tablet') {
    display: none !important;
  }
}

.hide-desktop {
  @include mq('desktop') {
    display: none !important;
  }
}
