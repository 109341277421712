.paper {
  background-color: $white;
  &.blue {
    background-color: $blue-highlight;
  }
  &.lightblue {
    background-color: $center-color;
  }
  &.grey {
    background-color: $color-grey--lite;
  }
  &.float {
    box-shadow: $shadow-condensed-dark;
  }
  &.card {
    border-radius: $radius-default;
    padding: $spacer-12;
  }
}

.dropdown-autocomplete {
  position: absolute;
  left: 30px;
  right: 0;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: $spacer-8;
  @include radius(bottom, 30px);
  hr {
    border: none;
    height: 24px;
  }
}
