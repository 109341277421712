#tarteaucitronRoot {
  // Centered version of the modal
  &.tarteaucitronBeforeVisible {
    z-index: 1300; // Make sure it sits above all the other elements of the website

    // Modal background overlay
    &:before {
      background: $black !important;
    }

    // Modal
    div#tarteaucitronAlertBig {
      padding: $spacing-4 !important;

      &:before {
        content: '🍪';
      }

      &.tarteaucitronAlertBigTop {
        width: 90%;
        max-width: $spacing-16;
        border-radius: $spacing-1;
        box-shadow: none;

        #tarteaucitronDisclaimerAlert {
          margin-bottom: $spacing-4;
        }
      }

      .tarteaucitronCTAS {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        @include mq('tablet') {
          justify-content: center;
          flex-direction: row-reverse;
          flex-wrap: wrap;
        }
      }

      button {
        @include mq('tablet') {
          margin: 0 0 $spacing-1 $spacing-3 !important;
        }

        &.tarteaucitronDeny {
          .tarteaucitronCross {
            display: none;
          }
        }
      }
    }
  }
  // Bottom alert
  #tarteaucitronAlertBig {
    padding: $spacing-2 !important;
    background: $white !important;
    box-shadow: 0px -3px 5px rgb(0 0 0 / 12%);
    box-sizing: border-box !important; // Make sure padding is included in width

    &.tarteaucitronAlertBigBottom:before {
      display: none;
    }

    * {
      font-family: $font-source-sans-3 !important;
    }

    #tarteaucitronDisclaimerAlert {
      color: $color-text;
      font-size: $text-default;
      line-height: 1.35;
      text-align: center;
      margin-bottom: $spacing-2;
    }

    button {
      font-size: $text-default !important;
      line-height: 18px;
      font-weight: 500;
      @include mq('mobile-only') {
        margin: $spacing-2 0 0 0; // Add space between buttons
      }
      @include mq('tablet-only') {
        margin: $spacing-2;
      }

      &.tarteaucitronAllow,
      &.tarteaucitronDeny,
      &#tarteaucitronCloseAlert {
        padding: $spacing-1 $spacing-4 !important;
        border: 2px solid transparent !important;
        border-radius: $radius-button !important;

        &:hover {
          color: $white;
        }
      }

      &.tarteaucitronAllow {
        background-color: color-ui(accent) !important;
        &:hover {
          background-color: color-ui(accent, dark) !important;
        }
      }
      &.tarteaucitronDeny,
      &#tarteaucitronCloseAlert {
        color: color-ui(accent);
        border-color: color-ui(accent) !important;
        background-color: transparent !important;
        &:hover {
          background-color: color-ui(accent, dark) !important;
          border-color: color-ui(accent, dark) !important;
        }
      }
      &#tarteaucitronPrivacyUrl {
        color: color-ui(main);
        margin-left: $spacing-2;
        margin-bottom: 0;

        &:hover {
          text-decoration: underline;
          color: color-ui(main, dark);
        }
      }
    }
  }

  // Modal overlay
  button#tarteaucitronBack {
    background-color: $black-opacity-half !important;
    opacity: 1 !important;
  }

  #tarteaucitron {
    overflow-y: auto;
    @include mq('mobile-only') {
      background: none !important;
    }

    // Close button
    button#tarteaucitronClosePanel {
      position: absolute;
      top: $spacing-2;
      right: $spacing-2;
      width: $spacing-5;
      height: $spacing-5;
      font-size: 0; // Hide default "Close" text
      padding: 0;
      background: none;
      z-index: 1; // Make sure it sits above the modal content

      // Replace default text with a closing icon
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-text;
        -webkit-mask-image: url(/static/icons/close.svg);
        mask-image: url(/static/icons/close.svg);
      }
    }

    // Modal
    #tarteaucitronServices {
      .tarteaucitronPlus {
        display: none !important;
      }
      height: fit-content !important;
      background-color: $white;
      margin-top: 0 !important; // Remove original place for closing button
      @include mq('mobile-only') {
        padding-top: $spacing-4; // Add space for closing button
        box-shadow: none;
      }
      @include mq('tablet') {
        border-radius: $spacing-1;
      }

      * {
        font-family: $font-source-sans-3 !important;
      }

      .tarteaucitronH1,
      .tarteaucitronH2 {
        font-weight: 700;
      }
      .tarteaucitronH1 {
        color: $title-color;
      }
      .tarteaucitronH2 {
        color: $color-text;
      }
      .tarteaucitronH3 {
        font-size: 12px !important;
      }

      .tarteaucitronMainLine,
      .tarteaucitronBorder {
        background: none !important;
        border: none !important;
      }

      .tarteaucitronMainLine {
        #tarteaucitronInfo {
          color: $color-text;
          padding: 0 !important;
          background: none;
          border: none;

          button {
            color: color-ui(main);
            font-size: $text-default !important;
            line-height: 18px;
            font-weight: 500;
            padding: $spacing-1 $spacing-5 !important;
            border: 2px solid color-ui(main) !important;
            border-radius: $radius-button !important;

            &:hover {
              color: $white;
              background-color: color-ui(main);
            }
          }
        }
      }

      .tarteaucitronLine {
        .tarteaucitronAsk {
          button {
            font-size: $text-default !important;
            line-height: 18px;
            font-weight: 500;
            padding: $spacing-1 $spacing-5 !important;
            border: 2px solid transparent !important;
            border-radius: $radius-button !important;
            opacity: 0.5;

            &.tarteaucitronAllow {
              background-color: color-ui(main) !important;
            }
            &.tarteaucitronDeny {
              background-color: color-ui(accent) !important;
            }
            &:hover,
            &:disabled {
              opacity: 1;
            }
          }
        }
      }

      .tarteaucitronBorder {
        background: $color-grey--background !important;

        & > ul {
          padding: 0 $spacing-4 $spacing-4 $spacing-4;
        }

        .tarteaucitronTitle {
          button {
            font-weight: 500;
            color: $color-text;
            background-color: $white;
          }
          @include mq('mobile-only') {
            button {
              margin-bottom: 0 !important;
            }
          }
        }
        .tarteaucitronLine {
          background: $white !important;
        }
        .tarteaucitronHidden {
          background: none !important;
        }
      }
    }
  }
}
