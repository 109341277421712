.card {
  background-color: $white;
  position: relative;
  color: $color-text;
  font-size: $text-default;
  width: 100%;
  padding: $spacer-12 $spacer-8 $spacer-12 $spacer-32;
  margin: $spacer-6 0;
  @include mq('tablet') {
    margin: $spacer-12 0;
    padding: $spacer-12 35px;
  }
  .title-icon {
    margin-bottom: $spacer-12;
    font-weight: 600;
    color: $title-color;
    position: relative;
    display: inline-block;
    align-items: center;
    > svg {
      position: absolute;
      left: -24px;
      top: 2px;
      width: auto;
      height: 1.2em;
      &:nth-child(2) {
        // When there is an icon on the right too
        position: unset;
        margin-left: $spacer-8;
      }
    }
  }
  hr {
    margin: 10px 0 15px 0;
    border: 1px solid $color-separator;
    border-top: none;
  }
  &.warning {
    font-weight: 500;
    &:before {
      left: 10px;
      zoom: 1.2;
    }
    .link-info {
      font-style: normal;
      font-weight: 500;
    }
  }
  &.is-stretch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.no-icon {
    padding: $spacer-12;
  }
}

.card-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  fill: colors(grey, 600);
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
  transition: all 0.2s ease;
  @include mq('tablet') {
    flex-direction: row;
    align-items: center;
  }

  .card-details {
    white-space: initial;
    svg {
      display: inline-block !important;
      @include squaresize($spacing-5);
    }
    .card-number {
      margin: 0 10px;
    }
    .expiration-date {
      color: colors(grey, 500);
    }
  }
  .is-default,
  .is-expired {
    justify-self: end;
    font-size: 12px;
    font-weight: 500;
    color: $color-grey--medium;
    margin-left: 34px; // Align perfectly with credit card number on mobile
  }
  .is-expired {
    color: color-ui(accent);
    padding: 2px 4px;
    border: 1px solid color-ui(accent);
    text-transform: uppercase;
  }
  &:hover {
    background-color: colors(aqua, 100);
    border: 1px solid colors(aqua, 400);
  }
}

.secure-payment {
  margin-top: 32px;
  img {
    margin-bottom: $spacer-4;
  }
  p {
    font-size: $text-small;
    font-family: $font-source-sans-3;
    font-weight: 500;
    color: $color-text;
  }
}
