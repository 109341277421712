.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  .patient-avatar {
    opacity: 1;
  }

  &.consent-form-dialog .popup__content {
    height: 100%;

    .popup-content-title {
      text-align: left;
    }
  }

  &.tlc-problems-report {
    .textarea-wrapper {
      position: relative;
      margin-top: $spacing-3;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        border-bottom: 2px solid color-ui(main);
        pointer-events: none;
      }
      &:hover {
        &:after {
          transform: scaleX(1);
        }
      }

      textarea {
        display: block; // Remove the little extra space below native textareas
        line-height: $text-large * 1.3;
      }
    }
  }
}

.popup__title {
  background: $gradient;
  padding: $spacer-16 $spacer-12;
  position: relative;
  h2 {
    font-weight: 500;
    text-align: center;
    color: $white;
    width: 100%;
    padding: 0 $spacer-32;
    font-size: $text-large;
    @include mq('tablet') {
      font-size: $text-xl;
      padding: 0 $spacer-40;
    }
  }
  button {
    right: 5px;
    top: 5px;
    position: absolute;
    .MuiSvgIcon-root {
      fill: $white;
    }
  }
}
.popup__content {
  padding: 20px;
  white-space: pre-line;
  color: $color-text;
  font-size: $text-default;
  @include mq('mobile-only') {
    padding-bottom: 80px;
    position: relative;
  }
  @include mq('tablet') {
    min-width: 400px;
    height: auto;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  p {
    white-space: pre-line;
  }
  .btn {
    margin-top: $spacer-24;
  }
  .wrapper-buttons {
    flex-direction: row;
    margin: $spacer-16 0;
    justify-content: space-between;
    .btn + .btn,
    .btn {
      margin: 0;
    }
    @include mq('mobile-only') {
      position: absolute;
      bottom: -10vh;
      left: 0;
      right: 0;
    }
  }
  .maiia-gallery {
    padding-bottom: 80px;
  }
  // Some buttons are rendered as "a" when they are just links
  // "p a" selector to avoid affecting them
  p a {
    color: color-ui(main);
  }
}

.popup__alert {
  font-weight: 500;
  font-size: $text-large;
  color: $error;
  margin: 0 0 $spacer-16;
}
.popup__info {
  color: $color-text;
  font-weight: 500;
  margin-bottom: $spacer-16;
}