.white-label {
  position: relative;
  //SAFETY IN CASE IT APPEARS
  .header,
  .footer {
    //display: none;
  }
  //Hide banner to recover RDV
  .bandeau-action {
    //display: none;
  }
  .page,
  .flow-rdv {
    margin-top: 0;
    min-height: 100vh;
  }
  .profile-creation {
    padding-top: 80px;
  }
  .sms-confirmation {
    margin-top: 0;
  }
  .rdv-form {
    &:not(.no-availabilities) {
      box-shadow: none;
      padding-top: 50px;
      width: 80%;
      @include mq('mobile-only') {
        width: 100%;
      }
      .wrapper--radio-buttons {
        justify-content: space-evenly;
      }
    }
    &.no-availabilities {
      // ideal width for NoClientAvailabity component
      width: 450px;
      margin: 0 auto;
    }
  }
  form {
    > div {
      width: 100%;
    }
  }
  .wrapper-headings {
    .title {
      color: $color-text;
      text-align: center;
      font-size: $title-med;
    }
    p {
      text-align: center;
    }
  }

  // remove margin for header
  .appointment-header {
    margin-top: 0;
    &.success,
    &.cancelled {
      margin-top: 30px;
    }
  }

  // Hide icon favorite
  .pratician__info .name {
    svg {
      display: none;
    }
  }
  .rdv-page {
    // Hide modification/cancel options
    .wrapper-buttons {
      display: none;
    }
    // Hide button to go back to homepage Maiia
    .button-row {
      display: none;
    }
    // Hide doc list
    .card.doc {
      display: none;
    }
  }
}
