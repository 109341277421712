/* Wrapper input suggestion + address + btn */
.searchbar {
  $input-height: 32px;
  $shadow: $shadow-condensed-light;
  display: none; /* Hide search bar on mobile to show fake button instead */
  @include mq('tablet') {
    display: flex;
    align-items: center;
    width: 65vw;
    min-width: 480px;
  }
  @include mq('desktop') {
    width: 50vw;
    min-width: 500px;
  }
  @include mq('wide') {
    min-width: 700px;
  }
  .input-dropdown {
    margin-right: 7px;
    position: relative;
    flex: 1;
    &.suggestion {
      flex-grow: 1.5;
    }
    .input-wrapper {
      background: none;
      &:after {
        display: none;
      }
    }
    .input-field {
      height: $input-height;
      border-radius: 50px 0 0 50px;
      font-size: $text-large;
      background: $white;
      width: 100%;
      color: $color-text;
      padding: 5px 45px 5px 5px;
      box-shadow: $shadow;
      text-overflow: ellipsis;
      &::placeholder {
        font-size: $text-large;
        color: $color-text;
        text-overflow: ellipsis;
      }
    }
    .dropdown-autocomplete {
      @include radius(bottom, 10px);
    }
    /* SIMPLE ICON */
    .with-icon {
      .input-field {
        padding: 5px 32px 5px 30px;
      }
    }
    /* Address field */
    &.address {
      .input-field {
        padding: 5px 54px 5px 5px;
        border-radius: 0;
      }
    }
  }
  .submit {
    height: $input-height;
    padding: 5px 17px;
    @include radius(right, 30px);
    box-shadow: $shadow;
    min-width: 10px;
    &:hover {
      background-color: color-ui(accent, dark);
    }
    svg {
      @include squaresize(22px);
    }
  }
  .MuiMenuItem-root,
  .MuiSelect-select {
    max-width: 100%;
    .search-item span {
      justify-self: flex-start;
    }
  }
}

/* input search on homepage mobile view */
.fake-input-mobile {
  position: relative;
  width: 100%;
  border-radius: 100px;
  height: 48px;
  padding: 0 70px 0 18px;
  background: $white;
  color: $color-grey--medium;
  line-height: 48px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  &:after {
    height: 48px;
    width: 65px;
    background-color: color-ui(accent);
    background-image: url('/static/icons/search.svg');
    background-repeat: no-repeat, repeat;
    background-position: center center;
    background-size: 30px;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
  }
  span {
    font-size: $text-large;
  }
}
/* Search pages mobile */
.search-mobile-fullpage {
  @extend %content-minus-header;
  background-color: $blue-grey-background;
  .input-wrapper {
    .input-field {
      height: var(--header-bar);
      background: $white;
      padding: 2px 10px 2px 46px;
      font-size: $text-large;
      &::placeholder {
        font-size: $text-large;
      }
    }
    &.input--no-icon .input-field {
      padding: 2px 10px;
    }
  }
  .input-dropdown {
    svg:not(.btn-location-icon) {
      @include squaresize(25px);
      top: 50%; // Center icon vertically
      left: 10px;
      transform: translateY(-50%);
      fill: $color-grey--medium;

      &#teleconsult-now-icon {
        fill: currentColor;
        transform: revert;
        width: unset;
        height: unset;
      }
    }
    .right-icon {
      top: 25px;
      svg {
        top: revert;
        left: revert;
        transform: revert;
      }
    }
  }
  .paper {
    > * {
      font-family: $font-source-sans-3;
      font-size: $text-default;
      line-height: 40px;
      border-bottom: 1px solid $blue-light-background;
      border-top: 1px solid $blue-grey-background;
      max-width: 100%;
      border-radius: 0 !important;
      background: $white;
    }
  }
}

/* Elements on dropdown list */
.search-item {
  cursor: pointer;
  white-space: normal;
  display: grid;
  grid-template-columns: 50px minmax(170px, 380px) 35px;
  margin: $spacer-4 0;
  align-items: center;
  min-height: 22px;
  line-height: 22px;
  .avatar {
    @include squaresize(40px);
  }
  .avatar--online {
    &:after {
      @include squaresize(12px);
    }
  }
  .wrapper-info {
    font-weight: 600;
    color: $color-text;
    white-space: normal;
    .info {
      font-weight: 400;
      font-size: $text-default;
      span {
        font-style: italic;
      }
    }
  }
  .btn {
    @include squaresize(35px);
    svg {
      @include squaresize(20px);
    }
  }
  &.item--center {
    grid-template-columns: 50px 1fr 10px;
  }
  &.item--spe {
    grid-template-columns: 1fr 30px;
  }
  &.drug-disabled {
    color: black;
  }
}
.item--default {
  display: block;
  margin: 0;
  padding: $spacer-4 0;
}
