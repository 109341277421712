/* RADIO BUTTON*/
.MuiFormGroup-root[role='radiogroup'] {
  margin-top: 20px;
  .MuiTypography-body1 {
    font-family: $font-source-sans-3;
    font-size: $text-default;
    font-weight: 400;
    color: $color-text;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: color-ui(accent);
  }
}
.wrapper--radio-buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: $spacer-8;
  &.sp-aligned {
    flex-direction: column;
    .radio-button {
      margin: 0 0 $spacer-8 0;
    }
    &.sp-section {
      .radio-button {
        margin: 0 0 $spacer-32 0;
      }
    }
  }
  .radio-button {
    margin: $spacer-16 0 0 $spacer-24;
    position: relative;
    label {
      vertical-align: top;
      cursor: pointer;
      font-family: $font-source-sans-3;
      font-size: $text-default;
      font-weight: 400;
      color: $color-text;
      position: relative;
      z-index: 2;
      &.radio-title {
        font-weight: 500;
        color: $color-text;
      }
    }
    .check {
      display: block;
      position: absolute;
      border: 2px solid $color-grey--medium;
      border-radius: 100%;
      height: 12px;
      width: 12px;
      top: 3px;
      left: 0;
      z-index: 1;
      transition: all 0.25s ease;
      box-sizing: unset;
    }
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      top: -1px;
      left: 1px;
      padding: 0;
      & ~ label {
        padding-left: 22px;
      }
      &:hover {
        & ~ .check {
          box-shadow: 0px 0px 0px 4px $white-salmon;
          background: $white-salmon;
          border-color: color-ui(accent);
        }
      }
    }
    input[type='radio']:checked {
      & ~ .check {
        border: 2px solid color-ui(accent);
        &:before {
          content: '';
          background: color-ui(accent);
          border-radius: 50px;
          height: 8px;
          width: 8px;
          top: 2px;
          left: 2px;
          position: absolute;
        }
      }
      & ~ label {
        font-weight: 500;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    p {
      margin: 5px 0 0 22px;
      &.impact {
        margin: $spacer-16 0 0 22px; /*  A uniformiser*/
      }
    }
  }
  &.is-vertical {
    .radio-button {
      margin: $spacer-16 0 0 $spacer-24;
      padding-bottom: 20px;
      input,
      .check {
        bottom: 0px;
        top: unset;
        left: 5px;
      }
    }
  }
}

.radio-button.radio--block {
  padding: 8px 0 8px 0 !important;
  background: $white-main;
  border-radius: $radius-xl;
  label {
    padding: 8px 56px 8px 40px !important;
    @include mq('mobile-only') {
      padding: 8px 40px 8px 36px !important;
    }
  }
  .check {
    left: $spacer-12;
    top: 10px;
  }
  svg {
    position: absolute;
    display: inline;
    z-index: 5;
    right: $spacer-12;
    height: 20px;
    width: 20px;
    fill: $color-grey--default;
  }
  input[type='radio']:checked ~ label,
  input[type='radio']:checked ~ svg {
    color: color-ui(accent);
    fill: color-ui(accent);
  }
}
