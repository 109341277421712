select {
  font-size: $text-default;
  padding: 9px 35px 9px 12px !important;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--background-color);
  border: none;
  background-image: url('/static/icons/arrow-down.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 8px top 50%, 0 0;
  text-overflow: ellipsis;
  outline: none;
  color: $color-text;
  & + .select-wrapper,
  & + select {
    margin-top: $spacer-12;
  }
  &[disabled] {
    background-image: unset;
  }
}
.select-wrapper.MuiOutlinedInput-root {
  width: 100%;
  svg {
    display: none;
  }
  &:before {
    display: none;
  }
  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid color-ui(main);
    pointer-events: none;
  }
  &:focus,
  &:hover {
    &:after {
      transform: scaleX(1);
      border-bottom: 2px solid color-ui(main);
    }
  }
}
.select-wrapper {
  .MuiMenuItem-root,
  .MuiSelect-select {
    font: 400 14px/18px $font-source-sans-3;
    color: $color-text;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
  }
  .MuiSelect-icon {
    display: none;
  }
}

.select-wrapper {
  .select-mention {
    text-align: right;
    font-size: $text-small;
    font-style: italic;
    margin-top: 2px;
    margin-bottom: $spacing-2;
  }
  & + .select-wrapper,
  & + select {
    margin-top: $spacer-12;
  }
}

.select-wrapper .MuiSelect-select {
  @extend select;
}

.MuiSelect-root {
  fieldset {
    border: none;
  }
}
