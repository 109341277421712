@import '@kiiwi/ui/dist/tailwind/reset.css';
@import '@kiiwi/ui/dist/tailwind/tokens.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer base {
  :root {
    --maiia-header-height: 56px;
    --opentok-patient-video-width: 35%;
    --opentok-patient-video-width-sm: 264px;
    --opentok-patient-video-height: 198px;
    --opentok-patient-video-left: 20px;
    --opentok-patient-video-bottom: 20px;
  }
}
