.tag {
  padding: 2px 7px;
  margin: 5px 12px 5px 0;
  border-radius: $radius-xs;
  background-color: $profile-background;
  color: $color-text;
  display: inline-block;
  &.tag--ko {
    color: $error;
    text-decoration: line-through;
    background-color: $profile-background;
  }
  &.secondary {
    &.tag--ko {
      color: $color-text;
    }
  }
  &.tertiary {
    background-color: $color-drug-light;
    color: $white;
    font: 500 14px/22px $font-source-sans-3;
  }
  &.tag--rounded {
    border-radius: 1em;
    padding-right: 0.75em;
    padding-left: 0.75em;
  }
  &.tag--main {
    color: color-ui(main, darker);
    background-color: color-ui(main, xlight);
  }
  &.tag--success {
    color: $success-dark;
    background-color: $success-light;
  }
  &.tag--warning {
    color: $warning-dark;
    background-color: $warning-light;
  }
  &.tag--error {
    color: $error-dark;
    background-color: $error-light;
  }
  &.tag--info {
    color: $color-grey--dark;
    background-color: $color-grey--lite;
  }
  &.tag--bold {
    font-weight: bold;
  }
}
