.param-account {
  background-color: var(--background-color);
  .MuiAccordionSummary-content.Mui-expanded {
    margin: $spacer-12 0;
  }
  > h2 {
    color: color-ui(main);
    text-align: center;
    font-size: $title-small;
    padding: 30px 0;
    @include mq('tablet') {
      padding: 50px 0;
      font-size: $title-med;
    }
  }
  h3 {
    color: $color-text;
  }
  .account-container {
    max-width: 800px;
    @include mq('mobile-only') {
      width: calc(100vw - 30px);
    }
    margin: 0 auto;
    .accordeon {
      margin-bottom: 15px;
      box-shadow: none;
      &::before {
        background-color: transparent;
      }
      .title-icon * {
        font-weight: 500;
      }
      .accordeon__body {
        display: flex;
        flex-direction: column;
        > .param-row:not(:last-of-type) {
          border-bottom: 1px solid colors(grey, 200);
        }
        .param-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: $spacer-16 0;
          &:first-child {
            padding-top: 0;
          }
          svg.action {
            @include squaresize(24px);
            fill: colors(grey, 500);
            &.edit {
              @include squaresize(24px);
            }
          }
          .btn.action {
            margin-top: $spacer-24;
          }
          @include mq('desktop') {
            svg.action {
              transition: all 0.2s ease;
              opacity: 0;
              cursor: pointer;
            }
            &:hover svg.action {
              opacity: 1;
              transition: all 0.2s ease;
              &:hover {
                fill: color-ui(accent);
              }
            }
          }
          form {
            margin-top: 8px;
            .intl-tel-input {
              @include mq('tablet') {
                max-width: 300px;
              }
            }
          }
          .card-row {
            &:hover {
              background-color: unset;
              border: 1px solid transparent;
              cursor: unset;
            }
          }
          .MuiFormControl-root.MuiTextField-root {
            width: auto;
          }
          &.phone-row.editing {
            flex-direction: column;

            .MuiButton-root {
              margin-top: $spacing-3;
              margin-left: auto;
            }

            @include mq('tablet') {
              flex-direction: row;

              .MuiButton-root {
                margin-top: $spacing-6; // Align submit button with text field
              }
            }
          }
        }
        .text {
          width: 100%;
          .value {
            padding-bottom: 10px;
            &.email {
              color: $color-grey--medium;
            }
          }
          .bloc-highlight {
            margin: $spacer-16 $spacer-24 0 0;
            padding: $spacer-8;
            display: block;
            text-align: center;
            svg {
              @include squaresize(24px);
              fill: $color-secondary;
              color: $white;
              margin-right: $spacer-8;
            }
            .btn {
              margin: $spacer-8 auto;
              width: 300px;
            }
          }
        }
      }
      &.logout,
      &.delete-account {
        cursor: pointer;
        background-color: $white;

        height: 48px;
        display: flex;
        align-items: center;
        padding-left: $spacer-24;
        color: colors(red, 400);
        svg {
          @include squaresize(24px);
          fill: colors(red, 400);
          margin-right: 20px;
        }
      }
    }
  }
  /* When creatio account is not complete */
  .info__account {
    margin: 0 auto 50px;
    width: 100%;
    padding: 0 10px;
    color: $color-text;
    p {
      font-size: $text-large;
      font-weight: 500;
      text-align: center;
      white-space: pre-line;
      @include mq('mobile-only') {
        white-space: normal;
      }
    }
    .btn {
      max-width: 250px;
      margin: 20px auto 0;
    }
    & + .account-container {
      .MuiAccordion-root {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: $white;
          opacity: 0.5;
        }
      }
    }
  }
  .wrapper-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 70px;
    @include mq('mobile-only') {
      justify-content: center;
    }

    .btn--logout {
      padding-left: 40px;
      position: relative;
      svg {
        position: absolute;
        left: 10px;
        top: 6px;
        @include squaresize(24px);
      }
    }
  }
  .btn--delete-account {
    color: $color-grey--medium;
    align-self: flex-end;
    &:hover {
      color: $error;
    }
  }

  .insurance-row .text {
    display: flex;

    @include mq('mobile-only') {
      flex-direction: column;
    }

    .insurance-row__branding {
      margin-right: $spacing-3;
      width: $spacing-10;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mq('mobile-only') {
        margin-right: 0;
        margin-bottom: $spacing-3;
      }

      img {
        max-height: $spacing-7;
        max-width: 100%;
      }
    }

    .insurance-row__info-line {
      display: block;
      color: $color-grey--medium;
      font-size: $text-default;
    }
  }
}
