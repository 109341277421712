.patient-avatar {
  position: relative;
  font-family: $font-source-sans-3;
  transition: opacity 0.3s ease-in;
  @extend %flexcenter;
  flex-direction: column;
  .fullname {
    grid-area: name;
    @extend %flexcenter;
    color: $color-text;
    text-transform: capitalize;
  }
  .is-default {
    grid-area: principal;
    @extend %flexcenter;
    color: $color-grey--medium;
    font-weight: 500;
    justify-self: start;
    font-size: $text-small;
    @include mq('tablet') {
      justify-self: end;
    }
  }
  .date-of-birth {
    grid-area: dob;
    justify-self: start;
    font-size: $text-default;

    color: $color-grey--medium;
  }
  &:hover,
  &.selected {
    opacity: 1;
    .fullname {
      font-weight: 500;
    }
  }

  // To be removed after refactoring practitioner avatar
  // it's needed now for patient chips
  @each $id, $color in $mui-patient-colors {
    &.patient-avatar--color#{$id} > .initial {
      background-color: $color;
    }
  }

  > .initial {
    grid-area: initial;
    border-radius: 50%;
    @extend %flexcenter;
    text-transform: capitalize;
    font-size: inherit;
    transition: background-color 0.2s ease;
    background-color: colors(red, 400);
    color: $white;
  }

  /* Select patient flow rdv && list patient prat - verticale state*/
  &.patient-card {
    $size: 63px;
    font-size: $title-med;
    width: $size;
    opacity: 0.7;
    > .initial {
      @include squaresize($size);
      color: $white;
    }
    .fullname {
      text-align: center;
      margin-top: $spacer-4;
      font-weight: 400;
      font-size: $text-default;
    }
    &.add-btn {
      flex-basis: 100%;
      font-size: $title-large;
      margin-top: $spacer-40;
      > .initial {
        background-color: $color-grey--disabled;
        width: 50px;
        height: 50px;
      }
      > .fullname {
        font-size: $text-default;
      }
    }
    &:hover,
    &.selected {
      opacity: 1;
      .fullname {
        font-weight: 500;
        color: $color-text;
      }
    }
    &.selected {
      .initial {
        border: 3px solid color-ui(accent);
        &::after {
          content: '✓';
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: $text-default;
          font-weight: 700;
          position: absolute;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          right: -2px;
          top: calc(63px - 20px);
          background-color: color-ui(accent);
        }
      }
    }
  }
  /* Account list patient - row state */
  &.is-row {
    $size: 37px;

    display: grid;
    grid-template-columns: $size 1fr 20px;
    grid-template-areas: 'init name .' 'init dob dob' 'init principal .';
    grid-column-gap: 10px;
    opacity: 1;
    margin: 0;
    width: auto;
    @include mq('tablet') {
      grid-template-columns: $size 1fr 130px;
      grid-template-areas: 'init name principal' 'init dob dob';
    }
    .initial {
      grid-area: init;
      @include squaresize($size);
      @extend %flexcenter;
      font-size: $title-xs;
    }
    .fullname {
      justify-self: flex-start;
      font-size: $text-default;
      font-weight: 500;
      text-align: left;
    }
    &:hover {
      .fullname {
        color: $login-link;
      }
    }
  }
  &--neutral {
    .initial {
      background-color: $color-grey--medium;
    }
  }
}

.avatar-hover {
  display: none;
}
.patient-card.patient-avatar {
  &--xsmall {
    width: 20px;
    font-size: $text-small;
    height: 20px;
    opacity: 1;
    .initial {
      width: 20px;
      height: 20px;
      font-weight: 700;
      line-height: 20px;
    }
    .fullname {
      display: none;
    }
    &:hover {
      & + .avatar-hover {
        display: block;
        opacity: 0.9;
        position: absolute;
        top: 56px;
        left: 10px;
        z-index: 11;
        background: white;
        font-weight: 500;
        font-size: $text-mention;
        padding: $spacer-4 $spacer-6;
        color: $color-text;
        box-shadow: 0px 3px 6px 0px $black-opacity-sixteen;
      }
    }
  }
}
