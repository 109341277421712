$default-spacer: 10px;
$title: color-ui(main);
$text: $color-text;

#teleconsultation {
  /* Extend or add common class for all pages ? */
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $gradient;
  h2,
  h3,
  h4,
  p,
  span {
    white-space: pre-wrap; /* Needed for break lines on trads */
    @include mq('mobile-only') {
      white-space: normal;
    }
  }
}
