.cards-wrapper {
  form {
    padding: $spacer-16;
    margin-top: $spacer-32;
    background: $profile-background;
    border-radius: $radius-med;

    .checkbox,
    .actions {
      margin-top: $spacer-16;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      margin: 0;
      position: relative;
      &:not(:last-child) {
        margin-right: $spacer-16;
      }
    }
    .loader-card {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    .check-card {
      position: absolute;
      top: 2px;
      left: 2px;
      color: $white;
    }
    .StripeElement {
      padding-bottom: $spacer-8;
      border-bottom: 1px solid lightgray;
    }
    // Media queries
    @include mq('mobile-only') {
      .actions {
        flex-direction: column;
        align-items: center;
      }
      .btn {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: $spacer-16;
        }
      }
    }
  }
}
