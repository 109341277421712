.minicard {
  grid-area: minicard;
  display: grid;
  min-height: $minicard-rdv-height;
  overflow: hidden;
  margin-bottom: $minicard-gap;
  border-radius: $radius-default;
  background-color: white;
  align-items: stretch;
  transition: all 0.3s ease;
  font-family: $font-source-sans-3;
  @extend %shadow-2;
  @include mq('tablet') {
    width: $minicard-width;
  }
  &:hover {
    @extend %shadow-3;
  }

  &.empty-card {
    margin: $spacer-40 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: inherit;
    .information {
      color: color-ui(main);
      padding: $spacing-3;
      text-align: center;
      display: flex;
      align-items: center;
      font: 600 14px/21px $font-source-sans-3;
      .primary {
        fill: color-ui(accent);
        // The "heart" icon is an image, it's not aligned with the text
        // "vertical-align" won't make the cut -visually-
        // I used "em" for future proof, in case someone decided to change font size.
        margin-top: -0.25em;
      }
    }
    .minicard_status {
      background-color: $color-blue--xlight-alt;
      flex-shrink: 0;
      img {
        width: 70px;
        height: 70px;
      }
    }
    &:hover {
      @extend %shadow-2;
      .minicard_status {
        background-color: $color-blue--xlight-alt;
      }
    }
  }
}
.minicard_info {
  grid-area: info;
  position: relative;
  background-color: $white;
  padding: $spacer-8 $spacer-8 $spacer-4 $spacer-8;
  display: grid;
  grid-column-gap: $spacer-8;
  grid-template:
    'avatar name name . ' auto
    'avatar spe  spe  . ' auto
    'avatar zip  .    .' auto / 60px 1fr;
  .avatar {
    grid-area: avatar;
    @include squaresize(56px);
    &--online:after {
      @include squaresize(12px);
    }
  }
  .prat-name {
    grid-area: name;
    color: color-ui(main);
    font: 600 14px/18px $font-source-sans-3;
    position: relative;
    margin-bottom: -5px;
    &.is-flagged {
      padding-right: $spacer-24;
      .care-status {
        @at-root svg#{&} {
          display: inline;
        }
        position: absolute;
        top: 0;
        right: 0;
        &.primary,
        &.favorite {
          height: $spacing-4;
          width: $spacing-4;
        }
      }
    }
  }
  .prat-spe {
    grid-area: spe;
    color: $color-text;
  }
  .prat-address {
    grid-area: zip;
    color: $color-grey--medium;
  }
  .patient-avatar--xsmall {
    position: absolute;
    z-index: 10;
    top: 40px;
    left: -13px;
    &:hover {
      & + .avatar-hover {
        top: 60px;
        left: 30px;
      }
    }
  }
}
.minicard_status {
  grid-area: status;
  width: $minicard-status-width;
  background-color: color-ui(accent);
  padding: $spacer-8;
  @extend %flexcenter;
  flex-direction: column;
  text-align: center;
  fill: $color-text;
  color: $color-text;
  transition: all 0.3s ease;
  .date {
    font: 500 16px/15px $font-source-sans-3;
    text-transform: capitalize;
  }
  .hour {
    font-weight: 400;
    font-size: $text-default;
    margin-top: $spacer-4;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg {
    @include squaresize(28px);
    margin-bottom: $spacer-6;
    fill: $minicard-icons;
  }
}

.minicard--rdv {
  grid-template-areas: 'status info';
  grid-template-columns: $minicard-status-width 1fr;
  .minicard_info {
    place-content: center;
    row-gap: 4px;
    grid-template-areas:
      'avatar name'
      'avatar spe'
      'btn btn'
      'count count';
    &.skeleton {
      grid-template-areas:
        'avatar spe'
        'avatar spe'
        'btn btn';
    }
    .prat-name {
      align-self: end;
    }
    .btn {
      margin-top: $spacing-1;
    }
    .btn,
    .countdown {
      justify-self: center;
    }
  }
  .minicard_status {
    background-color: $color-blue--xlight-alt;
    &.cancelled,
    &.warning {
      background-color: colors(red, 200);
      color: $color--ko;
      padding: 7px;
      svg {
        fill: $color--ko;
        @include squaresize(18px);
        margin-bottom: 0;
      }
      .rdv_type {
        @include squaresize(13px);
        margin-left: 2px;
        fill: $color--ko;
        margin-bottom: 0;
        display: none;
      }
      .cancelled-text {
        font-weight: 600;
        font-size: $text-large;
        margin-bottom: $spacer-8;
      }
      .date,
      .hour {
        font-weight: 600;
        font-size: $text-mention;
        margin-top: 0;
      }
      .hour {
        font-weight: 500;
        line-height: 14px;
      }
    }
  }
  .btn {
    grid-area: btn;
    align-self: center;
    justify-self: start;
    @include mq('mobile-only') {
      padding: $spacer-4 $spacer-8;
      width: 100%;
    }
  }

  .countdown {
    grid-area: count;
    margin-top: 2px;
    margin-left: 0;
    font-size: $text-mention;
    color: $color-grey--dark;
  }
  &:hover {
    .minicard_status {
      background-color: $blue-highlight;
      &.cancelled,
      &.warning {
        background-color: colors(red, 200);
      }
    }
  }
}
.minicard--careteam {
  grid-template-areas: 'info status';
  grid-template-columns: 1fr $minicard-status-width;
  .minicard_status {
    .date {
      color: white;
      font: 500 16px/18px $font-source-sans-3;
    }
    .next-availability {
      color: white;
      font-size: $text-small;
      margin-bottom: $spacer-4;
    }
  }
  .minicard_info {
    grid-template:
      'avatar name'
      'avatar spe'
      'avatar zip';
    grid-template-columns: 60px 1fr;
    .patient-avatar--xsmall {
      cursor: default;
    }
  }
  &:hover {
    .minicard_status {
      background-color: color-ui(accent, dark);
    }
  }
}
