.iframe.page {
  padding: $spacing-6 $spacing-4;
  @include mq('tablet') {
    padding: $spacing-9 $spacing-6;
  }

  .content-wrapper {
    max-width: 750px;
    margin: 0 auto;

    h1 {
      text-align: center;
    }

    form {
      padding-top: $spacing-6;
      .searchbar {
        display: flex; // Make sure it's visible on mobile too
        width: 100%;
        .input-field,
        button[type='submit'] {
          height: $spacing-7;
        }
      }
    }

    .iframe-generated {
      padding-top: $spacing-6;
      @include mq('tablet') {
        padding-top: $spacing-8;
      }

      .code {
        @include mq('tablet') {
          display: flex;
        }
        div {
          padding: $spacing-4;
          font-weight: bold;
          background: $white;
          border-radius: $spacing-4 $spacing-4 0 0;
          word-break: break-word; // Make sure that iframe code containing very long url won't break the layout
          @include mq('tablet') {
            border-radius: $spacing-4 0 0 $spacing-4;
          }
        }
      }
      .preview {
        padding-top: $spacing-6;
      }
    }
  }
}
