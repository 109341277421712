@use "sass:math";
/* Material UI
============================================================================= */
// TODO : Remove all this override (to line 28) and all classes 'disable-scss-override' on modals after Modal refacto
*[role='dialog']:not(.disable-scss-override) {
  // Material UI datepicker
  .MuiDialogActions-root {
    button {
      min-width: 150px;
      border-radius: $radius-button;
      padding: $spacer-4 $spacer-24;
      font: 500 math.div($text-default, $text-xl) $font-source-sans-3;
    }
    > button:first-child {
      border-radius: none;
      color: $color-text;
      background: transparent;
      font: 500 math.div($text-default, $text-xl) $font-source-sans-3;
      @include icon-size(18px);
      margin-right: $spacer-16;
    }
    > button:last-child {
      @include button($filled--accent...);
      &:hover {
        @include button($filled--accent_hover...);
      }
    }
  }
}

/* Specific calendar dialog*/
#calendar-picker-dialog {
  .popup__content {
    padding: 0;
  }

  .MuiCalendarPicker-root {
    font-family: $font-source-sans-3;

    .PrivatePickersSlideTransition-root {
      min-height: 230px;
    }

    .MuiTypography-caption {
      color: color-ui(main);
    }

    .MuiPickersDay-dayWithMargin {
      font-size: $text-default;
    }

    .Mui-selected {
      background-color: color-ui(main);
    }
  }
}

/* Menu list*/
.MuiMenuItem-root,
.MuiSelect-select {
  font: 400 math.div($text-default, $text-xl) $font-source-sans-3;
  color: $color-text;
  display: flex;
  justify-content: space-between;
  max-width: 100%; //470px
  white-space: pre-wrap;

  &:before {
    display: none;
  }
  span {
    justify-content: flex-end;
  }
}

.MuiModal-root {
  z-index: 1300;
}

//        d8888 88888888888 .d88888b.  888b     d888  .d8888b.
//       d88888     888    d88P" "Y88b 8888b   d8888 d88P  Y88b
//      d88P888     888    888     888 88888b.d88888 Y88b.
//     d88P 888     888    888     888 888Y88888P888  "Y888b.
//    d88P  888     888    888     888 888 Y888P 888     "Y88b.
//   d88P   888     888    888     888 888  Y8P  888       "888
//  d8888888888     888    Y88b. .d88P 888   "   888 Y88b  d88P
// d88P     888     888     "Y88888P"  888       888  "Y8888P"

.error {
  color: $error;
  font: 500 14px/22px $font-source-sans-3;
}

.error-msg {
  @extend .error;
  padding: 20px;
  text-align: center;
  padding-top: 0;
  margin-bottom: 30px;
  @include mq('mobile-only') {
    max-width: 100%;
  }
}

.online-dot {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 5px;
    right: -5px;
    background-color: $color--online;
    border-radius: 50%;
    @include squaresize(15px);
  }
}

.title-icon {
  display: flex;
  position: relative;
}

.customMessagePasswordValidation {
  font-size: 0.8em;
  margin-top: -2px;
  letter-spacing: 0.03em;
}

/* Icon traitant/favori */
.care-status {
  @at-root svg#{&} {
    display: inline;
  }
  &.primary {
    fill: color-ui(accent);
  }
  &.favorite {
    fill: $star;
  }
}

// 888b     d888  .d88888b.  888      8888888888 .d8888b.  888     888 888      8888888888 .d8888b.
// 8888b   d8888 d88P" "Y88b 888      888       d88P  Y88b 888     888 888      888       d88P  Y88b
// 88888b.d88888 888     888 888      888       888    888 888     888 888      888       Y88b.
// 888Y88888P888 888     888 888      8888888   888        888     888 888      8888888    "Y888b.
// 888 Y888P 888 888     888 888      888       888        888     888 888      888           "Y88b.
// 888  Y8P  888 888     888 888      888       888    888 888     888 888      888             "888
// 888   "   888 Y88b. .d88P 888      888       Y88b  d88P Y88b. .d88P 888      888       Y88b  d88P
// 888       888  "Y88888P"  88888888 8888888888 "Y8888P"   "Y88888P"  88888888 8888888888 "Y8888P"

.list {
  color: $white;
}

// 8888888b.         d8888  .d8888b.  8888888888  .d8888b.
// 888   Y88b       d88888 d88P  Y88b 888        d88P  Y88b
// 888    888      d88P888 888    888 888        Y88b.
// 888   d88P     d88P 888 888        8888888     "Y888b.
// 8888888P"     d88P  888 888  88888 888            "Y88b.
// 888          d88P   888 888    888 888              "888
// 888         d8888888888 Y88b  d88P 888        Y88b  d88P
// 888        d88P     888  "Y8888P88 8888888888  "Y8888P"

.forgot-password {
  form {
    padding: 20px;
    max-width: 750px;
    margin-top: $spacer-40;
    text-align: center;
    input,
    .MuiInputBase-input {
      background: $white;
      width: 300px;
      @include mq('tablet') {
        width: 400px;
      }
    }
  }
}

.MuiSkeleton-text {
  transform: scale(1, 0.8) !important;
}

@media only percy {
  // when using percy, elements that change (such as date, etc) must be hidden
  .percy-hidden {
    visibility: hidden !important;
  }
}
