#map {
  width: 100%;
  height: 100%;
  .mapboxgl-marker,
  .leaflet-marker-icon {
    color: color-ui(accent);
    .map-marker {
      font-size: 2em;
    }
  }
  .mapboxgl-marker.highlight,
  .map-marker.highlight {
    color: color-ui(accent);
    z-index: 9999;
  }

  .mapboxgl-marker.highlight > svg.map-marker,
  svg.map-marker.highlight {
    -webkit-animation: leaves 1s;
    animation: leaves 1s;
    animation-fill-mode: forwards;
    transform-origin: center bottom; // Make sure that the bottom of the pin (pointing the exact location) doesn't move when beeing scaled up
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .leaflet-div-icon {
    background: transparent;
    border: none;
  }

  .leaflet-popup {
    max-width: $spacing-12;
    .leaflet-popup-content {
      margin: $spacing-1 $spacing-2;
    }
  }

  @keyframes leaves {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.5);
    }
  }
}
