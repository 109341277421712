/* HEADER */
.brandname--pharmacy {
  color: $white;
  font-weight: bold;
  font-size: $text-xl;
  line-height: 18px;
  @include mq('tablet') {
    font-size: $title-small;
    line-height: 28px;
  }
}
/* HOME */
.bandeau--pharmacy {
  background: $white;
  padding: 20px 20px 70px 20px;
  margin: 0 auto;
  border-radius: 10px;
  width: 500px;
  @include mq('mobile-only') {
    width: 90%;
    padding: 0;
    background: transparent;
  }
  .wrapper-brandname {
    max-width: 250px;
    max-height: 100px;
    margin: 0 auto $spacer-12;
    text-align: center;
    @include mq('mobile-only') {
      max-height: 50px;
    }
    img.brandname--pharmacy {
      margin-bottom: 0;
      max-width: 250px;
      max-height: 50px;
      object-fit: contain;
    }
  }
}

.bandeau--insurance {
  background: $white;
  padding: 10px;
  margin: 0 auto 120px auto;
  border-radius: 10px;
  width: 500px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  @include mq('mobile-only') {
    width: 90%;
    padding: 0;
    margin: 0 auto 12px auto;
  }
  .insurance-logo {
    width: auto;
    object-fit: contain;
    max-height: 180px;
    height: 180px;
    @include mq('mobile-only') {
      max-height: 70px;
    }
  }
}

.info--pharmacy {
  margin-top: -60px;
  margin-bottom: 60px;
  @include mq('tablet') {
    margin-top: -70px;
    margin-bottom: 120px;
  }
  @include mq('mobile-only') {
    display: none;
  }
  .name--pharmacie,
  .subtext--pharmacie {
    text-align: center;
    font-size: $text-large;
    line-height: 20px;
    color: $color-text;
    font-weight: 400;
    font-family: $font-source-sans-3;
    span {
      color: color-ui(main);
    }
    @include mq('tablet') {
      font-size: $title-xs;
      line-height: 24px;
    }
  }
  .name--pharmacie {
    font-weight: bold;
  }
}

/* NAV */
nav .nav__header > .brandname--pharmacy {
  height: 60px;
  width: auto;
  margin-left: 18px;
}
