.popup-grid-items {
  display: grid;
  grid-template-columns: 350px 300px;
  grid-column-gap: $spacer-16;
  grid-template-rows: 450px;
  .wrapper-list-items {
    overflow-y: auto;
  }
  //TODO cristela ==> clean
  @include mq('mobile-only') {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 130px);
    .wrapper-list-items {
      height: 70vh;
      overflow-y: auto;
      margin-top: $spacer-16;
    }
    .popup-map {
      height: 25vh;
      width: 100%;
    }
  }
}
.popup-select--item {
  background: $color-grey--background;
  border-radius: $radius-xs;
  padding: $spacer-8 $spacer-16;
  display: block;
  cursor: pointer;
  color: $color-text;
  & + .popup-select--item {
    margin-top: $spacer-16;
  }
  strong {
    font-weight: 500;
  }
  &:hover {
    box-shadow: $shadow-condensed-dark;
    strong {
      color: color-ui(accent);
    }
  }
  &.with-avatar {
    display: grid;
    grid-template-columns: 55px 1fr;
    grid-column-gap: $spacer-16;
    align-items: center;
  }
  &.with-checkbox {
    grid-template-columns: 55px 1fr auto;
    cursor: auto;
    &:hover {
      box-shadow: none;
    }
  }
  .avatar {
    border: 1px solid $color-placeholder;
  }
}

.popup-content-title {
  margin: $spacer-16 auto;
  font: 500 16px/20px $font-outfit;
  color: color-ui(main);
  text-align: center;
}

.popup__subtitle {
  font-weight: 500;
  font-size: $text-large;
  color: $color-text;
  margin: 0 0 $spacer-16;
  span {
    color: color-ui(accent);
    &.wrap {
      display: inline-block;
      @include mq('mobile-only') {
        display: inline;
        margin-left: 5px;
      }
    }
  }
}

.popup__content {
  ul {
    li:not(.instruction-item) {
      color: $color-text;
      padding: 10px 0;
      &:not(:last-of-type) {
        border-bottom: 1px solid colors(grey, 200);
      }
    }
  }
  &:last-child {
    margin: $spacer-16;
    padding: $spacer-8;
  }
  .search-card__rdv.availability-calendar {
    max-width: 450px;
    margin: $spacer-24 auto;
  }

  &.not-overflow {
    overflow: visible;
    padding-bottom: 50px;
  }
  .intl-tel-input {
    @include mq('tablet') {
      width: 300px;
    }
    .country-list {
      @include mq('tablet') {
        width: 300px;
        max-height: 85px; // Corresponds to 2.5 options
      }
    }
  }
}

@include mq('mobile-only') {
  .MuiDialog-container {
    width: 100%;
  }
}
