@use "sass:math";

.alert {
  color: $error;
  grid-area: error;
  margin-bottom: $spacer-16;
  background-color: $error-light;
  display: flex;
  padding: $spacer-16 $spacer-16;
  border-radius: $radius-default;
  font: 500 math.div($text-small, 20px) $font-source-sans-3;
  box-shadow: $shadow-condensed-light;
  span {
    font-weight: 500;
  }
  svg {
    @include squaresize(22px);
    margin-right: $spacer-8;
    flex-shrink: 0;
  }
  &.is-filled {
    color: $white;
    background-color: $error;
  }
  &.is-success {
    color: $success-dark;
    background-color: $success-light;
    svg {
      fill: $success-dark;
    }
    &.is-filled {
      color: $white;
      background-color: $success-dark;
      svg {
        fill: $white;
      }
    }
  }
  &.is-warning {
    color: $warning-dark;
    background-color: $warning-light;
    &.is-filled {
      color: $white;
      background-color: $warning-dark;
    }
  }
  &.is-info {
    color: $color-text;
    background-color: $white;
  }
}

//WHEN IN SNACKBAR
.MuiSnackbar-root {
  .alert {
    align-items: center;
    padding: 8px 12px;
    svg {
      @include squaresize(30px);
    }
  }
}
