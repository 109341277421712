// TODO uniformiser vars
$legal-title: color-ui(main);
$legal-text: $color-text;
$primary-color: color-ui(accent);

.page .legal__big-title {
  padding-top: $spacer-40;
  text-align: center;
  word-break: break-word;
  @include mq('tablet') {
    text-align: left;
  }
}
.legal__title {
  margin-top: $spacer-40;
}
// For sub-header (10.3) to be in the view, and not behind the header
// after scroll
#covered-tlc {
  margin-top: -58px;
  padding-top: 58px;
}
.legal__subtitle,
.legal-ssr-content h2 {
  color: $legal-text;
  margin: $spacer-32 0 $spacer-8;
  @include mq('mobile-only') {
    font-size: $text-default;
    line-height: 20px;
  }
}

.legal__text,
.legal-ssr-content {
  color: $legal-text;
  margin: $spacer-8 0;
  em {
    color: $legal-text;
    font: 400 14px/22px $font-source-sans-3;
    font-style: italic;
  }
  span {
    text-decoration: underline;
  }
  a {
    color: $color-secondary;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  &.sub {
    font-weight: 500;
    margin-top: 20px;
    text-decoration: underline;
    &_sub {
      font-weight: 500;
      text-indent: 20px;
    }
  }
  ul {
    margin: 15px 0;
    li {
      margin: 15px 0 15px 15px;
    }
  }
}

.legal {
  @include mq('tablet') {
    display: grid;
    grid-template-columns: 240px 1fr;
  }
  @include mq('desktop') {
    grid-template-columns: 270px 1fr;
  }
  &__sidebar {
    display: block;
    position: fixed;
    @include mq('tablet') {
      background: $white;
      padding: $spacer-32 $spacer-24;
      box-shadow: 0px 0px 9px $color-grey--lite-alt;
      position: relative;
    }
    @include mq('desktop') {
      padding: 32px;
    }
  }
  &__content {
    padding: 0 $spacing-4 $spacing-4 $spacing-4;
    max-width: 1480px;
    @include mq('tablet') {
      padding: 0 $spacing-6 $spacing-6 $spacing-6;
    }
    @include mq('desktop') {
      padding: 0 $spacing-7 $spacing-7 $spacing-7;
    }
    h4 {
      margin: $spacing-6 0 $spacing-3 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
    > div {
      max-width: 1180px;
    }
  }
  /*  Surcharge Material  */
  .MuiPopover-paper.MuiMenu-paper {
    @include mq('mobile-only') {
      position: inherit !important;
      top: 98px !important;
      left: 0 !important;
      right: 0;
      height: auto;
      width: 300px;
      min-width: 300px;
      margin: 0 auto;
      box-shadow: 0 11px 19px -6px $color-grey--lite-alt;
      border-top: 1px solid $color-grey--lite-ter;
      ul {
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          &:hover,
          &:focus {
            background: transparent;
          }
        }
        a {
          line-height: 30px;
          padding: $spacer-6 $spacer-6 $spacer-6 $spacer-32;
          width: 100%;
          &:hover,
          &:focus {
            background: $color-blue--xlight-quater;
          }
        }
      }
    }
  }

  .MuiButtonBase-root.legal__button-nav {
    display: inline;
    background: $white;
    width: 300px;
    min-width: 300px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: $spacer-8 $spacer-32;
    box-shadow: 0px 0px 9px $color-grey--lite-alt;
    border-radius: 25px;
    font: 500 14px/24px $font-source-sans-3;
    text-align: left;

    &:hover {
      background: $white;
      opacity: 1;
    }
    img {
      margin-top: -11px;
    }
    .MuiButton-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  div[aria-hidden='true'] .legal .MuiButtonBase-root.legal__button-nav {
    border-radius: 25px 25px 0 0;
    color: color-ui(main);
    img {
      transform: rotate(180deg);
      position: relative;
      top: 8px;
    }
  }

  figure,
  .table-wrapper,
  .wp-block-table {
    width: 100%;
    max-width: none;
    overflow: auto;
    margin-top: 0;

    a {
      color: $color-secondary;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }

    table {
      table-layout: fixed;
      width: 100%;
      overflow: hidden;
      @include mq('mobile-only') {
        width: $spacing-15;
      }

      th,
      td {
        padding: $spacing-3;
        word-break: break-word;
      }
      th {
        color: $white;
        background-color: color-ui(accent);

        &:first-of-type {
          border-top-left-radius: $spacing-2;
        }
        &:last-of-type {
          border-top-right-radius: $spacing-2;
        }
        &:not(:last-of-type) {
          border-right: 1px solid $color-grey--lite;
        }
      }
      td {
        background-color: $white;
        border: 1px solid $color-grey--lite;

        &.sub-category {
          padding-top: $spacing-2;
          padding-bottom: $spacing-2;
          background-color: $color-grey--lite;
        }

        ul {
          li {
            position: relative;
            padding-left: $spacing-4;
            word-break: break-all;

            &:before {
              content: '\2022'; // Disc caracter
              color: $color-text;
              position: absolute;
              font-size: 1.75em;
              left: 0;
              top: 0;
              transform: translate(0, -20%);
            }
          }
        }
      }
    }
  }
}

.legal__nav {
  @include mq('tablet') {
    position: sticky;
    top: 82px; // 50px header + 32px parent padding
  }
  li {
    margin-bottom: $spacer-8;
    list-style-type: decimal;
    &.active {
      color: color-ui(accent);

      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
  a {
    font: 400 14px/14px $font-source-sans-3;
    &:hover,
    &.focus {
      font-weight: 500;
    }
    &.active {
      color: $primary-color;
      font-weight: 500;
    }
  }
}

.floating-image {
  margin: 80px auto 0;
  width: 100%;
  height: 200px;
  @include mq('tablet') {
    float: right;
    margin: 150px 0 48px 48px;
    width: 200px;
  }
  @include mq('desktop') {
    width: 265px;
    height: 265px;
  }
}

// The following rules are for the WordPress (SSR)
.legal-ssr-content {
  .wp-block-table {
    margin-top: $spacing-2;
    margin-bottom: $spacing-5;

    tbody tr:first-of-type td {
      text-align: center;
      font-weight: 700;
    }
  }

  li {
    position: relative;

    > * {
      margin-left: $spacing-3;
      &:before {
        content: '\2022'; // Disc caracter
        color: $color-text;
        position: absolute;
        font-size: 1em;
        left: 0;
        top: 0;
      }
    }
  }
}

.legal-ssr-content-cgu {
  h3 {
    margin: 0 0 8px 0;
  }

  ul {
    margin: 0 12px;
    li {
      list-style-type: disc;

      a::before {
        content: '';
      }
    }
  }

  ol {
    margin: 0 12px;
    li {
      list-style-type: lower-roman;

      a::before {
        content: '';
      }
    }
  }

  p {
    margin: 0 0 16px 0;
  }
}
