.availability-calendar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  min-width: 315px;
  min-height: 191px; /* Skeleton size */
  max-height: 1650px;
  overflow-y: auto;
  justify-content: space-between;
  margin-left: -15px;
  @include mq('xsmall-mobile-only') {
    min-width: 275px;
    margin-left: -20px;
  }
  @include mq('tablet') {
    margin-left: 0;
  }

  &.skeleton {
    @include mq('xsmall-mobile-only') {
      margin-right: -20px !important;
    }
  }

  .more {
    margin-top: $spacer-8;
    flex-basis: 100%;
    font-size: $text-small;
    color: $color-text;
    > svg {
      @include squaresize($spacing-4);
      fill: $color-grey--disabled;
    }
  }
  .availability-day {
    height: auto;
    overflow: hidden;

    &__header {
      display: flex;
      flex-direction: column;
      margin-bottom: $spacer-4;

      &.skeleton {
        @include mq('xsmall-mobile-only') {
          width: 42px !important;
        }
      }

      .day,
      .date {
        color: $color-text;
        text-align: center;
        font-size: $text-default;
        line-height: 16px;
      }
      .date {
        font-size: $text-small;
        color: $color-grey--medium;
      }
    }
    &__slots {
      display: flex;
      flex-direction: column;
      --slot-margin: 5px;

      & > .availability-slot {
        margin: 5px $spacer-4;
        width: 46px;
        padding: $spacer-8 5px;
        border-radius: $radius-xs;
        font-family: $font-source-sans-3;
        font-weight: 500;
        font-size: $text-small;
        background-color: color-ui(accent, light);
        color: $color-text;
        &:hover,
        &.selected {
          background-color: color-ui(accent);
          color: $white;
        }

        @include mq('xsmall-mobile-only') {
          width: 38px;
        }

        &--empty {
          text-align: center;
          color: $color-text;
          margin: var(--slot-margin);
          padding: $spacer-8 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 33px;

          @include mq('xsmall-mobile-only') {
            width: 38px;
          }
        }
      }
    }
  }
  & .calendar__navigation {
    cursor: pointer;
    width: 20px;
    height: 32px;
    & svg {
      fill: $color-grey--medium;
      @include squaresize(13px);
    }
    &.disabled svg {
      display: none;
    }
    &:hover {
      & svg {
        fill: color-ui(main);
      }
    }
  }
  .paragraph.has-sub {
    padding-left: $spacer-16;
    position: relative;
    margin: $spacer-8 $spacer-32 $spacer-4;
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      @include squaresize(10px);
      border-radius: $radius-full;
      background: color-ui(accent);
    }
  }
}
.calendar-info-availability {
  @extend %flexcenter;
  background-color: $white;
  font-size: $title-xs;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 10px;
  > span {
    display: block;
    min-width: 250px;
    text-align: center;
    border: 1px solid color-ui(accent);
    color: color-ui(accent);
    font-size: $text-small;
    padding: 10px;
    margin: 0 $spacer-24;
    border-radius: 2px;
  }
  .info-availability {
    cursor: pointer;
    font-size: 12px;
    .date {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}