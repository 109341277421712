@use "sass:math";

#search,
#tlcList {
  --gap: 10px;
  --recap-height: calc(var(--header-bar) + 10px);
  --card-width: 760px;
  --map-width: 25vw;
  --map-max-width: 400px;
  .header form {
    margin: 0 auto;
    .searchbar {
      input {
        line-height: revert;
      }
    }
  }
}
#search .input-dropdown.suggestion {
  flex-grow: 1;
}

/* Override Material UI with class filter-item scoped to search page*/
.MuiList-root.MuiMenu-list.MuiList-padding {
  padding: 0;
  .MuiListItem-button.filter-item,
  .MuiListItem-root.Mui-selected.filter-item {
    padding: $spacer-12;
  }
}
.MuiListItem-root.Mui-disabled.Mui-selected.filter-item,
.MuiListItem-root.Mui-disabled.filter-item {
  opacity: 1;
  border-bottom: 1px solid $color-separator--grey;
  //color: $color-text;
  font-weight: 500;
  padding-bottom: $spacer-12;
  pointer-events: none;
  font-size: $text-default;
  background-color: $white;
  color: $color-grey--dark;
}

.MuiListItem-button.filter-item,
.MuiListItem-root.Mui-selected.filter-item {
  font: 400 math.div($text-default, $text-large) $font-source-sans-3;
  background-color: $profile-background;
  min-width: 180px;
  min-height: 35px;
  color: $color-text;
}

.MuiListItem-root.Mui-selected.filter-item {
  color: $color-drug-primary;
  pointer-events: none;
  font: 400 math.div($text-default, $text-large) $font-source-sans-3;
}

.MuiListItem-button.filter-item:hover {
  text-decoration: none;
  background-color: $color-blue--xlight-ter;
  color: $color-text;
  font: 500 math.div($text-default, $text-large) $font-source-sans-3;
}

.filter-title {
  opacity: 1;
  font-weight: 500;
  padding-bottom: $spacer-12;
  pointer-events: none;
  font-size: $text-default;
  color: $color-grey--dark;
}

.filter-address {
  .input-dropdown {
    position: relative;
  }
}

.search {
  @extend %content-minus-header;
  display: flex;
  justify-content: center;
  @include mq('mobile-only') {
    flex-direction: column-reverse;
    padding: 0 10px;
  }

  .filter-dialog-open {
    position: fixed;
    left: 50%;
    bottom: $spacing-5;
    transform: translateX(-50%);
  }

  .filter-bar {
    position: fixed;
    top: var(--header-bar);
    height: var(--filter-bar);
    width: 100vw;
    z-index: $zindex-filterbar;
    background-color: $white;
    box-shadow: 0 3px 6px $black-opacity-sixteen;
    display: flex;
    justify-content: center;
    @include mq('mobile-only') {
      position: sticky; // Make sure filters bar stays above footer on scroll
      width: 100%;
      bottom: $spacing-3;
      top: auto;
      background: transparent;
      box-shadow: none;
      margin-top: $spacing-3;
    }

    > .filters {
      width: calc(var(--card-width) + var(--map-width));
      display: flex;
      align-items: center;
      padding: 0 $spacer-24;

      @include mq('mobile-only') {
        width: revert;
        padding: 0;
        overflow: auto;
      }
    }

    .filter-btn {
      height: 35px;
      margin: 0 $spacing-1;
      border: 2px solid $color-secondary;
      background-color: $white;
      border-radius: 25px;
      box-shadow: none;
      color: $color-secondary;
      text-transform: capitalize;
      min-width: auto;
      font: 500 math.div($text-default, $text-xl) $font-source-sans-3;
      @include mq('tablet') {
        height: 32px;
        font-size: $text-default;
        border-width: 1px;
      }
      .MuiSelect-select {
        color: $color-secondary;
        font: 500 math.div($text-default, $text-default) $font-source-sans-3;
        min-height: 0; // Make sure that the label of the button will be centered vertically
        padding: $spacing-2 $spacing-5 $spacing-2 $spacing-3;
      }
      &.MuiButton-root {
        font: 500 math.div($text-default, $text-default) $font-source-sans-3;
        padding-left: $spacing-3;
        padding-right: $spacing-3;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.btn--default {
        padding-left: $spacing-3;
        padding-right: $spacing-3;
      }
      &:hover,
      &:focus,
      &.selected {
        color: $white;
        background-color: $color-secondary;
        .MuiSelect-select {
          color: $white;
        }
      }

      &:before,
      &:after {
        display: none;
      }

      > div {
        padding-right: $spacer-12;

        &:focus {
          background: transparent;
        }
      }

      svg {
        color: inherit;
      }
    }
  }
}

.search-results {
  grid-area: searchcard;
  width: 100%;
  @include mq('mobile-only') {
    flex-grow: 1;
  }
  @include mq('tablet') {
    margin: 0 0 0 20px;
    width: var(--card-width);
  }

  .recap-search {
    @extend %flexcenter;
    margin: $spacer-24 0;
    grid-area: recap;

    .paragraph {
      font-weight: 400;
    }

    .link-info {
      display: block;
      margin-top: $spacing-2;
    }

    @include mq('mobile-only') {
      margin: 20px 5px;
      height: auto;
    }
  }

  .recap-search {
    @extend %flexcenter;
    margin: $spacing-5 0; //FOR BANNER INFO
    grid-area: recap;

    .paragraph {
      text-align: center;
      font-weight: 400;
    }

    .link-info {
      display: block;
      margin-top: $spacing-2;
    }

    @include mq('mobile-only') {
      margin: 20px 5px;
      height: auto;
    }
  }

  @include mq('mobile-tablet') {
    .availability-calendar {
      display: none;
    }
  }
}
#tlcList {
  /* padding-top: var(--header-bar); //TODO cris: padding 70px when covid banner will disappear
  @include mq('tablet') {
    padding-top: 100px; //TODO cris: padding 40px when covid banner will disappear
  } */
  @include mq('tablet') {
    padding-top: var(--header-bar);
  }
  .search {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
  }
  .search-results {
    margin-top: $spacing-5;
    @include mq('tablet') {
      margin-top: $spacing-6;
      margin-right: 20px;
    }
    @include mq('desktop') {
      margin-top: $spacing-7;
    }

    h1 {
      text-align: center;
    }
  }
  /* .search .filter-bar {
    //TODO cris: à supprimer when covid banner will disappear
    top: 100px;
    @include mq('mobile-only') {
      top: unset;
    }
  } */
}

.search-card {
  width: calc(100vw - 30px);
  position: relative;
  display: flex;
  justify-content: space-between;
  min-width: 0;
  box-shadow: $shadow-minicard-phone;
  margin: 0 auto $spacer-8 auto;
  min-height: 82px;
  background-color: $white;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;
  @include mq('tablet') {
    min-height: 142px;
    margin: 0 0 $minicard-gap;
    width: 100%;
  }
  @include mq('desktop') {
    display: grid;
    grid-template-columns: 102px 1fr 335px;
    min-height: 174px;
    padding: $spacing-3 $spacing-3 $spacing-3 0;
  }
  &:hover {
    box-shadow: $shadow-minicard-hover;
  }
  &.selected {
    box-shadow: $shadow-minicard-selected;

    &:hover {
      box-shadow: $shadow-minicard-selected;
    }
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding: $spacing-3 0 $spacing-3 $spacing-3;
    cursor: pointer;
    @include mq('tablet') {
      flex-basis: 102px;
      justify-content: start;
    }
    @include mq('mobile-only') {
      .avatar {
        @include squaresize(50px);
      }
      .avatar--online:after {
        @include squaresize(12px);
      }
    }
    p {
      font-size: $text-default;
      color: $color-grey--medium;
      font-style: italic;
    }
  }

  &__infos {
    padding: $spacing-3;
    color: $color-text;
    font-weight: 400;
    flex-grow: 1;
    @include mq('mobile-only') {
      width: fit-content;
    }
    @include mq('tablet') {
      min-height: 174px;
    }
    .tag {
      margin: $spacer-12 0 0;
    }
    & .prat {
      &__name,
      &__spe,
      &__address,
      &__secu,
      &__zipcity {
        font-weight: 400;
      }

      &__name {
        font-size: $text-default;
        font-weight: 600;
        line-height: $text-xl;
        color: color-ui(main);
        position: relative;
        font-family: $font-source-sans-3;
        word-break: break-word;
        cursor: pointer;
        display: flex;
        align-items: center;
        &.is-flagged {
          padding-right: $spacer-24;
          .care-status {
            @at-root svg#{&} {
              display: inline;
            }
            position: absolute;
            top: 0;
            right: 0;
            &.primary,
            &.favorite {
              height: $spacing-4;
              width: $spacing-4;
            }
          }
        }
        &:hover {
          text-decoration: underline;
        }
        @include mq('tablet') {
          font-size: $text-large;
          font-weight: 500;
        }
        &--fav {
          &::after {
            content: '';
            display: inline-block;
            background: url('/static/icons/heart--full.svg');
            background-repeat: no-repeat;
            position: absolute;
            bottom: 0;
            right: 0;
            @include squaresize(30px);
          }
        }
      }

      &__spe {
        font-size: $text-small;

        & + .prat__address {
          margin-top: $spacer-4;
          @include mq('tablet') {
            margin-top: $spacer-8;
          }
        }
      }

      &__address,
      &__zipcity {
        color: $color-grey--medium;
        font-size: $text-small;
      }

      &__address {
        @include killonmobile;
      }

      &__secu {
        color: $color-text;
        font-style: italic;
        font-size: $text-mention;
        //@include killonmobile;
      }

      &__rdv-button {
        margin-top: $spacing-4;
        display: flex;
        align-items: flex-start;
        font-size: $text-default;
        font-weight: 500;
        @include mq('desktop') {
          margin-top: $spacing-5;
        }

        & > a:first-child {
          &:only-child {
            padding: 5px 51px;
          }

          padding-right: 21px;
        }

        & > *:not(:only-child) {
          border-radius: 30px 0 0 30px;
        }

        & svg {
          position: relative;
          left: -5px;
          fill: $white;
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  &.disabled {
    &:before {
      content: '';
      position: absolute;
      background: $white;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.5;
      z-index: 20;
      cursor: not-allowed;
    }
  }

  .next-availability {
    background-color: color-ui(accent);
    color: $white;
    width: $minicard-status-width;
    flex: 0 0 auto; /* Fix width  */
    flex-direction: column;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    cursor: pointer;
    @include radius(right, 5px);
    @extend %flexcenter;
    @include mq('tablet') {
      border-left: 1px solid color-ui(accent);
      background-color: $white;
      color: color-ui(accent);
      width: 110px;
      margin: 15px 0;
    }
    & > * {
      font-family: $font-source-sans-3;
    }
    & > p:first-child {
      font-size: $text-mention;
      opacity: 0.8;
      @include mq('tablet') {
        font-size: $text-small;
      }
    }
    & > p:last-child {
      margin-top: 5px;
    }
    .day,
    .date,
    .month {
      font-size: $text-default;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .availability-calendar {
    max-height: 500px;
    .calendar-info-availability {
      .info-availability {
        cursor: default;
      }
      .clickable {
        cursor: pointer;
      }
    }
  }
}

.search-card--tlc {
  min-height: 0;
  padding: 0;
  grid-template-columns: 65px 1fr 90px;
  display: grid;
  &--clickable {
    cursor: pointer;
  }
  @include mq('tablet') {
    grid-template-columns: 102px 1fr 190px;
  }
  .search-card__infos {
    min-height: 0;
  }
  .action {
    background-color: color-ui(accent);
    @include radius(right, 5px);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90px;
    align-items: center;
    @include mq('tablet') {
      display: grid;
      grid-template: '. closing .' 25px '. waiting .' 25px '. button .' 1fr;
      padding: $spacing-3;
      width: 190px;
    }
    &:hover {
      background-color: color-ui(accent, dark);
      cursor: pointer;
      .button {
        .pulse {
          display: block;
          animation: pulse-white 2.5s infinite;
        }
      }
    }

    .closing {
      text-align: center;
      padding: 5px 0;
      font-size: $text-mention;
      font-weight: 400;
      grid-area: closing;
      span {
        font-weight: 500;
        font-size: $text-large;
      }
      @include mq('mobile-only') {
        span {
          font-size: $text-default;
        }
      }
      @include mq('tablet') {
        font-size: $text-default;
      }
    }

    .waiting {
      text-align: center;
      grid-area: waiting;
      span {
        font-weight: 500;
        font-size: $text-large;
      }
      @include mq('mobile-only') {
        border-top: 1px solid white;
        padding: 5px 0;
        width: 100%;
        font-size: $text-mention;
        span {
          font-size: $text-default;
          display: block;
          margin: 0;
        }
      }
    }

    svg {
      @include squaresize(41px);
    }

    .button {
      position: relative; // Make sure that pulse effect will be properly positioned
      grid-area: button;
      margin: 0 auto 0;
      width: 41px;
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.search {
  .map {
    @include killonmobile;
    @include mq('tablet') {
      grid-area: map;
      position: sticky;
      margin-top: 0;
      top: var(--header-bar);
      width: 400px;
      max-width: var(--map-max-width);
      height: calc(100vh - (var(--header-bar)));
      margin-left: $spacer-16;
    }
    @include mq('desktop') {
      top: calc(var(--header-bar));
      height: calc(100vh - (var(--header-bar)));
      width: var(--map-width);
    }
  }
}

#searchSuggestions,
#searchAddresses {
  background: $gradient;

  .searchbar {
    align-self: center;
    width: 100%;
  }

  .search-suggestion {
    padding: 50px 0 0 0;
  }
}

#searchAddresses {
  .searchbar--homepage > .input--with-icon {
    display: block;
  }
}

.no-availability {
  width: $minicard-status-width;
  font-size: $text-small;
  padding: $spacer-4;
  background-color: color-ui(accent);
  margin: 0;
  color: $white;
  display: flex;
  flex: 0 0 auto; /* Fix width  */
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  text-align: center;
  border-radius: 0 5px 5px 0;
  &.not-client {
    border: 1px solid $color-grey--medium;
    background-color: $color-grey--medium;
    color: $white;
  }
  @include mq('tablet') {
    width: 110px;
    margin: 15px 0;
    background-color: $white;
    border-left: 1px solid color-ui(accent);
    color: color-ui(accent);
    font-size: $text-default;
    &.not-client {
      border: none;
      background: $white;
      border-left: 1px solid $color-grey--medium;
      color: $color-grey--medium;
    }
  }
  @include mq('desktop') {
    justify-self: center;
    display: block;
    width: 250px;
    text-align: center;
    border: 1px solid color-ui(accent);
    color: color-ui(accent);
    font-size: 12px;
    padding: 10px;
    border-radius: 2px;
    align-self: center;
    opacity: 0.9;
    cursor: default;
    &.not-client {
      color: $color-text;
      border: 1px solid $color-grey--disabled;
    }
  }
}

/* Specific autres filtre sur TLc immédiate */
#tlc-other-filter-popover {
  .MuiPaper-rounded {
    overflow-x: initial;
    overflow-y: initial;
    padding: 10px;
    min-width: 300px;

    .input-wrapper.address {
      > svg {
        fill: $color-grey--medium;
        top: 3px;
      }
    }

    .filter-practitioners {
      margin-top: $spacing-4;

      .wrapper--radio-buttons {
        margin-bottom: 0;
      }
    }

    .filter-ctas {
      margin-top: $spacing-2;
      text-align: right;

      .btn {
        display: inline-flex;
      }
    }
  }
}

.search-related {
  ul {
    column-count: 1;
    @include mq('tablet') {
      column-count: 2;
    }
    @include mq('desktop') {
      column-count: 5;
    }
  }
  li + li {
    margin-top: $spacer-12;
  }
  a {
    color: $color-text;
    &:hover {
      color: color-ui(main);
      text-decoration: underline;
    }
  }
}

.popup-search-filters {
  .filter-btn {
    width: 100%;
    height: 35px;
    padding: 0 $spacer-12;
    border: 2px solid $color-secondary;
    background-color: $white;
    border-radius: 25px;
    box-shadow: none;
    color: $color-secondary;
    text-transform: capitalize;

    &:before,
    &:after {
      display: none; // Remove underline style
    }

    &:not(:first-of-type) {
      margin-top: $spacing-4; // Add space between each filter select
    }
    &:last-of-type {
      margin-bottom: $spacing-8; // Make sure filters won't sit below dialog ctas on mobile
      @include mq('tablet') {
        margin-bottom: $spacing-5;
      }
    }

    .MuiSelect-select:focus {
      background-color: transparent; // Remove default focus style
    }
    .MuiSelect-select {
      color: $color-secondary;
      font: 500 math.div($text-default, $text-default) $font-source-sans-3;
      min-height: 0; // Make sure that the label of the button will be centered vertically
      padding: $spacing-2 $spacing-5 $spacing-2 $spacing-1;
    }

    > div {
      padding-right: $spacer-12;
    }

    svg {
      color: inherit;
    }
  }
  .filter-dialog-ctas {
    display: flex;
    justify-content: center;
    position: fixed;
    left: $spacing-5;
    right: $spacing-5;
    bottom: $spacing-5;
    @include mq('tablet') {
      position: relative; // Reset fixed positionning for tablet
      left: auto;
      right: auto;
      bottom: auto;
    }
    .btn {
      margin: 0; // Reset top/bottom margin
      &:not(:first-of-type) {
        margin-left: $spacing-3; // Add space between buttons
      }
      &.btn--outline--main {
        &:not(:hover) {
          background-color: $white; // Make sure the BG is a solid white so that we won't see elements passing behind
        }
      }
    }
  }
}

#tlcList {
  background-color: $white;
}
