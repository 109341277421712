@keyframes height-grow {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 300vh;
  }
}

.rdv-form {
  $border-radius: 5px;
  $border: 4px;
  $color-valid: color-ui(main);
  --spacing: $spacer-16;
  border-radius: $border-radius + 3px;
  background-clip: padding-box;
  background-color: $white;
  height: auto;
  z-index: 20;
  position: sticky;
  top: calc(var(--header-bar) + 8px);
  box-shadow: 0 1px 3px $black-opacity-twelve,
    0 1px 2px $black-opacity-twenty-four;

  &__title {
    color: $white;
    @include radius(top, $border-radius);
    font-family: $font-outfit;
    font-weight: 500;
    text-align: center;
    background-color: color-ui(accent);
    padding: $spacer-8 $spacer-16;
    h4 {
      font-weight: 700;
      line-height: 17px;
    }
    span {
      font-size: $text-small;
    }
  }
  form {
    $border-radius: 5px;
    transition: height 2s ease;
    background-color: $white;
    padding: 20px 0 0 0;
    border-radius: $border-radius;
    > * {
      padding: 0px 20px 28px 20px;
    }
    > div:not(:last-of-type) {
      border-left: $border solid $color-valid;
      .number {
        background-color: $color-valid;
        left: -34px;
        &::after {
          content: '';
          position: absolute;
          background-image: url(/static/icons/check.svg);
          background-position: center;
          background-size: 60%;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          z-index: 2;
          background-color: $color-valid;
          border-radius: 50%;
        }
      }
    }
    > div:last-of-type {
      color: color-ui(accent);
      > label {
        color: color-ui(accent);
      }
    }
  }
  .label {
    position: relative;
    & > label:first-of-type {
      display: block;
      margin-bottom: 10px;
      color: inherit;
      font-size: $text-default;
      font-weight: 500;
      font-family: $font-source-sans-3;
    }
    &.is-disabled {
      color: $color-grey--default;
      > label {
        color: $color-grey--default;
      }
      .number {
        background-color: $color-grey--default;
        &:after {
          display: none;
        }
      }
    }
  }

  .number {
    position: absolute;
    @extend %flexcenter;
    color: white;
    border-radius: 50%;
    left: -31px;
    top: 0;
    @include squaresize(25px);
    background-color: color-ui(accent);
  }

  //TODO cristela ==> clean
  @include mq('mobile-only') {
    width: 100%;
    position: relative;
    top: 0;
    box-shadow: none;
    border-radius: 0;
    min-height: calc(100vh - var(--header-bar));
    &__title {
      border-radius: 0;
    }
    form {
      padding-left: 20px;
      span.number {
        left: -30px;
      }
    }
  }

  .rdv {
    &__centerId,
    &__availability {
      max-height: 0;
      animation: height-grow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      animation-fill-mode: forwards;
    }
    &__centerId > div {
      display: flex;
      align-items: center;
      & a {
        border: 1px solid color-ui(main);
        background-color: transparent;
        color: color-ui(main);
      }
    }
    &__practitioner > div {
      color: $color-text;

      display: flex;
      justify-content: space-between;
    }
    &__center {
      & > div {
        display: flex;
        align-items: center;
        & > a {
          margin-left: 7px;
        }
      }
      div > div {
        //name of the center
        color: $color-text;
      }
    }
    &__type {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      min-height: 94px;
      label {
        flex-basis: 100%;
      }
      a {
        background-color: color-ui(main, xlight);
        border-radius: $border-radius;
        padding: 10px 7.5px;
        border: 1px solid transparent;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        color: $color-text;
        svg {
          fill: $color-text;
          @include squaresize(24px);
        }
        & + a {
          margin-top: 10px;
        }
        &.btn:hover {
          background-color: color-ui(accent);
          color: white;
          > svg {
            fill: white;
          }
        }
        &.selected {
          background-color: color-ui(accent, light);
          border: 1px solid color-ui(accent);
          opacity: 1;
        }
      }
    }
  }
  .select-prat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    p {
      color: $color-text;
      font-size: $text-default;
    }
    .btn {
      margin: $spacer-12 auto 0;
    }
  }

  .select-wrapper {
    .MuiInput-formControl,
    .MuiSelect-select {
      &:before,
      &:after {
        display: none;
      }
    }
    font: 400 12px/18px $font-source-sans-3;
    color: $color-text;
  }

  .rdv__availability {
    .availability-tlc {
      margin: $spacer-12 auto;
      border: 2px solid $grey-background;
      border-radius: $radius-xs;
      position: relative;
      > img {
        position: absolute;
        top: -2px;
        left: -2px;
        width: 80px;
      }
      &--information {
        padding: 0 $spacer-12 0 88px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 94px;
        .title {
          font-size: $text-default;
          font-weight: 500;
          color: color-ui(accent);
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .icon {
            position: relative;
            margin-left: 5px;
            &:after {
              content: '';
              position: absolute;
              top: 8px;
              right: -12px;
              background-color: $color--online;
              border-radius: 50%;
              @include squaresize(8px);
            }
          }

          svg {
            fill: color-ui(accent);
          }
        }
        .subtitle {
          font-size: 10px; /* Very specific to TLC choice element*/
          color: $color-text;
          .link-info {
            margin-left: 3px;
          }
        }
        .checkbox {
          align-items: flex-end;
          justify-content: flex-end;
          .label {
            flex-direction: row-reverse;
            font-size: 12.5px;
            font-weight: 500;
          }

          .label__check {
            margin: 0 $spacer-4 0 0;
          }
        }
      }
    }
  }
  .selected--item {
    display: block !important;
    background: $color-grey--background;
    padding: $spacer-8 $spacer-12;
    border-radius: $radius-xs;
    color: $color-text;
    width: 100%;
    span {
      font-weight: 500;
    }
  }
  .btn--outline--main {
    margin: 10px auto;
    width: 200px;
  }

  /* No availabilities */
  .no-form {
    text-align: center;
    padding: $spacer-24 $spacer-12;
    .info {
      font-size: $text-default;
      font-weight: 500;
      color: $color-grey--medium;
    }
    .rdv-phone {
      margin-top: $spacer-24;
      p {
        color: $color-text;
      }
      .phone {
        color: color-ui(accent);
        font-size: $title-small;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .calendar-info-availability {
      position: initial;
      span {
        border-color: $color-grey--medium;
        color: $color-text;
      }
    }
  }
  .wrapper-first-availability {
    position: relative;
    .availability-calendar {
      padding: 0 $spacer-24 $spacer-12;
      min-height: auto;
    }
    .error {
      font-size: $text-small;
      line-height: 18px;
      padding: 0 $spacer-24;
      display: block;
    }
  }
  .first-availability {
    padding: 0 $spacer-16 $spacer-24 20px;
    position: absolute;
    z-index: 1;
    top: 58px;
    left: 0;
    right: 0;
    @include mq('tablet') {
      padding: 0 $spacer-16 $spacer-24 20px;
    }
    .calendar-info-availability {
      position: unset;
      background: none;
      .info-availability {
        font-size: 12px;
        background: white;
      }
      > span {
        color: $color-grey--default;
        border: 1px solid $color-grey--default;
      }
    }
    &.is-active {
      .date {
        color: color-ui(accent);
      }
      .calendar-info-availability {
        > span:hover {
          color: color-ui(accent);
          border-color: color-ui(accent);
        }
      }
    }
  }
  /* Mention that appears under the consultation reasons */
  .select-mention {
    & + .is-right {
      margin-top: 0;
    }
  }
  .is-right {
    margin-top: 4px;
  }
  .warning {
    &:before {
      top: 1px;
    }
  }
  /* Specific small window screen*/
  &.unstick {
    position: relative;
    top: 0px;
  }

  .wrapper--radio-buttons {
    justify-content: space-between;
    @include mq('mobile-only') {
      justify-content: space-evenly;
    }
    .radio-button {
      margin: 16px 0 0;
    }
  }
}

.form-block--info {
  background: $color-blue--xlight;
  border-radius: $radius-default;
  padding: $spacer-8 $spacer-12;
  color: $color-text;
  p {
    fill: $color-secondary;
  }
}
