// PROFILE-HEADER PRAT
.profile-header {
  $infocard-width: minmax(300px, var(--searchcard-width));
  $rdv-width: 420px;

  grid-template-columns: 1fr 1fr;
  background: $gradient-dark;
  padding: $spacer-12;
  position: sticky;
  top: var(--header-bar);
  z-index: $zindex-profile;
  min-height: 140px;
  height: auto;
  @include mq('tablet') {
    display: grid;
    align-items: center;
    grid-template-areas: 'prat prat';
    position: relative;
    top: unset;
    z-index: 3;
  }
  @include mq('desktop') {
    grid-template-columns: 1fr $infocard-width $rdv-width 1fr;
    grid-template-areas: '. prat . . ';
  }
  &__prat {
    grid-area: prat;
    width: $infocard-width;
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-areas:
      'avatar info '
      'avatar button ';
    grid-gap: 8px;
    @include mq('tablet') {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    & > .avatar {
      grid-area: avatar;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      & > img {
        @include squaresize(80px);
      }
      @include mq('mobile-only') {
        @include squaresize(80px);
        align-self: flex-start;
      }
    }
    & > .name-n-spe {
      grid-area: info;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @include mq('tablet') {
        margin-left: 20px;
      }
      > .name {
        display: flex;
        font-size: 22px;
        line-height: 25px;
      }
      > .spe {
        color: $white;
        font: 400 18px/24px $font-source-sans-3;
        margin-top: $spacer-8;
        span {
          font-size: $text-default;
          font-style: italic;
          margin-left: $spacer-24;
        }
      }
      //TODO cristela Clean
      @media #{$tab} {
        > .name {
          font-size: $text-xl;
        }
        > .spe {
          font-size: $text-default;
          margin-top: 0;
          margin-bottom: $spacer-8;
          span {
            display: block;
            margin-left: 0;
          }
        }
      }

      @media #{$phone} {
        margin-top: 0;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        > .name {
          font-size: $text-large;
        }
        > .spe {
          span {
            display: inline-block;
            margin-left: $spacer-24;
          }
        }
      }
    }

    & > .profile-button {
      grid-area: button;
      margin-left: auto;
      display: none;
      @media #{$phone} {
        display: flex;
        width: 100%;
        height: fit-content;
        margin-right: auto;
      }
    }
  }
}

// PROFILE-HEADER CENTER

.profile-header.center-header {
  @include mq('mobile-only') {
    & + .profile-content {
      margin-top: 16px;
    }
  }
  .profile-header__prat {
    display: grid;
    grid-template-columns: 110px 8px 1fr;
    grid-template-areas:
      'avatar . info'
      'avatar . center-count'
      'center-details . button ';
    grid-gap: 0;
    justify-items: center;
    color: $white;
    @include mq('tablet') {
      grid-template-columns: 120px 16px 1fr;
    }
    @include mq('desktop') {
      grid-template-columns: 100px 20px 1fr;
      grid-template-areas:
        'avatar . info '
        'avatar . center-count';
      justify-items: start;
      .center-count {
        font-size: $text-large;
      }
      .center-type {
        font-size: $text-default;
      }
    }
  }

  .name-n-spe {
    align-self: end;
    text-align: center;
    margin-bottom: $spacer-4;
    margin-left: 0;
    @include mq('tablet') {
      justify-self: start;
      text-align: left;
    }
  }
  .center-count {
    grid-area: center-count;
    font-size: $text-mention;
    align-self: start;
    display: flex;
    @include mq('tablet') {
      justify-self: start;
      align-self: center;
      margin-left: 40px;
    }
    @include mq('desktop') {
      align-self: start;
      margin: 0;
    }
    @include mq('mobile-tablet') {
      .spe-count,
      .prat-count,
      .children-count {
        text-align: center;
        span {
          display: block;
          font-size: $text-default;
          font-weight: bold;
        }
      }
    }
    .spe-count {
      margin-right: $spacer-16;
    }
  }
  .center-details {
    grid-area: center-details;
    @include mq('mobile-only') {
      margin-top: $spacing-4;
    }
    @include mq('mobile-tablet') {
      text-align: center;
    }
  }
  .center-type {
    display: block;
    font-size: $text-mention;
    align-self: start;
    text-align: center;
    margin-top: $spacer-4;
    opacity: 0.8;
    fill: $white;
    > svg {
      margin-right: $spacer-4;
      display: inline;
    }
    @include mq('desktop') {
      margin-top: 0;
      text-align: left;
      align-self: center;

      &:not(:first-child) {
        margin-left: $spacing-4;
      }
    }
  }
  .opening-hours {
    & > span {
      border-radius: $spacing-4;
    }
    @include mq('mobile-tablet') {
      display: inline-block;
      margin-top: $spacing-1;
    }
    @include mq('desktop') {
      margin-left: $spacing-2;
    }
  }
  .profile-button {
    justify-content: center;
    margin-top: $spacer-16;
  }
}
