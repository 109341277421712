@use "sass:math";

$text-size--small-title: 32px;

$break-point--max-phone: 767px;
$break-point--max-tab: 1023px;
$break-point--min-desk: 768px;
$break-point--l-desk: 1890px;
$break-point--xl-desk: 3200px;

$phone: 'only screen and (max-width: ' $break-point--max-phone ')';
$tab: 'only screen and (max-width: ' $break-point--max-tab ')';
$desk: 'only screen and (min-width: ' $break-point--max-phone ')';
$ldesk: 'only screen and (min-width: ' $break-point--l-desk ')';
$xldesk: 'only screen and (min-width: ' $break-point--xl-desk ')';

$zindex-headerbar: 1299;
$zindex-footer: 900;
$zindex-menu: 9999;
$zindex-profile: 999;
$zindex-searchbar: 1200;
$zindex-filterbar: 1199;

$font-source-sans-3: 'Source Sans 3', sans-serif;
$font-outfit: Outfit, sans-serif;

:root {
  --pratbar: 60px;
  --filter-bar: 50px;
  --header-and-pratbar: calc(var(--header-bar) + var(--pratbar));
  --document-card-height: 275px;
  --header-bar: 56px;
  --content-height: calc(100vh - var(--header-bar));
  --searchcard-width: 760px;
}

$radius-default: 6px;

$radius-xs: 4px;
$radius-med: $radius-default;
$radius-xl: 10px;
$radius-full: 100%;
$radius-button: 100px;

// SPACER - ORIGINAL SYSTEM

$spacer-square: 10px;
$spacer-default: 8px;

$spacer-4: $spacer-default * 0.5; // 4px
$spacer-6: math.div($spacer-default, 1.5); // 6px
$spacer-8: $spacer-default; // 8px
$spacer-12: $spacer-default * 1.5; // 12px
$spacer-16: $spacer-default * 2; // 16px
$spacer-24: $spacer-default * 3; // 24px
$spacer-32: $spacer-default * 4; // 32px
$spacer-40: $spacer-default * 5; // 40px

// SPACER - NEW SYSTEM

$spacing-default: 16px;

$spacing-1: $spacing-default * 0.25; // 4px
$spacing-2: $spacing-default * 0.5; // 8px
$spacing-3: $spacing-default * 0.75; // 12px
$spacing-4: $spacing-default * 1; // 16px
$spacing-5: $spacing-default * 1.5; // 24px
$spacing-6: $spacing-default * 2; // 32px
$spacing-7: $spacing-default * 3; // 48px
$spacing-8: $spacing-default * 4; // 64px
$spacing-9: $spacing-default * 6; // 96px
$spacing-10: $spacing-default * 8; // 128px
$spacing-11: $spacing-default * 12; // 192px
$spacing-12: $spacing-default * 16; // 256px
$spacing-13: $spacing-default * 24; // 384px
$spacing-14: $spacing-default * 32; // 512px
$spacing-15: $spacing-default * 40; // 640px
$spacing-16: $spacing-default * 48; // 768px

/**************/
/* BOX SHADOWS */
/**************/

/* wideness*/
$shadow-spread-wide: 3px 3px 5px;
$shadow-spread-condensed: 1px 1px 3px;
/* colors */
$shadow-color-light: rgba(0, 0, 0, 0.12);
$shadow-color-dark: rgba(0, 0, 0, 0.25);
$shadow-color-main: rgba(0, 0, 0, 0.25);
$shadow-color-error: rgba(240, 0, 0, 0.25);

/* Box-shadows combinations */
$shadow-condensed-light: $shadow-spread-condensed $shadow-color-light;
$shadow-condensed-dark: $shadow-spread-condensed $shadow-color-dark;

$shadow-wide-light: $shadow-spread-wide $shadow-color-light;
$shadow-wide-dark: $shadow-spread-wide $shadow-color-dark;

@mixin shadow($spread, $color) {
  box-shadow: $spread $color;
}

/* default*/
$shadow-default: $shadow-wide-light;

/**************/
/* MINICARD */
/**************/
$minicard-width: 375px;
$minicard-rdv-height: 127px; // This looks like the maximum height (TLC starting in less than 15 minutes)
$minicard-careteam-height: 90px;
$minicard-gap: 12px;
$minicard-status-width: 100px;

$shadow-minicard-phone: $shadow-default;
$shadow-minicard-hover: $shadow-condensed-light;
$shadow-minicard-selected: $shadow-wide-dark;
