//@use "sass:map";  // Not valid on compilator css

@function color-ui($palette, $shade: 'default') {
  @return map-get(map-get($color-ui, $palette), $shade);
}

@function colors($palette, $shade: 400) {
  @return map-get(map-get($colors, $palette), $shade);
}

$color-ui: (
  main: (
    default: #64c9c3,
    light: #86e1db,
    dark: #3fb0aa,
    darker: #4eb3c9,
    xlight: #ecf5f5,
  ),
  accent: (
    default: #f68092,
    light: #fcd8dd,
    xlight: #fdf1f0,
    dark: #e35d78,
  ),
);
.documents,
.popup,
.param-account,
select {
  --background-color: #f1f4f5;
}
$color-grey--default: #899b9e;
$color-grey--medium: #a1a1a1;
$color-grey--medium-alt: #aaaaaa;
$color-grey--dark: #888888;
$color-grey--background: #f1f4f5;
$color-grey--lite: #f1f1f1;
$color-grey--lite-alt: #dddddd;
$color-grey--lite-ter: #eeeeee;
$color-grey--xtra-lite: #f8f8f8;
$color-grey--disabled: #b7b7b7;

$color-text: #606060;
$color-placeholder: #a8a8a8;

$color-blue--xlight: #e4f2f1;
$color-blue--xlight-alt: #e0f4f3;
$color-blue--xlight-ter: #e5f4f7;
$color-blue--xlight-quater: #edf8f9;
$color-blue--xlight-fifth: #f7f8fc;
$color-blue--xlight-six: #eff9f9;
$color-blue--light: #b7e6e3;
$color-blue--light-alt: #bfe9e6;
$color-blue--light-ter: #d1eaed;

$profile-background: #f7f7f7;
$center-color: $color-blue--xlight;

//COULEUR RESEAUX Sociaux
$facebook: #3b5998;
$twitter: #33ccff;
$google: #dd4b39;
$youtube: #ff3333;
$linkedin: #0e76a8;

$color-separator: #e0efee;
$color-separator--grey: #dedede;
$white: #ffffff;
$white-cream: #fafafa;
$white-salmon: #ffe8eb;
$white-blue: #f8fafa;
$white-pink: #fff1f4;
$white-main: #e4f2f1;
$chalkboard: #222222;
$chalkboard-dark: #111111;
$black: black;

$white-opacity-zero: rgba(255, 255, 255, 0);
$white-opacity-thirty: rgba(255, 255, 255, 0.3);
$white-opacity-half: rgba(255, 255, 255, 0.5);
$white-opacity-seventy: rgba(255, 255, 255, 0.7);
$white-opacity-eighty: rgba(255, 255, 255, 0.8);
$white-opacity-ninety: rgba(255, 255, 255, 0.9);
$white-opacity-hundred: rgba(255, 255, 255, 1);
$grey-opacity-twelve: rgba(100, 100, 100, 0.12);
$grey-opacity-fourteen: rgba(100, 100, 100, 0.14);
$grey-opacity-twenty: rgba(100, 100, 100, 0.2);
$black-opacity-five: rgba(0, 0, 0, 0.05);
$black-opacity-ten: rgba(0, 0, 0, 0.1);
$black-opacity-twelve: rgba(0, 0, 0, 0.12);
$black-opacity-fourteen: rgba(0, 0, 0, 0.14);
$black-opacity-sixteen: rgba(0, 0, 0, 0.16);
$black-opacity-twenty: rgba(0, 0, 0, 0.2);
$black-opacity-twenty-four: rgba(0, 0, 0, 0.24);
$black-opacity-twenty-nine: rgba(0, 0, 0, 0.29);
$black-opacity-thirty: rgba(0, 0, 0, 0.3);
$black-opacity-half: rgba(0, 0, 0, 0.5);
$black-opacity-sixty: rgba(0, 0, 0, 0.6);
$black-opacity-eighty: rgba(0, 0, 0, 0.8);
$green-opacity-fifteen: rgba(109, 212, 180, 0.15);
$blue-xlight-opacity-hundred: rgba(236, 245, 245, 1);
$accent-opacity-zero: rgba(227, 93, 120, 0);
$accent-opacity-seventy: rgba(227, 93, 120, 0.7);
$accent-opacity-hundred: rgba(227, 93, 120, 1);

$error: #f35252;
$error-med: #f1a2a3;
$error-light: #fdecea;
$error-xlight: #efe5e4;
$error-dark: #cf4646;

$success: #6dd4b4;
$success-med: #82cca7;
$success-light: #edf7ed;
$success-dark: #13a55c;

$star: #ffd04e;

$warning: #f6900b;
$warning-med: #f0b48d;
$warning-light: #fff4e5;
$warning-dark: #ff7b00;

$color--ok: #86f1b0;
$color--ko: $error;
$color--checkbox: #6dd4b4;
$color--online: #30e2a6;

$color-background: rgb(246, 246, 254);
$color-secondary: #4eb3c9;

$color--online: $color--ok;

$login-link: $color-secondary;
$input-background: #f1f4f5;
$input-border-ok: #55aba6;
$input-box-shadow: #cccccc;

$title-color: color-ui(main);
$blue-grey-background: #f1f4f5;
$blue-light-background: #e3efee;
$grey-background: #ebebeb;
$blue-navy: #120a8f;

$blue-highlight: #ccebe9;
$placeholder: $color-text;

$bg-is-centered: #fff;

$color-drug-primary: #50b3c8;
$color-drug-light: #96d1de;

$blue-bg-illus: #50a9bc;

$bandeau-default: #5bb5ae;
$bandeau-alt: #449dab;

$minicard-icons: #58b0aa;

$documents-error: #fcf2f3;

$colors-metro-line-nine: #d5c900;
$colors-metro-line-ten: #e4b327;

//
//
$colors: (
  grey: (
    100: #f8f9fa,
    200: #e1e7ec,
    300: #cfd6de,
    400: #b8c4ce,
    500: #8895a7,
    600: #5f6b7a,
    700: #212934,
    800: #2d3748,
    900: #1a202c,
  ),
  aqua: (
    100: #e6fffe,
    200: #a7eeea,
    300: #6ed7d2,
    400: #3caea4,
    500: #2d9188,
    600: #1d655e,
    700: #124544,
  ),
  blue: (
    100: #ebf8ff,
    200: #bee3f8,
    300: #90cdf4,
    400: #63b3ed,
    500: #4299e1,
    600: #3182ce,
    700: #2b6cb0,
    800: #2c5282,
    900: #2a4365,
  ),
  green: (
    100: #f0fff4,
    200: #c6f6d5,
    300: #9ae6b4,
    400: #68d391,
    500: #48bb78,
    600: #38a169,
    700: #2f855a,
    800: #276749,
    900: #22543d,
  ),
  teal: (
    100: #e6fffa,
    200: #b2f5ea,
    300: #81e6d9,
    400: #4fd1c5,
    500: #38b2ac,
    600: #319795,
    700: #2c7a7b,
    800: #285e61,
    900: #234e52,
  ),
  orange: (
    100: #fffaf0,
    200: #feebc8,
    300: #fbd38d,
    400: #f6ad55,
    //2
      500: #ed8936,
    600: #dd6b20,
    700: #c05621,
    800: #9c4221,
    900: #7b341e,
  ),
  yellow: (
    100: #fffff0,
    200: #fefcbf,
    300: #faf089,
    400: #f6e05e,
    //3
      500: #ecc94b,
    600: #d69e2e,
    700: #b7791f,
    800: #975a16,
    900: #744210,
  ),
  indigo: (
    100: #ebf4ff,
    200: #c3dafe,
    300: #a3bffa,
    400: #7f9cf5,
    500: #667eea,
    600: #5a67d8,
    700: #4c51bf,
    800: #434190,
    900: #3c366b,
  ),
  purple: (
    100: #faf5ff,
    200: #e9d8fd,
    300: #d6bcfa,
    400: #b794f4,
    500: #9f7aea,
    600: #805ad5,
    700: #6b46c1,
    800: #553c9a,
    900: #44337a,
  ),
  pink: (
    100: #fff5f7,
    200: #fed7e2,
    300: #fbb6ce,
    400: #f687b3,
    500: #ed64a6,
    600: #d53f8c,
    700: #b83280,
    800: #97266d,
    900: #702459,
  ),
  red: (
    100: #fff5f5,
    200: #fed7d7,
    300: #feb2b2,
    400: #fc8181,
    //(1)
      500: #f56565,
    600: #e53e3e,
    700: #c53030,
    800: #9b2c2c,
    900: #742a2a,
  ),
);

// $patient-colors: 0 red, 1 orange, 2 yellow, 3 green, 4 teal, 5 blue, 6 indigo,
//   7 purple, 8 pink, 9 red, 10 orange, 11 yellow, 12 green, 13 teal, 14 blue,
//   15 indigo;

// Temporary for patient chips to match <PatientNameTag /> colors
$mui-patient-colors: 0 #e57373, 1 #ba68c8, 2 #7986cb, 3 #4fc3f7, 4 #4db6ac,
  5 #aed581, 6 #fff176, 7 #ffb74d, 8 #a1887f, 9 #e57373, 10 #ba68c8, 11 #7986cb,
  12 #4fc3f7, 13 #4db6ac, 14 #aed581, 15 #fff176;

$gradient: linear-gradient(to right, color-ui(main), #50b3c8);
$gradient-dark: linear-gradient(to left, #1a8ca5, #21bbb2);
$gradient-secondary: linear-gradient(to left, #4eb3c9, #4eb3c9);
