.accordeon {
  > * {
    background-color: white;
  }
  &__body {
    > div {
      width: 100%;
    }
  }
}
