/* Arguments for mixin button */
$filled--accent: color-ui(accent), $white, color-ui(accent), $white;
$filled--accent_hover: color-ui(accent, dark), $white, color-ui(accent, dark),
  $white;
$filled--accent-reverse: color-ui(accent), $white, color-ui(accent), $white;
$filled--accent-reverse_hover: $white, color-ui(accent), $white,
  color-ui(accent);

$filled--main: color-ui(main), $white, color-ui(main), $white;
$filled--main_hover: color-ui(main, dark), $white, color-ui(main, dark), $white;

$filled--white: $white, color-ui(accent), $white, color-ui(accent);
$filled--white_hover: color-ui(accent), $white, color-ui(accent), $white;

$filled--alert: $error, $white, $error, $white;
$filled--alert_hover: $error-dark, $white, $error-dark, $white;

$filled--white-dark: $white, $color-text, $white, $color-text;
$filled--white-dark_hover: $white, $color-text, $white, $color-text;

$outline--accent: transparent, color-ui(accent), color-ui(accent),
  color-ui(accent);
$outline--accent_hover: color-ui(accent, dark), $white, color-ui(accent, dark),
  $white;

$outline--main: transparent, color-ui(main), color-ui(main), color-ui(main);
$outline--main_hover: color-ui(main, dark), $white, color-ui(main, dark), $white;

$outline--secondary: transparent, $color-secondary, $color-secondary,
  $color-secondary;
$outline--secondary_hover: $color-secondary, $white, $color-secondary, $white;

@mixin button($bg, $color, $border, $fill) {
  background-color: $bg;
  color: $color;
  border: 2px solid $border;
  border-radius: $radius-button;
  font-weight: 500;
  svg {
    fill: $fill !important; /* Material ui*/
  }
  :after,
  :before {
    color: $fill;
  }
}

@mixin icon-size($size) {
  svg {
    width: $size;
    height: $size;
  }
}

@mixin icon-direction($direction: left) {
  svg {
    @if ($direction== 'left') {
      margin-right: $spacer-8;
    } @else {
      margin-left: $spacer-8;
    }
  }
}
.btn {
  user-select: none;
  cursor: pointer;
  border: none;
  text-align: center;
  @extend %flexcenter;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: none;
  }

  svg {
    transition: all 0.3s ease;
  }
  /* DESIGN  + COLORS */
  &--filled {
    &--accent {
      @include button($filled--accent...);
      &:hover {
        @include button($filled--accent_hover...);
      }
    }
    &--accent-reverse {
      @include button($filled--accent-reverse...);
      box-shadow: $shadow-wide-light;
      &:hover {
        @include button($filled--accent-reverse_hover...);
      }
      &:active {
        box-shadow: none;
      }
    }
    &--main {
      @include button($filled--main...);
      &:hover {
        @include button($filled--main_hover...);
      }
    }
    &--white {
      @include button($filled--white...);
      box-shadow: $shadow-wide-light;
      &:hover {
        @include button($filled--white_hover...);
        @include shadow($shadow-spread-condensed, $shadow-color-light);
      }
      &:active {
        box-shadow: none;
      }
    }
    &--white-dark {
      @include button($filled--white-dark...);
      box-shadow: $shadow-wide-light;
      &:hover {
        @include button($filled--white-dark_hover...);
        @include shadow($shadow-spread-condensed, $shadow-color-light);
      }
    }
    &--alert {
      @include button($filled--alert...);
      &:hover {
        @include button($filled--alert_hover...);
      }
    }
  }
  &--outline {
    &--accent {
      @include button($outline--accent...);
      &:hover {
        @include button($outline--accent_hover...);
      }
    }
    &--main {
      @include button($outline--main...);
      &:hover {
        @include button($outline--main_hover...);
      }
    }
    &--secondary {
      @include button($outline--secondary...);
      &:hover {
        @include button($outline--secondary_hover...);
      }
    }
  }
  & + .btn {
    margin: 12px 0;
  }
}
/* RENDER TYPE */

.btn--default {
  min-width: 150px;
  border-radius: $radius-button;
  padding: $spacer-4 $spacer-24;
  font: 500 14px/18px $font-source-sans-3;
  @extend .ripple;
}

/* VARIANT */
.btn--with-icon {
  &--left {
    @include icon-direction();
    @include icon-size(1.2em);
    padding-left: $spacer-16;
    flex-direction: row-reverse;
  }
  &--right {
    @include icon-direction(right);
    @include icon-size(1.2em);
    padding-right: $spacer-16;
  }
}
.btn--icon {
  @include icon-size(34px);
  border-radius: 100%;
  padding: 0;
  min-width: auto;
}

/* SIZE */
.btn--big {
  padding: $spacer-8 $spacer-24;
  font: 500 16px/20px $font-source-sans-3;
}
.btn--xbig {
  padding: $spacer-8 $spacer-32;
  font: 500 20px/24px $font-source-sans-3;
}
.btn--wide {
  width: 100%;
}
.btn--wide-big {
  @extend .btn--big;
  width: 100%;
}
.btn--wide-xbig {
  @extend .btn--xbig;
  width: 100%;
}

.btn--link {
  border-radius: none;
  color: $color-text;
  background: transparent;
  font: 500 14px/18px $font-source-sans-3;
  svg {
    opacity: 0.8;
    transition: none;
  }
  &:hover {
    color: color-ui(main);
  }
  &.btn--big {
    padding: $spacer-8 0;
    font: 500 16px/20px $font-source-sans-3;
  }
  &.btn--xbig {
    padding: $spacer-8 0;
    font: 500 20px/24px $font-source-sans-3;
  }
  &--greylight {
    color: $color-placeholder;
  }
  &--main {
    color: $color-secondary;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
/* DISABLED */
.btn {
  &[disabled=''] {
    background-color: $color-grey--disabled;
    border-color: $color-grey--disabled;
    color: $white;
    cursor: not-allowed;
    &:active,
    & * {
      pointer-events: none;
    }
  }
}

/* For 2 aligned buttons */
.wrapper-buttons {
  display: flex;
  flex-direction: column;
}

/* Not a component button */
.link {
  text-decoration: underline;
  line-height: 25px;
  font-size: 10px;
  color: $color-grey--medium;
  margin-left: $spacer-8;
  &:hover {
    color: color-ui(main);
  }
}
.add-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: $spacer-8;
  font-size: $text-default;
  color: $color-text;
  fill: $color-text;
  opacity: 0.7;
  transition: all 0.2s ease;
  font-weight: 500;
  cursor: pointer;
  svg {
    margin-right: 8px;
    @include squaresize(20px);
  }
  &:hover {
    color: color-ui(accent);
    opacity: 1;
  }
}

.link-info {
  //font-size: 12px;
  font-family: $font-source-sans-3;
  font-style: italic;
  color: $color-secondary;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
/* USED ??? */
.link-icon {
  width: 21px;
  height: 21px;
  background: color-ui(main, darker);
  color: $white;
  text-align: center;
  line-height: 21px;
  font-size: 14px;
  margin-left: $spacer-8;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
}
