.avatar {
  position: relative;
  border-radius: 50%;
  min-height: 25px;
  min-width: 25px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &--online {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 85.355%; /* x = x0 + r*cos(a) where (x0,y0) are center coordinates, r the radius and a the angle, so for a=45° (bottom right positionning), the absolute position will always be ~85.355% */
      left: 85.355%; /* y = y0 + r*sin(a) where (x0,y0) are center coordinates, r the radius and a the angle, so for a=45° (bottom right positionning), the absolute position will always be ~85.355% */
      transform: translate(
        -50%,
        -50%
      ); /* Position the center of the circle on the avatar circumference */
      background-color: $color--online;
      border-radius: 50%;
      @include squaresize(15px);
    }
  }
  &-size {
    &--xsmall {
      @include squaresize(25px);
    }
    &--small {
      @include squaresize(55px);
      &:after {
        @include squaresize(12px);
      }
    }
    &--medium {
      @include squaresize(75px);
    }
    &--big {
      @include squaresize(90px);
    }
  }
  .wrapper-stickers {
    position: relative;
    top: 42px;
    left: 3px;
    display: flex;
    flex-direction: row;
    .avatar-hover {
      visibility: hidden;
    }
    .patient-avatar--xsmall {
      position: unset;
      margin: 0 0 0 -5px;
    }
  }
  &--info {
    margin: $spacing-1 auto 0 auto;
    color: $color-placeholder;
    font-size: $text-small;
    @include mq('tablet') {
      font-size: $text-default;
    }
  }
  // Special carroussel avatar prat
  .avatar-infos {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $black-opacity-half;
    color: $white;
    font-family: $font-source-sans-3;
    font-weight: 500;
    cursor: pointer;
  }
  &:hover {
    .avatar-infos {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $radius-full;
      @include mq('mobile-only') {
        display: none;
      }
    }
  }
  .wrapper-dots {
    position: absolute;
    bottom: -16px;
    display: flex;
    span {
      display: block;
      @include squaresize(8px);
      background-color: $color-grey--lite;
      border-radius: $radius-full;
      margin: 0 2px;
    }
    @include mq('tablet') {
      display: none;
    }
  }
}
