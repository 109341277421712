.link-account {
  .btn {
    padding: $spacer-12 $spacer-24;
    & + .btn {
      margin-top: $spacer-24;
    }
  }
  .wrapper-buttons {
    margin: 48px 0 64px;
    width: 90%;
  }
  strong {
    font-weight: 500;
  }
  .link {
    color: $login-link;
    margin: 0 auto;
  }
}

.profile-creation {
  &.link-account {
    padding-top: 150px;
  }
}
