.document-card {
  $icon-size: 25px;

  --transitioncubic: cubic-bezier(0.82, -0.02, 0.4, 1.18);
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
  @include mq('tablet') {
    height: var(--document-card-height);
  }
  &:hover .actions,
  &:focus-within .actions,
  &.selected .actions {
    transform: translateY(0);
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: $spacing-4 $spacing-3;
    transform: translateY(100%);
    background-color: $white;
    transition: all 300ms ease-out;
    z-index: 20;
    border-radius: 16px 16px 0 0;

    > *:not(:last-child) {
      margin-bottom: 8px;
    }

    // Override theme's minWidth: 150
    > .MuiButton-root {
      min-width: 0;
      width: 100%;
    }
  }
  .date {
    color: $color-text;
    font-weight: 500;
    margin: 0 0 $spacer-8;
  }
  .categorie {
    color: $white;
    display: block;
    padding: $spacer-8;
    position: absolute;
    bottom: 0;
    text-align: right;
    font-weight: 500;
    width: 100%;
    background-color: $color-placeholder;
  }
  .document-card__content {
    background: $grey-background;
    padding: $spacer-8;
    .name {
      color: $color-text;
      font-size: $text-mention;
      margin-top: $spacer-4;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: right;
    }
  }
  .document__ {
    &image {
      display: block;
      background-size: cover;
      width: 100%;
      height: 180px;
      background-position: center center;
    }
  }
  &--notification {
    position: relative;
    &:after {
      @extend %bullet-notification;
      @include squaresize(16px);
      top: 32px;
      right: 4px;
      z-index: 1;
    }
  }
}
/* Popup suppression de document */
.popup__content {
  .document__image {
    display: flex;
    background-position: center center;
    margin: $spacer-16 auto $spacer-32;
    border: 1px solid $grey-background;
    width: 250px;
    height: 250px;
    background-size: cover;
  }
}
