.careteam-section {
  h3 {
    margin: 0 auto;
    max-width: calc(#{$minicard-width} * 2 + (#{$minicard-gap} * 2));
  }
  .minicard_list {
    margin-top: 16px;

    &.empty {
      p {
        @include mq('mobile-only') {
          color: $white;
        }
      }
    }
  }
}

.minicard_list {
  @include mq('tablet') {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: calc(#{$minicard-width} * 2 + (#{$minicard-gap} * 2));
    width: 100%;
    z-index: 1;
  }
  &.empty {
    justify-content: center;
    @include mq('tablet') {
      display: block;
      text-align: center; // Make sure default text is centered if list is empty
    }
    p {
      text-align: center;
    }
  }
}
