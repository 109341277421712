@use "sass:math";

.circle-loader {
  $brand-success: color-ui(main);
  $loader-size: 7em;
  $check-height: $loader-size*0.5;
  $check-width: $check-height*0.5;
  $check-left: (math.div($loader-size, 6) + math.div($loader-size, 12));
  $check-thickness: 3px;
  $check-color: $brand-success;
  margin-bottom: $loader-size*0.5;
  border: 18px solid $black-opacity-twenty;
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
  @include mq('mobile-only') {
    border-width: 10px;
  }
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }
}
