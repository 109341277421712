.popup {
  &.dialog_appointment_series {
    ul.summary_appointment_series {
      li {
        position: relative;
        color: color-ui(accent);
        font-style: italic;
        border-bottom: none;
        padding: 0 0 0 $spacer-16;
        margin: $spacer-4 auto;

        &:before {
          content: '\2D'; // Hyphen caracter
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
