.nav {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  hr {
    border-top: none;
    border-color: colors(grey, 200);
  }
}
.nav__close {
  position: absolute;
  top: 14px;
  left: $spacing-4;
  font-size: 22px;
  color: $color-grey--medium-alt;

  &:hover {
    cursor: pointer;
  }
}
.nav__header {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin: $spacing-4 0 $spacing-6 0;
  display: flex;
  color: color-ui(main);
  height: $spacing-5;
  > svg {
    width: auto;
    height: 100%;
  }
  .brand {
    cursor: pointer;
  }
}
.nav__body {
  flex-grow: 1;
  font-size: $text-xl;
  color: $color-text;
  hr {
    margin: $spacer-24 0;
  }
  .nav-link {
    display: flex;
    align-items: center;
    margin-bottom: $spacer-24;
    margin-left: 0.75 * $spacer-24;
    font: 400 16px/30px $font-source-sans-3;
    cursor: pointer;
    fill: color-ui(main);
    svg,
    .amc-branding-container {
      display: flex;
      align-items: center;
      margin-right: 0.5 * $spacer-24;
      fill: inherit;
      @include squaresize(20px);
    }
    &.selected,
    &:hover {
      color: color-ui(accent);
      fill: color-ui(accent);
      p {
        color: inherit;
      }
    }
  }
  .nav-link--notification p {
    position: relative;
    &:after {
      @extend %bullet-notification;
      @include squaresize(12px);
      top: 10px;
      right: -18px;
    }
  }
}
.nav__footer {
  text-align: center;
  padding: $spacing-6;
}
