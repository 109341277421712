.paragraph {
  font-family: $font-source-sans-3;
  color: $color-text;
  fill: $color-text;
  font-size: $text-default;
  line-height: 1.4;
  &.with-icon {
    display: flex;
    align-items: flex-start;
    &.main {
      svg {
        fill: color-ui(main);
      }
    }
    &.accent {
      svg {
        fill: color-ui(accent);
      }
    }
    &.current {
      svg {
        fill: $color-secondary;
      }
    }
    &.alert {
      svg {
        fill: $error;
      }
    }
  }
  &.is-right {
    text-align: right;
  }
  svg {
    width: auto;
    height: 1.2em;
    margin-right: $spacing-2;
    margin-top: 2px;
    flex-shrink: 0;
    opacity: 0.8;
  }
  &.icon-right {
    flex-direction: row-reverse;
    svg {
      margin-left: 4px;
      margin-right: 0;
    }
  }
  &--small {
    font-size: $text-small;
  }
  &--mention {
    font-size: $text-mention;
  }
  &--large {
    font-size: $text-default;
    @include mq('tablet') {
      font-size: $text-large;
    }
  }
  &--xlarge {
    font-size: $text-xl;
  }
  & + .paragraph {
    margin-top: $spacer-8;
    @include mq('tablet') {
      margin-top: $spacer-12;
    }
  }
}

.title {
  font-weight: 700;
  color: color-ui(main);
  fill: color-ui(main);
  line-height: 1.2;
  font-size: $title-med;
  &.with-icon {
    display: flex;
    align-items: center;

    &.main {
      fill: color-ui(main);
    }
    &.accent {
      fill: color-ui(accent);
    }
    &.current {
      fill: $color-secondary;
    }
    &.alert {
      fill: $error;
    }
  }
  svg {
    margin-right: $spacer-8;
    flex-shrink: 0;
    opacity: 0.8;
  }
  &.icon-right {
    flex-direction: row-reverse;
    svg {
      margin-left: $spacer-8;
      margin-right: 0;
    }
  }
  &--xsmall {
    font-size: $title-xs;
  }
  &--small {
    font-size: $title-xs;
    @include mq('tablet') {
      font-size: $title-small;
    }
  }
  &--med {
    font-size: $title-xs;
    @include mq('tablet') {
      font-size: $title-med;
    }
  }
  &--medlarge {
    font-size: $title-med;
    @include mq('tablet') {
      font-size: $title-medlarge;
    }
  }
  &--large {
    font-size: $title-medlarge;
    @include mq('tablet') {
      font-size: $title-large;
    }
  }
  &--xlarge {
    font-size: $title-large;
    @include mq('tablet') {
      font-size: $title-xl;
    }
  }
}

.warning {
  color: $warning;
  font: 400 12px/16px $font-source-sans-3;
  padding-left: 17px;
  position: relative;
  margin-top: $spacer-4;
  &:before {
    content: '';
    position: absolute;
    background-image: url('/static/icons/ico_warning.png');
    left: 0px;
    height: 13px;
    width: 14px;
  }
}

.aide-support {
  color: $color-placeholder;
  font-weight: 500;
  font-size: $text-mention;
  @include mq('mobile-only') {
    text-align: center;
  }

  a {
    color: color-ui(main);
    font-weight: bold;
  }
}
