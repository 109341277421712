.drug-detail {
  background-color: $color-grey--background;
  @include mq('tablet') {
    display: grid;
    grid-template-areas: '. drugSummary drugInformations .';
    grid-template-columns: 1fr 300px minmax(300px, var(--searchcard-width)) 1fr;
    grid-column-gap: $spacing-4;
    padding: $spacing-6 0;
  }
  @include mq('desktop') {
    grid-template-columns: 1fr 420px minmax(300px, var(--searchcard-width)) 1fr;
  }
}
