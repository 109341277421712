.is-centered {
  min-height: var(--content-height);
  background: $bg-is-centered;
  max-width: 750px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .title {
    text-align: center;
  }

  .progress-round {
    margin-top: 5vmin;
  }

  /*  FORMS  */
  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .conditions {
      margin: 20px 0 10px;
      > a {
        text-decoration: underline;
        color: $color-text;
      }
      .forgot {
        color: colors(grey, 500);
        margin-top: $spacer-8;
        text-decoration: none;
        display: inline-block;
        &:hover {
          color: color-ui(main);
        }
      }
      & + .conditions {
        margin: 0;
      }
    }
    > a:last-of-type {
      align-self: stretch;
      padding: 10px;
    }
    > button[type='submit'] {
      margin: $spacer-32 0 $spacer-24;
    }
  }
}
