@keyframes icon {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes check {
  0% {
    width: calc(0.75 * var(--size));
    height: calc(0.75 * var(--size));
    border-width: 2px;
  }
  10% {
    width: calc(0.75 * var(--size));
    height: calc(0.75 * var(--size));
    opacity: 0.1;
    background: $black-opacity-twenty;
    border-width: 5px;
  }
  12% {
    width: calc(0.75 * var(--size));
    height: calc(0.75 * var(--size));
    opacity: 0.4;
    background: $black-opacity-ten;
    border-width: 0;
  }
  50% {
    width: var(--size);
    height: var(--size);
    background: $color--checkbox;
    border: 0;
    opacity: 0.6;
  }
  100% {
    width: var(--size);
    height: var(--size);
    background: $color--checkbox;
    border: 0;
    opacity: 1;
  }
}

.checkbox {
  display: flex;
  min-height: 30px;
  align-items: center;
  --size: 20px;
  color: $color-text;
  &.is-full {
    .label {
      width: 100%;
      align-items: center;
      display: grid;
      grid-template-areas: 'label . check';
      grid-template-columns: 1fr 16px 26px;
    }
  }
  .label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: $text-default;
    color: $color-text;
    margin: $spacer-4 0;

    &:hover,
    &:focus {
      .label__check {
        border: 2px solid $color--checkbox;

        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -3px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          -webkit-transition: all 0.25s ease;
          -o-transition: all 0.25s ease;
          transition: all 0.25s ease;
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          box-shadow: 0px 0px 0px 4px $green-opacity-fifteen;
          background-color: $green-opacity-fifteen;
        }
      }
    }

    .label__check {
      margin-left: $spacer-8;
      margin-right: 0;
      width: 20px;
      height: 20px;
      grid-area: check;
    }
  }
  &.is-reverse {
    .label {
      flex-direction: row-reverse;
      .label__check {
        margin-right: $spacer-8;
        margin-left: 0;
      }
    }
  }
  .label__checkbox {
    display: none;
  }
  .label__check {
    display: flex;
    border-radius: 5px;
    border: 2px solid $black-opacity-thirty;
    background: $white;
    vertical-align: middle;
    @include squaresize(20px);
    cursor: pointer;
    @extend %flexcenter;
    transition: border 0.3s ease;

    svg {
      opacity: 0.2;
      color: transparent;
      transition: opacity 0.3s 0.1s ease;
      -webkit-text-stroke: 3px $black-opacity-half;
    }
  }

  .label__checkbox:checked + .label__check {
    animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
    &:before {
      left: -1px;
    }

    svg {
      opacity: 1;
      @include squaresize(15px);
      transform: scale(0);
      color: white;
      -webkit-text-stroke: 0;
      animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
    }
  }
  & + .checkbox-link,
  & + .checkbox {
    margin-top: $spacer-4;
  }
}
/* Checkbox + link label*/
.checkbox-link {
  display: grid;
  grid-template-areas: 'label . check';
  grid-template-columns: 1fr 26px 26px;
  align-items: center;
  @include mq('tablet') {
    min-width: 390px;
  }
  > * {
    color: $color-text;
    grid-area: label;
    font-size: $text-default;
    margin: 0;
  }
  .link {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: $color-secondary;
    }
  }
  > .checkbox {
    margin-left: auto;
    grid-area: check;
    align-items: center;
  }
  & + .checkbox-link,
  & + .checkbox {
    margin-top: $spacer-4;
  }
}
