#hcd {
  .header,
  .profile-header {
    box-shadow: $shadow-wide-dark;
  }
}

.prat {
  @extend %content-minus-header;
  $infocard-width: minmax(300px, var(--searchcard-width));
  $rdv-width: 420px;
  // profile-content
  .profile-content {
    grid-template-columns: 1fr $infocard-width $rdv-width 1fr;
    position: relative;
    $title-color: color-ui(main);
    padding: 0 10px;
    @include mq('tablet') {
      display: grid;
      grid-template-areas: '. content-info content-right .';
      grid-template-columns: 1fr $infocard-width $rdv-width 1fr;
      box-shadow: var(--inner-shadow);
      margin-top: 0;
    }
    &__rdv {
      grid-area: content-right;
      position: relative;
      z-index: 20;
      margin-left: 20px;
      @include mq('tablet') {
        top: -54px;
        min-width: 400px;
      }
      @include mq('mobile-only') {
        display: none;
      }
      &--mobile {
        margin-top: calc(var(--header-and-pratbar) + 10px);
        display: block;
      }
    }
  }

  .profile-content__info {
    grid-area: content-info;
    position: relative;
    @include mq('tablet') {
      margin-right: 10px;
    }
    .card {
      &.share-prescription button {
        margin: auto;
      }
      //BLOC ACCESS CAB
      &.access {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas:
          'title'
          'address'
          'map'
          'info';
        width: 100%;
        padding-right: $spacer-16;
        @include mq('desktop') {
          grid-template-columns: minmax(330px, 1fr) minmax(200px, 285px);
          grid-template-rows: auto auto minmax(220px, auto);
          grid-template-areas:
            'title map'
            'address map'
            'info map';
          grid-column-gap: 8px;
          padding-right: 0;
        }
        .title-icon {
          grid-area: title;
          margin-bottom: 0;
        }
        .access__content--infos {
          grid-area: info;
          @extend .text-pre;

          > p {
            overflow-wrap: break-word;
            word-wrap: break-word;
          }
        }
        .access__content--address {
          grid-area: address;
          a {
            color: $color-secondary;
            text-decoration: underline;
          }
        }
      }
      // BLOC INFO
      &.information {
        .message {
          max-height: 750px;
          overflow-y: auto;
          // For some reason, I had to add vertical padding to hide scrollbar
          padding: 1px 0;

          > p {
            min-height: 15px;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }
        }
      }
      .cv-elem {
        margin: 2px 0;
        list-style: inside;
        word-break: break-word;
      }
    }
    //MAP
    .map {
      grid-area: map;
      .mapboxgl-ctrl-group > button {
        width: 20px;
        height: 20px;
      }
      #map {
        height: 200px;
        margin: $spacer-16 0 0;
        @include mq('desktop') {
          height: calc(100% + 24px);
          margin: -$spacer-12 0 0;
        }
      }
    }
    // LIST SUB CENTERS
    .center {
      width: 100%;
      padding-right: $spacer-16;
      @include mq('desktop') {
        padding-right: 0;
      }
      .map {
        display: none;
      }
      p .highlight {
        color: color-ui(accent);
      }
      .center-list {
        margin: $spacer-32 0;
      }
      .center-list-item {
        display: grid;
        grid-template-columns: 70px 1fr;
        grid-template-rows: minmax(70px, auto);
        align-items: center;
        grid-column-gap: $spacer-12;
        .avatar {
          @include squaresize(70px);
        }
        .btn {
          justify-content: start;
          text-align: left;
        }
        .spe {
          color: $color-grey--medium;
          margin-top: 0;
          span {
            margin: 0 0 0 $spacer-4;
          }
        }
        & + .center-list-item {
          margin-top: $spacer-32;
        }
      }

      // CONDITION WHEN MAP IS VISIBLE
      &.has-map {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas:
          'title'
          'text'
          'list'
          'map';
        @include mq('desktop') {
          grid-template-columns: minmax(330px, 1fr) minmax(200px, 285px);
          grid-template-rows: auto auto minmax(220px, auto);
          grid-template-areas:
            'title map'
            'text map'
            'list map';
          grid-column-gap: 16px;
          padding-right: 0;
        }
        > p {
          grid-area: text;
        }
        .wrapper-list {
          grid-area: list;
        }
        .map {
          display: block;
        }
      }
    }
  }

  // LIST OF PRAT ON CENTER CHILD
  .center-specialty {
    margin: $spacer-16 0 $spacer-24;
    h5 {
      font-size: 14px;
      font-weight: 500;
      color: $color-text;
      text-decoration: underline;
    }
  }
  .center-prat-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 90px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: center;
    align-items: start;
    text-align: center;
    margin: $spacer-16 0;
    @include mq('tablet') {
      grid-template-columns: repeat(auto-fit, 120px);
    }
    .avatar-size--big {
      height: 60px;
      width: 60px;
      margin: 0 auto $spacer-4;
      border: 2px solid $white;
      border-radius: 100%;
      transition: all 0.3s ease;
      @include mq('tablet') {
        height: 75px;
        width: 75px;
      }
    }

    span {
      display: block;
      transition: all 0.3s ease;
    }
    > img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-bottom: $spacer-8;
    }
    a:hover {
      .avatar-size--big {
        border-color: color-ui(main);
      }
      span {
        font-weight: 500;
      }
    }
  }
}
// BLOC WHEN PRAT IS NOT CLIENT
.bloc-prat-contact {
  grid-area: content-right;
  margin: $spacer-12 0;
  .wrapper {
    background-clip: padding-box;
    background-color: $white;
    height: auto;

    @include mq('tablet') {
      z-index: 20;
      position: sticky;
      top: calc(var(--header-bar) + 8px);
    }
    .contact-title {
      padding: $spacer-8;
      background: $gradient-dark;
      color: $white;
      font-size: $text-default;
      text-align: center;
      font-weight: 500;
    }
    .link {
      display: block;
      color: $white;
      font-size: $text-default;
      text-align: center;
      font-weight: 400;
      &:hover {
        text-decoration: none;
      }
    }
    .contact-content {
      padding: $spacer-24 $spacer-12;
      text-align: center;
      white-space: pre-wrap;

      .btn {
        margin: 16px auto 0;
        display: inline-block;
      }
    }
  }
}
// POPUP WHEN PRAT IS ON TLC WITHOUT RDV
.popup-tlc-info {
  color: $color-text;
  min-width: 350px;
  .info-attente {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: $spacer-16 0 $spacer-32;
  }
  .btn {
    position: relative;
    svg {
      fill: $white;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }
}
