//popup that appears when you click the star close to the name of the practitioner when on his profile page
.wrapper-favorites {
  max-width: 400px;
  > p {
    font-size: $text-small;
    font-style: italic;
    color: $color-grey--medium-alt;
    text-align: center;
  }
}
.favorite-selector {
  width: 100%;
  max-height: calc(100vh - 270px);
  overflow-y: scroll;
  margin: $spacer-24 0 0;
  padding: 0 20px;
  @include mq('tablet') {
    max-height: calc(65vh - 115px);
    overflow: auto;
  }
  @include mq('desktop') {
    max-height: 50vh;
  }
  &__item {
    display: grid;
    grid-template-columns: 38% 1fr;

    .switchers {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .favorite,
    .referring {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        @include squaresize(24px);
        margin-right: 8px;
      }
    }
    svg {
      @include squaresize(16px);
    }
    .patient-avatar.patient-card > .initial {
      font-size: $title-small;
    }
  }
}
