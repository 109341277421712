.cookie {
  color: $color-text;
  font: 400 14px/20px $font-source-sans-3;
  max-width: 600px;
  margin: 0 auto; /* Make sure it's centered if width gets limited by max-width */
  display: flex;
  flex-direction: column;
  &_module {
    text-align: center;
    display: grid;
    justify-items: center;
    @include mq('tablet') {
      grid-template-columns: 40px 1fr;
      grid-column-gap: 16px;
    }
    @include mq('mobile-only') {
      img {
        margin-bottom: $spacing-4;
      }
    }
  }
  &_edit {
    .btn {
      align-self: center;
    }
  }
  p {
    white-space: pre-wrap;
    a {
      color: color-ui(main);
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
    &.highlight {
      color: color-ui(main);
      font-size: $text-large;
      font-weight: 500;
      margin: $spacer-24 0 $spacer-12;
    }
  }
  .wrapper-buttons {
    margin-bottom: 0 !important;
  }

  p strong {
    font-weight: 500;
  }
  &_list {
    margin-bottom: $spacer-24;
    display: grid;
    grid-template-columns: 1fr 100px;
    grid-template-areas:
      'info  choice'
      'info-2 choice';
    .wrapper--radio-buttons {
      grid-area: choice;
      .radio-button {
        margin-top: 0;
      }
    }
    .info {
      grid-area: info;
    }
    .info-2 {
      grid-area: info-2;
    }
  }
}

.popup__content {
  .cookie {
    @include mq('mobile-only') {
      margin-bottom: $spacing-4; // Add space because we have 2 lines of buttons on mobile
    }

    .wrapper-buttons {
      @include mq('mobile-only') {
        flex-wrap: wrap;

        .btn--filled--accent {
          flex: none; // Make sure this button will take full width
          width: 100%;
          margin-bottom: $spacing-3;
        }
        .btn--default {
          padding-left: $spacing-4; // Has to be overwitten to allow the two buttons side by side
          padding-right: $spacing-4;
        }
      }

      @include mq('tablet') {
        .btn + .btn {
          margin-left: $spacing-4;
        }
      }

      @include mq('desktop') {
        flex-direction: row-reverse;
      }
    }
  }
}
