.cnil-disclaimer {
  &.small p {
    color: $color-placeholder;
    font-size: $text-mention;
    @include mq('mobile-only') {
      text-align: center;
    }
  }

  p,
  span {
    a {
      color: color-ui(main);
      font-weight: bold;
      cursor: pointer;
    }
  }
}
