.MuiDrawer-paperAnchorRight {
  @include mq('tablet') {
    width: 75%;
    max-width: 1000px;
  }
  @include mq('desktop') {
    width: 50%;
  }
  /* Specific drawer mobile on tlc */
  @include mq('mobile-only') {
    > svg {
      fill: $color-text;
      @include squaresize(32px);
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
    }
    .documents.page {
      margin: 0;
      .documents__list-wrapper {
        width: 100%;
      }
    }
  }
}
.documents.page {
  position: relative;
  .documents__content {
    display: flex;
    flex-direction: row;
  }
  .documents__list-wrapper {
    width: 100%;
    min-height: calc(100vh - 215px);

    .document-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(159px, 1fr));
      grid-gap: $spacing-5;
      @include mq('tablet') {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }
  }
  .document_empty {
    margin: 0 auto 100px;
    text-align: center;
    img {
      width: 200px;
      height: 200px;
      margin: $spacer-24 auto $spacer-24;
      @include mq('tablet') {
        width: 300px;
        height: 300px;
        margin: $spacer-32 auto $spacer-40;
      }
    }
    p {
      margin-bottom: $spacer-24;
    }
    .btn {
      display: inline-block;
    }
  }
  .storage-infos {
    position: absolute;
    top: 32px;
    right: 20px;
    @include mq('tablet') {
      top: 42px;
      right: 35px;
    }
  }
}

// UPLOAD DOCUMENT POPPER
.popup .document-upload {
  [name='profileId'] {
    margin-top: $spacer-24;
  }
  .upload-btn {
    &:hover {
      color: color-ui(main);
    }
  }

  .upload-zone {
    padding: $spacer-12;
    cursor: pointer;
    margin: 0;
    margin-bottom: $spacer-24;
    width: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DCDFE2FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    svg {
      width: unset;
      height: unset;
    }
    &--success {
      transition: all 5s ease;
      svg {
        padding: 15px;
        animation: disapear-n-appear 5s ease;
        @keyframes disapear-n-appear {
          0% {
            transform: scale(1);
          }
          10% {
            transform: scale(0);
          }
          50% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
    .text {
      padding: 20px;
      text-align: center;
      color: $color-text;
      overflow: hidden;

      p:first-child {
        font-size: $text-xl;
        white-space: pre-wrap;
      }
      > *:last-child {
        margin-top: 10px;
      }
      .light {
        color: colors(grey, 500);
      }
    }
  }

  .shareCheckbox {
    margin-top: $spacer-24;

    .label {
      flex-direction: row-reverse;
    }
    span {
      margin-left: 0 !important;
      margin-right: 10.5px !important;
    }
  }
  .prat {
    min-height: auto;
  }
  .shareCheckbox.disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.26);
    .label {
      cursor: not-allowed;
      &:hover ::before {
        box-shadow: none !important;
        background-color: transparent !important;
      }
    }
    span {
      border: 2px solid rgba(0, 0, 0, 0.26) !important;
      &:hover {
        border: 2px solid rgba(0, 0, 0, 0.26) !important;
        cursor: not-allowed;
      }
    }
  }
}
