.meter {
  display: flex;
  justify-content: space-between;

  .bar {
    width: $spacing-2; // Default width
    height: $spacing-7;
    border-radius: $spacing-3;
    background-color: $color-grey--background;

    &.active {
      background-color: color-ui(main);
    }
  }
}
