/* HTML, BODY
============================================================================= */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: 'Source Sans 3', sans-serif;
  padding: 0;
  margin: 0;
  font-size: $text-default;
  height: 100%;
  color: $color-text;
  line-height: 1.428571429; // Value coming from Bootstrap (20px / 14px = 1.428571429, 20px is the target line-height and 14px the default font-size)
  background-color: $color-grey--background;
  @media print {
    background-color: $white;
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
strong,
b {
  font-weight: 700;
}

body::backdrop {
  background-color: $white-cream;
}

// GENERAL RULES
.page {
  min-height: calc(100vh - var(--header-bar)); /* Content is full screen on mobile*/
  margin-top: var(--header-bar);
  @include mq('tablet') {
    margin-top: var(--header-bar);
    min-height: var(--content-height);
  }
  .title--med {
    padding: $spacer-32 0;
    margin: 0 auto;
    text-align: center;
    @include mq('tablet') {
      padding: $spacer-40 0;
    }
  }
}
