@use "sass:math";

%gradient {
  background: $gradient;
}

%title-text {
  font-weight: bold;
  font-size: $title-medlarge;
}
%flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

%bullet-notification {
  content: '';
  position: absolute;
  z-index: 20;
  background-color: color-ui(accent);
  border-radius: 100%;
}

%shadow {
  box-shadow: 0 1px 3px $black-opacity-twelve,
    0 1px 2px $black-opacity-twenty-four;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 1px 3px $black-opacity-twelve,
      0 1px 2px $black-opacity-twenty-four;
  }
}
@mixin shadows($level) {
  @if $level == 1 {
    box-shadow: 0px 2px 1px -1px $black-opacity-twenty,
      0px 1px 1px 0px $black-opacity-fourteen,
      0px 1px 3px 0px $black-opacity-twelve;
  } @else if $level == 2 {
    box-shadow: 0px 3px 1px -2px $black-opacity-twenty,
      0px 2px 2px 0px $black-opacity-fourteen,
      0px 1px 5px 0px $black-opacity-twelve;
  } @else if $level == 3 {
    box-shadow: 0px 3px 3px -2px $black-opacity-twenty,
      0px 3px 4px 0px $black-opacity-fourteen,
      0px 1px 8px 0px $black-opacity-twelve;
  } @else if $level == 4 {
    box-shadow: 0px 2px 4px -1px $black-opacity-twenty,
      0px 4px 5px 0px $black-opacity-fourteen,
      0px 1px 10px 0px $black-opacity-twelve;
  } @else if $level == 5 {
    box-shadow: 0px 3px 5px -1px $black-opacity-twenty,
      0px 5px 8px 0px $black-opacity-fourteen,
      0px 1px 14px 0px $black-opacity-twelve;
  } @else if $level == 6 {
    box-shadow: 0px 3px 5px -1px $black-opacity-twenty,
      0px 6px 10px 0px $black-opacity-fourteen,
      0px 1px 18px 0px $black-opacity-twelve;
  } @else if $level == 7 {
    box-shadow: 0px 4px 5px -2px $grey-opacity-twenty,
      0px 7px 10px 1px $grey-opacity-fourteen,
      0px 2px 16px 1px $grey-opacity-twelve;
  } @else if $level == 8 {
    box-shadow: 0px 5px 5px -3px $black-opacity-twenty,
      0px 8px 10px 1px $black-opacity-fourteen,
      0px 3px 14px 2px $black-opacity-twelve;
  } @else if $level == 9 {
    box-shadow: 0px 5px 6px -3px $black-opacity-twenty,
      0px 9px 12px 1px $black-opacity-fourteen,
      0px 3px 16px 2px $black-opacity-twelve;
  } @else if $level == 10 {
    box-shadow: 0px 6px 6px -3px $black-opacity-twenty,
      0px 10px 14px 1px $black-opacity-fourteen,
      0px 4px 18px 3px $black-opacity-twelve;
  } @else {
    background-color: $blue-navy !important;
    @warn "is not a valid shadow level";
  }
}

@mixin killondesk {
  @media screen and (min-width: $break-point--min-desk) {
    display: none !important;
  }
}
@mixin killon1023 {
  @media screen and (min-width: $break-point--max-tab) {
    display: none !important;
  }
}
@mixin killonmobile {
  @media screen and (max-width: $break-point--max-phone) {
    display: none !important;
  }
}

@mixin radius($direction, $size) {
  @if $direction == left {
    border-radius: $size 0 0 $size;
  } @else if $direction == right {
    border-radius: 0 $size $size 0;
  } @else if $direction == top {
    border-radius: $size $size 0 0;
  } @else if $direction == bottom {
    border-radius: 0 0 $size $size;
  } @else {
    background-color: red !important;
    @warn "is not a valid use of radius mixin use radius($direction, $size)";
  }
}

@mixin squaresize($size) {
  @if $size == null {
    @warn "is not a valid size";
  }
  height: $size;
  width: $size;
}
@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
@mixin brandname($heightorwidth, $size) {
  @if $heightorwidth == height {
    height: $size;
    width: $size * 3;
  } @else if $heightorwidth == width {
    width: $size;
    height: math.div($size, 3);
  } @else {
    fill: red !important;
  }
}

// the mixin regarding colors is in the _color file

%content-minus-header {
  margin-top: var(--header-bar);
  min-height: var(--content-height);
}
%shadow-1 {
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);
}
%shadow-2 {
  box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 0%, 0.12);
}
%shadow-3 {
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.15), 0 3px 6px hsla(0, 0%, 0%, 0.1);
}
%shadow-4 {
  box-shadow: 0 15px 25px hsla(0, 0%, 0%, 0.15), 0 5px 10px hsla(0, 0%, 0%, 0.5);
}
%shadow-5 {
  box-shadow: 0 20px 40px hsla(0, 0%, 0%, 0.2);
}
