/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */

/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */

*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: inherit; /* 2 */
}

/**
   * 1. Add text decoration inheritance in all browsers (opinionated).
   * 2. Add vertical alignment inheritence in all browsers (opinionated).
   */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
     ========================================================================== */

/**
   * Add the correct display in IE 9-.
   */

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
   * Correct the margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */

section h1 {
  margin: 0.67em 0;
}

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
   * Remove the list style on navigation lists in all browsers (opinionated).
   */

nav ol,
nav ul {
  list-style: none;
}

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
     ========================================================================== */

/**
   * Add the correct font size in all browsers.
   */

small {
  font-size: 80%;
}

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
     ========================================================================== */

/*
   * Change the alignment on media elements in all browers (opinionated).
   */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
   * Add the correct display in IE 9-.
   */

audio,
video {
  display: inline-block;
}

/**
   * Add the correct display in iOS 4-7.
   */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
   * Remove the border on images inside links in IE 10-.
   */

img {
  border-style: none;
}

/**
   * Hide the overflow in IE.
   */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
     ========================================================================== */

/**
   * Collapse border spacing
   */

table {
  border-collapse: collapse;
}

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
     ========================================================================== */

/**
   * Remove the margin in Firefox and Safari.
   */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
   * Inherit styling in all browsers (opinionated).
   */

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
   * Remove the inner border and padding in Firefox.
   */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
   * Restore the focus styles unset by the previous rule.
   */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
   * 1. Add the correct display in IE 9-.
   * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
   * 1. Remove the default vertical scrollbar in IE.
   * 2. Change the resize direction on textareas in all browsers (opinionated).
   */

textarea {
  overflow: auto; /* 1 */
  resize: vertical; /* 2 */
}

/**
   * 1. Add the correct box sizing in IE 10-.
   * 2. Remove the padding in IE 10-.
   */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
     ========================================================================== */

/*
   * Remove the tapping delay on clickable elements (opinionated).
   * 1. Remove the tapping delay in IE 10.
   */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation;
}

/**
   * Add the correct display in IE 10-.
   */

[hidden] {
  display: none;
}

/* ARIA (https://w3c.github.io/html-aria/)
     ========================================================================== */

/**
   * Change the cursor on busy elements (opinionated).
   */

[aria-busy='true'] {
  cursor: progress;
}

/*
   * Change the cursor on control elements (opinionated).
   */

[aria-controls] {
  cursor: pointer;
}

/*
   * Change the display on visually hidden accessible elements (opinionated).
   */

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
   * Change the cursor on disabled, not-editable, or otherwise
   * inoperable elements (opinionated).
   */

[aria-disabled] {
  cursor: default;
}
