.mini-open-tok {
  position: fixed;
  top: 61px;
  left: 11px;
  background-color: $black-opacity-eighty;
  border-radius: $spacing-1;
  overflow: hidden;
  z-index: 9999;

  .subscriber {
    width: 250px;
    height: 198px;
    div {
      height: 100%;
      &:not(.alert) + div {
        display: none;
      }
      &.alert {
        position: absolute; // Remove most of the style for the alert to make it more discret in the .mini-open-tok context
        top: $spacing-2;
        left: $spacing-2;
        width: auto;
        height: auto;
        background: transparent;
        z-index: 1;
        svg {
          margin: 0;
          fill: white;
        }
        span {
          display: none; // Hide the label of the alert
        }
      }
    }
  }
  .patient {
    width: 50px;
    height: 50px;
    top: 0px;
    position: absolute;
    display: none;
    div {
      height: 100%;
    }
  }
  .moveHandler {
    display: none;
    cursor: move;
    text-align: center;
  }
  &:hover:not(.is-dropping) .moveHandler {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 70px to allow the click on the bottom link in the content TODO: refactor moveHandler to avoid this hack
    // we may avoid this by using an icon "move" on top left to move the mini openTok
    bottom: 70px;
    background: linear-gradient(
      $white-opacity-hundred 0%,
      $white-opacity-half 35%,
      $white-opacity-zero 100%
    );
    z-index: 3;
  }
  &.is-dropping {
    border: 2px dashed $input-box-shadow;
  }
  .dropOverlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 70px to allow the click on the bottom link in the content TODO: refactor dropOverlay to avoid this hack
    bottom: 70px;
    z-index: 2;
    .placeholder {
      display: none;
      > svg {
        width: 100px;
        height: 100px;
      }
    }

    &.active {
      bottom: 0;
      .placeholder {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: $white-opacity-thirty;
        display: flex;
        > svg {
          fill: $input-box-shadow;
        }
      }
    }
  }
  @include mq('mobile-only') {
    bottom: 0;
    top: unset;
    right: 0;
    left: 0;
    width: 100%;
    background: $white;
    border-top: 1px solid $color-grey--background;
    .opentok-common {
      width: 40%;
    }
    .subscriber {
      width: auto;
      height: 95px;
    }
    .card {
      width: auto;
      height: auto;
    }
  }
}
