.wrapper-switch {
  padding: 2px;
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 16px;
    input {
      display: none;
    }
  }
  .slide-button {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    background-color: $color-grey--medium;
    border: 1px solid $color-grey--medium;
    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: -1px;
      bottom: -3px;
      transition: 0.2s;
      border-radius: $radius-full;
      box-shadow: 0px 2px 1px -1px $black-opacity-twenty,
        0px 1px 1px 0px $black-opacity-fourteen,
        0px 1px 3px 0px $black-opacity-twelve;
      background: $color-grey--xtra-lite;
      z-index: 10;
    }
    &:after {
      content: '';
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      bottom: -13px;
      left: -11px;
      transition: 0.2s;
    }
    &:hover,
    &:focus {
      &:after {
        background: $black-opacity-five;
      }
    }
  }
  input:checked + .slide-button {
    background-color: color-ui(accent, light);
    border: 1px solid color-ui(accent, light);
    &:before {
      transform: translateX(23px);
      background: color-ui(accent, dark);
    }
    &:after {
      transform: translateX(23px);
    }
  }
  input:focus + .slide-button {
    box-shadow: 0 0 1px $input-box-shadow;
  }
}
