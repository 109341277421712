$burger-height-mobile: 19px;
$burger-width-mobile: 24px;

$burger-height: 23px;
$burger-width: 26px;
$burger-bar-thickness: 3px;
$burger-color: $white;

.navigation-burger {
  min-width: $burger-width;
  height: $burger-height;
  position: relative;
  z-index: 6;
  cursor: pointer;
  .icon-burger {
    display: block;
    width: $burger-width;
    position: absolute;
    z-index: 11;
    top: ($burger-height - $burger-bar-thickness) * 0.5;
    border-bottom: $burger-bar-thickness solid $burger-color;
    border-radius: $radius-button;
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 11;
      width: 100%;
      display: block;
      border-radius: $radius-button;
      white-space: normal;
    }
    &:before {
      top: -($burger-height - $burger-bar-thickness - 2) * 0.5;
      border-bottom: $burger-bar-thickness solid $burger-color;
    }
    &:after {
      bottom: -($burger-height - $burger-bar-thickness) * 0.5 - $burger-bar-thickness +
        1;
      border-bottom: $burger-bar-thickness solid $burger-color;
    }
  }
  .menu-text {
    color: $white;
    padding-left: 38px;
    line-height: $burger-height;
    @include mq('mobile-only') {
      display: none;
    }
  }
  @include mq('mobile-only') {
    width: 35px;
    padding: 5px;
    span {
      width: $burger-width-mobile;
      top: ($burger-height-mobile - $burger-bar-thickness) * 0.5;
      &:before {
        top: -($burger-height-mobile - $burger-bar-thickness) * 0.5;
      }
      &:after {
        bottom: -($burger-height-mobile - $burger-bar-thickness) * 0.5 - $burger-bar-thickness;
      }
    }
  }
  &--notification {
    position: relative;
    &:after {
      @extend %bullet-notification;
      @include squaresize(10px);
      top: -6px;
      left: -4px;
    }
  }
}
