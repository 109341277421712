$default-spacer: 10px;

#qui-sommes-nous {
  /* Extend or add common class for all pages ? */
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $gradient;
  h2,
  h3,
  h4,
  p,
  span {
    white-space: pre-wrap; /* Needed for break lines on trads */
    @include mq('mobile-only') {
      white-space: normal;
    }
  }
}

.qsn-intro {
  padding: $default-spacer * 4 $default_spacer * 2;
  grid-template-areas: 'offre-intro';
  color: $white;
  margin: 0 auto;
  @include mq('tablet') {
    display: grid;
    grid-template-columns: minmax(300px, 800px);
    padding: $default-spacer * 8 $default_spacer * 2 $default_spacer * 4;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: relative;
    z-index: 1;
  }

  .title {
    color: $white;
    @include mq('mobile-only') {
      text-align: center;
    }
  }
  .subtitle {
    font-size: $text-large;
    margin: $default-spacer * 2 0 $default-spacer * 3;
    padding-left: 15px;
    display: block;
    border-left: 2px solid color-ui(accent);
  }
}

.qsn__wrapper {
  position: relative;
  background: transparent;
  min-height: 200vh;
  @include mq('tablet') {
    padding-top: 180px;
    &::before {
      content: '';
      position: absolute;
      top: -40px;
      left: 0;
      width: 100vw;
      height: 220px;
      background-image: url('/static/images/pages_illu/small-wave-fullscreen.svg');
      background-repeat: no-repeat;
      background-size: 100% 500px;
    }
  }
}

.qsn__content {
  margin: 0 auto;
  figure {
    background: $white;
  }
}

.qsn__title {
  font-size: $title-med;
  color: color-ui(main);
  margin-bottom: $spacer-24;
  @include mq('mobile-only') {
    text-align: center;
    font-size: $title-xs;
    line-height: 22px;
  }
}
.qsn__text {
  color: $color-text;
  font: 400 16px/25px $font-source-sans-3;
}

.qsn__content-bloc,
.qsn__location {
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-color: $white;
  padding: $default-spacer * 4 $default-spacer * 2;
  @include mq('tablet') {
    padding: $default-spacer * 7 $default-spacer * 2;
  }
  &.adventure {
    @include mq('tablet') {
      .qsn__content-bloc--text,
      .qsn__content-bloc--illu {
        z-index: 1; /* This is to make sure that content will go above the wave shape on the top */
      }
      .qsn__content-bloc--text {
        margin-top: -130px; /* This is to pull the content upper (white space due to the wave shape on the top) */
      }
      .qsn__content-bloc--illu {
        margin-top: -185px; /* This is to pull the content upper (white space due to the wave shape on the top) */
      }
    }
  }
  &.team {
    grid-template-columns: 250px minmax(min-content, 680px);
    grid-column-gap: 25px;
  }
  &.values,
  &.adventure {
    grid-template-columns: minmax(min-content, 680px) 250px;
    grid-column-gap: 100px;
  }
  &.adventure,
  &.team,
  &.values {
    @include mq('mobile-only') {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
    }
  }
  .qsn__content-bloc--illu,
  .qsn__content-bloc--illu img {
    width: 250px;
    height: 250px;
    display: block;
    background: $white; // provisoire
    position: relative;
    border-radius: 36px;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -15px;
      left: -22px;
      border-radius: 25px;
      background-color: $color-blue--light-alt;
      width: 248px;
      height: 265px;
      z-index: 0;
      box-shadow: 0px 3px 6px $black-opacity-twenty-nine;
    }
    .qsn__mentions {
      font: 600 12px/18px $font-source-sans-3;
      margin-top: 15px;
    }
  }
  .qsn__content-bloc--illu {
    @include mq('mobile-only') {
      margin: 36px 0;
    }
  }
  &:nth-child(even) {
    background: $color-blue--xlight-six;
  }
}

.qsn__content-bloc.values,
.qsn__content-bloc.adventure {
  .qsn__content-bloc--illu {
    margin-left: 25px;
    width: 250px;
    height: 250px;
    .qsn__mentions {
      width: 105%;
    }
  }
  li {
    margin-bottom: 26px;
    text-align: center;
    position: relative;
    width: 100%;
    @include mq('tablet') {
      width: calc(100% / 3);
    }
    .qsn__text {
      margin-top: $spacer-12;
    }
    svg {
      width: 80px;
      height: auto;
      fill: $white;
      padding: $spacing-3;
      margin-bottom: $spacing-3;
      background-color: color-ui(accent);
      border-radius: 50%;
    }
  }
}
.qsn__content-bloc.values {
  .qsn__content-bloc--text {
    justify-self: stretch;
    ul {
      display: flex;
      justify-content: space-around;
      margin-top: 60px;
      @include mq('mobile-only') {
        flex-direction: column;
        li {
          height: 160px;
        }
      }
    }
  }
}
.qsn__location {
  padding: 100px 0;
  @include mq('tablet') {
    grid-template-columns: 390px 385px;
    grid-column-gap: 115px;
  }
  .qsn__map {
    display: block;
    height: 365px;
    width: 100%;
    @include mq('mobile-only') {
      margin-bottom: 36px;
    }
  }
  .qsn__address {
    align-self: start;
    @include mq('mobile-only') {
      padding: 0 20px;
    }
  }
  ul {
    margin-top: $spacer-24;
    .qsn__station {
      padding-left: $spacer-32;
      margin-bottom: $spacer-12;
      position: relative;
      font: 400 16px/25px $font-source-sans-3;
      &::before {
        content: '9';
        width: 22px;
        height: 22px;
        background-color: $colors-metro-line-nine;
        color: $chalkboard;
        text-align: center;
        font: 700 14px/22px helvetica;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 0;
        letter-spacing: -1px;
      }
      &.m10 {
        &::before {
          background-color: $colors-metro-line-ten;
          content: '10';
        }
      }
    }
  }
}
