.maintenance__page {
  display: flex;
  justify-items: center;
  align-items: center;
  /* width: 100vh; */
  height: 100vh;
  text-align: center;
  background: $gradient;
  .maintenance__wrapper {
    max-width: 540px;
    margin: 0 auto;
    text-align: center;
    color: $white;
    font-size: 16px;
    line-height: 22px;
    padding: 20px;
    position: relative;
  }
  svg {
    max-width: 270px;
    height: auto;
  }
  .maintenance__illustration {
    position: absolute;
    top: -20px;
    left: -240px;
    svg {
      width: auto !important;
      height: 350px !important;
    }
    .action {
      position: absolute;
      left: -50px;
    }
    .animblob {
      fill: $white;
      filter: drop-shadow(10px 0 50px $color-blue--xlight-fifth);
    }
    @include mq('mobile-tablet') {
      display: none;
    }
  }
  .maintenance__title {
    display: block;
    font: 400 18px/24px $font-outfit;
    margin: $spacer-40 0 $spacer-24;
    @include mq('tablet') {
      font: 400 22px/28px $font-outfit;
    }
  }
}
