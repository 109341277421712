* {
  font-family: "Source Sans 3", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Outfit, sans-serif;
}

/* Text sizes */

$text-mention: 12px;
$text-small: 14px;
$text-default: 16px;
$text-large: 18px;
$text-xl: 20px;

/* Title sizes */

$title-xs: 20px;
$title-small: 25px;
$title-med: 28px;
$title-medlarge: 34px;
$title-large: 36px;
$title-xl: 40px;