.appointment-header {
  --animation-timing: 3s;
  --avatar-size: 50px;

  height: 100%;
  min-height: var(--pratbar);
  width: 100%;
  position: relative;
  background-color: white;
  @extend %flexcenter;
  color: $color-text;
  margin-top: 50px;
  box-shadow: 0 2px 4px $black-opacity-sixteen;
  z-index: 3;
  &.cancelled {
    background-color: color-ui(accent, xlight);
    & > div {
      border-left: 4px solid $error-xlight;
    }
    .time {
      color: $error;
    }
  }
  &.success,
  &.cancelled {
    margin-top: 80px;
    &.spe-vaccin {
      margin-top: 100px;
      @include mq('mobile-only') {
        margin-top: 154px;
      }
    }
  }
  .toaster-success,
  .toaster-cancelled {
    width: 100%;
    position: absolute;
    top: -30px;
    height: 30px;
    min-height: 30px;
    background-color: color-ui(accent);
    text-align: center;
    font: 500 14px/30px $font-source-sans-3;
    color: $white;
    padding: 0;
    border: none;
    &.just-cancelled {
      background-color: $success;
    }
    &.spe-vaccin {
      border-bottom: 2px solid color-ui(accent);
      background: $white;
      height: 48px;
      top: -48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: color-ui(accent);
      line-height: 20px;
      padding: 0 $spacer-24;
      .btn {
        margin-left: $spacer-16;
        min-width: 170px;
      }
      @include mq('mobile-only') {
        height: 103px;
        top: -103px;
        flex-direction: column;
        .btn {
          margin: 8px 0;
        }
      }
    }
  }
  .toaster-cancelled {
    background-color: $error;
  }
  & > div {
    border-left: 4px solid colors(grey, 200);
    padding: $spacer-4 $spacer-12;
    height: 100%;
    min-height: var(--pratbar);
    &.pratician {
      border-left: none;
    }
    @include mq('mobile-only') {
      border-width: 2px;
    }
  }
  .pratician {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-column-gap: 16px;
    position: relative;
    transition: 0.3s ease;
    @include mq('mobile-only') {
      flex-grow: 1;
    }
    &:not(.not-link):hover {
      cursor: pointer;
      .pratician__info {
        .name {
          color: $color-secondary;
          text-decoration: underline;
        }
      }
    }
    .patient-avatar {
      position: absolute;
      top: 35px;
      left: 4px;
    }
    .avatar {
      @include squaresize(var(--avatar-size));
      margin-right: $spacer-12;
      position: relative;
      transform: scale(1);
    }
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: $color-text;

      .name {
        font-size: $text-default;
        transition: 0.3s ease;
        font-weight: 500;
        display: flex;
        @include mq('tablet') {
          font-size: $text-large;
        }

        .care-status {
          @at-root svg#{&} {
            display: inline;
          }
          flex: none; // Make sure star size will always be respected (no shrink or grow)
        }
      }
      .speciality {
        font-size: $text-small;
      }
    }
  }
  & .reason {
    color: $color-text;
    font-size: $text-small;
    max-width: 430px;
    @include mq('mobile-only') {
      justify-content: start;
    }
    svg {
      @include squaresize(15px);
      margin-left: $spacer-4;
      @include mq('mobile-only') {
        @include squaresize(17px);
      }
    }
  }

  & .time {
    @include mq('mobile-only') {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      flex-grow: 0;
      width: 110px;
    }
    .hour {
      font-size: $text-large;
      font-weight: 500;
    }
    .date {
      font-size: $text-small;
      padding-left: 10px;
      @include mq('mobile-only') {
        padding-left: 0;
        text-align: center;
      }
    }
  }
  & .reason,
  & .time {
    @extend %flexcenter;
  }
}

.mention-sub {
  color: $color-grey--default;
  font-size: $text-small;
  font-style: italic;
  display: inline-block;
}
