.unconnected-page {
  background: $color-grey--background;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - var(--header-bar));
  min-width: 100vw;
  margin-top: var(--header-bar);
  .content {
    max-width: 550px;
    margin: 0 auto;
    padding: $spacer-40 $spacer-24;
    .bloc-validation {
      background: $white;
      border-radius: $radius-default;
      color: $color-text;
      text-align: center;
      padding: $spacer-24 $spacer-40;
      margin-top: $spacer-24;
      form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .btn {
        margin-top: $spacer-24;
        padding: $spacer-8 $spacer-32;
        font-size: $text-large;
      }
    }
    .title {
      font: 400 16px/20px $font-outfit;
      color: $color-text;
      margin-bottom: 20px;
    }
    .input--big {
      input {
        line-height: 45px;
        height: 45px;
        font-size: $title-small;
        width: 260px;
        letter-spacing: 3px;
      }
    }
    &.rdv-page__content {
      max-width: 750px;
      padding-top: 0;
    }
  }
  .brandname {
    color: color-ui(main);
    margin-bottom: $spacing-7;
    text-align: center;
    svg {
      width: auto;
      height: $spacing-7;
    }
  }
  .bloc-highlight--success {
    margin-top: $spacer-32;
  }
  .header-appointement {
    background: $white;
    border-radius: $radius-default;
    margin-bottom: $spacer-24;
    .patient {
      border-top: 4px solid colors(grey, 200);
      padding: $spacer-12 $spacer-24;
    }
    & + .btn {
      margin-bottom: $spacer-16;
    }
  }
  .appointment-header {
    margin-top: 0;
    box-shadow: none;
    display: grid;
    background-color: unset;
    grid-template-columns: minmax(250px, 400px) 1fr 180px;
    height: auto;
    @include mq('mobile-only') {
      grid-template-columns: 1fr 110px;
      .pratician__info .reason {
        display: block;
        text-align: left;
        svg {
          margin-top: -2px !important;
        }
      }
    }
    .time,
    .reason {
      display: flex;
      flex-direction: column;
      text-align: center;
      .date {
        padding: 0;
      }
      svg {
        margin-top: 2px !important;
      }
    }
    > div {
      padding: $spacer-12 $spacer-8;
      &.toaster-cancelled,
      &.toaster-success {
        padding: 0;
      }
    }
    .name {
      line-height: 18px;
    }
  }
}
