.bandeau-alert-covid {
  width: 100%;
  color: $white;
  text-align: center;
  background: linear-gradient(to right, $bandeau-default, $bandeau-alt);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 8px 8px 10px 2px;
  z-index: 1290;
  font: 500 14px/18px $font-source-sans-3;

  figure {
    width: 35px;
    flex-grow: 0; /* Make sure the width of the image will be respected */
    flex-shrink: 0; /* Make sure the width of the image will be respected */
    margin: 0 $spacer-8;
    @include mq('tablet') {
      width: 50px;
    }
    @include mq('desktop') {
      width: 55px;
    }
  }

  img {
    width: 100%;
    height: auto;
    padding: 3px;
    background-color: $white;
  }

  a {
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  & + .header {
    top: 50px;
  }

  @include mq('mobile-only') {
    height: 65px;
    font-size: $text-small;
    line-height: 1.3em;
    padding: $spacer-8 $spacer-16 $spacer-8 $spacer-8;
    & + .header {
      top: 65px;

      & + .home {
        margin-top: 100px;
      }
    }
  }
}

.bandeau-action {
  color: $color-text;
  background: $white;
  display: grid;
  grid-template-columns: 1fr 65px;
  grid-column-gap: $spacer-16;
  align-items: center;
  bottom: 80px;
  padding: $spacer-12;
  z-index: 1300;
  font: 500 12px/16px $font-source-sans-3;
  box-shadow: $shadow-condensed-dark;

  .wrapper-buttons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .avatar {
    @include squaresize(65px);
  }

  @include mq('mobile-only') {
    border-radius: 0 100px 100px 0;
    width: 340px;
    grid-template-areas:
      'text avatar'
      'buttons avatar';
    .avatar {
      grid-area: avatar;
    }
    p {
      grid-area: text;
      text-align: center;
      max-width: 270px;
      overflow: hidden;
      white-space: nowrap;

      span {
        font-weight: 400;
        font-size: 10px;
      }
    }
    .wrapper-buttons {
      grid-area: buttons;

      .btn {
        margin: $spacer-8 $spacer-4 0;
        padding: 0 $spacer-12;
        font-size: 10px;
        min-width: 100px;
      }
    }
  }
  @include mq('tablet') {
    top: 0;
    width: 100%;
    grid-template-columns: 1fr 350px;
    grid-column-gap: $spacer-16;
    align-items: center;
    height: 50px;
    padding: 0 $spacer-8;
    font: 500 12px/16px $font-source-sans-3;
    .avatar {
      display: none;
    }

    .wrapper-buttons {
      flex-direction: row;

      .btn {
        margin: 0 $spacer-4;
      }
    }
    & + .header {
      top: 50px;

      & + .rdv-page {
        margin-top: 50px;
      }

      & + .page,
      & + .appointment-header,
      & + .prat {
        margin-top: 100px;
      }
    }

    .killon1023 {
      display: none;
    }
  }
  @include mq('desktop') {
    grid-template-columns: 40px 1fr 350px;
    font: 500 14px/18px $font-source-sans-3;
    .avatar {
      @include squaresize(40px);
      margin-right: $spacer-12;
      display: block;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100vw;
  height: var(--header-bar);
  z-index: $zindex-headerbar;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $spacing-3;
  @extend %gradient;
  @include mq('tablet') {
    padding-right: $spacing-4;
    padding-left: $spacing-4;
  }
  @include mq('desktop') {
    padding-right: $spacing-5;
  }

  &:not(.connected) {
    .header__menu-toggle-logo {
      @include mq('mobile-only') {
        margin-right: 0;
      }
    }

    .header-links {
      .login__link {
        @include mq('mobile-only') {
          margin: 0;
        }
      }
    }
  }

  &.with-bcb-cta {
    .header-links {
      @include mq('mobile-only') {
        justify-content: space-between;
        flex-basis: 100%;
      }

      .cta-professional {
        span {
          @include mq('mobile-only') {
            display: block;
          }
        }
      }
    }
  }

  .is-demo {
    background-color: color-ui(accent);
    font: 600 14px/18px $font-source-sans-3;
    color: $white;
    border-radius: $spacer-8;
    position: absolute;
    width: 65px;
    height: 25px;
    right: -80px;
    bottom: -1px;

    &:after {
      content: 'DEMO';
      position: absolute;
      top: 4px;
      left: 12px;
    }

    @include mq('mobile-only') {
      bottom: 10px;
      width: 42px;
      height: 15px;
      font-size: 9px;
      right: -30px;
      &:after {
        left: 8px;
        top: -1px;
        bottom: auto;
      }
    }
  }
}

/* PAGES RESULTATS DE RECHERCHE */
.header--with-searchbar {
  &.connected {
    .header__menu-toggle-logo {
      .navigation-burger {
        .menu-text {
          @include mq('tablet-only') {
            display: none;
          }
        }
      }

      .header__logo {
        .brandname {
          @include mq('tablet') {
            display: none;
          }
        }
      }
    }
  }

  .header-links {
    .link {
      display: none;
    }

    > a {
      margin-left: $spacing-2;
      @include mq('desktop') {
        margin-left: $spacing-3;
      }

      .fullname {
        @include mq('mobile-tablet') {
          display: none;
        }
      }

      @include mq('tablet-only') {
        span {
          display: none;
        }
      }
    }

    .btn {
      display: none;

      &.header__avatar {
        display: inline-flex;
      }
    }

    .login__link {
      display: flex;
    }
  }
}

.header__menu-toggle-logo {
  display: flex;
  align-items: center;
  margin-right: $spacing-4;

  .brandname {
    height: 23px;
    color: $white;
    cursor: pointer;
    position: relative;
    transform: translateY(-10%); // Center vertically the logo
    @include mq('tablet') {
      height: 27px;
    }
  }

  & .logo--small {
    width: auto;
    height: 24px;
    @include mq('tablet') {
      height: 28px;
    }
  }
}

.header__cta-bcb {
  .btn {
    color: color-ui(main);
    background-color: $white;
    border: 2px solid transparent;
    font-weight: 600;

    &:hover {
      box-shadow: 0px 0px 6px 0px white; // Add glow effect on hover
    }
  }
}

/* Lien sur le header */
.header-links {
  display: flex;
  flex-direction: row;
  align-items: center;

  .header__avatar {
    @include squaresize(24px);
    @include mq('tablet') {
      @include squaresize(30px);
    }
  }

  > a {
    margin: 0 auto 0 $spacer-12;
    color: $white;
    font-size: $text-default;
    font-weight: 500;
    transition: all 0.2 ease;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    @include mq('mobile-only') {
      span {
        display: none;
      }
    }

    &.cta-professional {
      text-decoration: underline;
      margin-left: 0;
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;

      span {
        font-weight: 600;
      }

      .header__avatar {
        box-shadow: 0px 0px 6px 0px $white-opacity-hundred;
        border-radius: 50%;
      }
    }

    svg {
      fill: $white;
      @include squaresize(24px);
      @include mq('tablet') {
        margin-right: $spacer-8;
      }
      @include mq('desktop') {
        @include squaresize(16px);
      }
    }
  }

  .login__link {
    .patient-avatar {
      display: grid;
      grid-template-columns: 30px 4px 1fr;
      justify-content: center;
      /* flex-direction: row; */
      align-items: center;
      width: 100%;
      grid-template-areas: 'initial . name';
      opacity: 1;
      transition: all 0.2 ease;

      .initial {
        grid-area: initial;
        display: block;
        @include squaresize(30px);
        border: 1px solid $white;
        font-weight: 600;
        line-height: 30px;
        font-size: $text-xl;
        @include mq('tablet-only') {
          @include squaresize(26px);
          line-height: 26px;
          font-size: $text-large;
        }
      }

      .fullname {
        grid-area: name;
        font-weight: 500;
        margin: 0;
      }

      span {
        color: $white;
        font-family: $font-source-sans-3;
        font-size: $text-default;
      }

      &:hover {
        .initial {
          box-shadow: 0px 0px 6px 0px $white-opacity-eighty;
          border-radius: 50%;
        }

        .fullname {
          font-weight: 600;
          color: $white;
        }
      }

      @include mq('mobile-only') {
        .fullname {
          display: none;
        }
      }
    }
  }
}

/* PAGES OFFRES */
.header--pro {
  .return-btn {
    color: $white;
    padding-bottom: 0; // Align vertically return button with Maiia logo
  }

  .brandname {
    svg {
      width: auto;
      height: 100%;
    }
  }

  @include mq('mobile-only') {
    button {
      .MuiButton-endIcon {
        display: none; // Hide this icon as there is not enough space on mobile for everything to fit in one line
      }
    }
  }
}
