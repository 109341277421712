@use "sass:math";

$default-spacer: 10px;
$title: color-ui(main);
$subtitle: $color-text;

#offers {
  /* Extend or add common class for all pages ? */
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $gradient;
  h2,
  h3,
  h4,
  p,
  span {
    white-space: pre-wrap; /* Needed for break lines on trads */
    @include mq('mobile-only') {
      white-space: normal;
    }
  }
}

.offers-intro {
  color: $white;
  padding: $default-spacer * 5 $default_spacer * 2 0;
  margin: 0 auto;
  @include mq('tablet') {
    display: grid;
    width: 100vw;
    justify-content: center;
    align-items: center;
    grid-template-columns: minmax(300px, 750px) 0;
    grid-template-areas: 'intro wave';
    grid-column-gap: 10px;
    position: relative;
    z-index: 1;
  }

  .btn {
    display: inline-block;
  }
  .title {
    color: $white;
    font-weight: 400;
    @include mq('tablet') {
      line-height: 32px;
    }
    span {
      font-size: $text-xl;
      line-height: 32px;
      display: block;
      font-weight: 700;
      @include mq('tablet') {
        font-size: $title-med;
        line-height: 32px;
      }
    }
  }
  .subtitle {
    margin: $default-spacer * 2 0 $default-spacer * 3;
    display: block;
  }
  .btn {
    margin: 0 auto;
  }
}
.offers-intro__text {
  text-align: center;
}

.offers__wrapper {
  position: relative;
  background: $white;
  @include mq('tablet') {
    clip-path: url(#wave-shape); // Create a mask of the svg on the block
    -webkit-clip-path: url(#wave-shape);
    top: -150px;
    margin-bottom: -150px;
  }

  .clip-svg {
    @include mq('mobile-only') {
      display: none;
    }
  }
}
.offers__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mq('tablet') {
    padding: 100px $default-spacer * 4 150px;
    background: $color-blue--xlight-six;
    max-width: 755px;
    position: relative;
    left: 0;
    right: 0;
    top: 110px;
    margin: 0 auto;
  }
}
.offers__title {
  color: $title;
  text-align: center;
  padding: $spacer-40 $spacer-24 $spacer-24;
  @include mq('tablet') {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 50px;
  }
}
.offers__subtitle {
  color: $subtitle;
  margin-top: $spacer-12;
  padding: $spacer-40 $spacer-24 $spacer-24;
  text-align: center;
  @include mq('tablet') {
    font-size: 18px;
  }
}
.offers__content-bloc {
  display: grid;
  padding: $spacer-40 $spacer-24 $spacer-32;
  background: $color-blue--xlight-six;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'title'
    'illu'
    'text';
  @include mq('mobile-only') {
    &:nth-child(3) {
      background: $white;
    }
  }
  @include mq('tablet') {
    margin-bottom: $spacer-40;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'title illu'
      'text illu';
    grid-column-gap: 20px;
    align-items: center;
  }

  .offers__title,
  .offers__subtitle {
    margin: 0;
    text-align: center;
    padding: 0;
    @include mq('tablet') {
      text-align: left;
    }
  }

  &.is-center {
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'title'
      'illu'
      'text';
    @include mq('tablet') {
      grid-template-columns: 40% 60%;
      grid-column-gap: 0;
      grid-template-areas:
        'illu title'
        'illu text ';
    }
    .offers__content-bloc--illu figure {
      text-align: right;
    }
  }
}

.offers__content-bloc--title {
  grid-area: title;
  align-self: end;
}
.offers__content-bloc--text {
  grid-area: text;
  align-self: start;
}
.offers__content-bloc--illu {
  grid-area: illu;
  align-self: center;
}
