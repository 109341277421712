/* UTILS */
.grayscale {
  filter: grayscale(1);
}
.killondesk {
  @include killondesk;
}
.killon1023 {
  @include killon1023;
}
.killonmobile {
  @include killonmobile;
}

.hide {
  display: none;
}

.text-pre {
  white-space: pre-wrap;
}

//TODO uniformiser avec tag--ko
.ko {
  text-decoration: line-through;
  color: colors(red, 300);
}
.gradient {
  @extend %gradient;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clip-svg {
  width: 0; // Hide the svg used for path clipping
  height: 0;
}
