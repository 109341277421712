.back-to-top {
  position: fixed;
  // Pour changer la position du bouton 'back to top'
  bottom: 5vh;
  right: 5vh;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: color-ui(accent);
  cursor: pointer;
  transition: all 0.3s ease;
  > div {
    background: color-ui(accent);
    border-radius: $radius-full;
    @include squaresize(40px);
    margin-bottom: $spacer-12;
    transition: all 0.3s ease;
  }
  svg {
    fill: $white;
    @include squaresize(40px);
    transform: rotate(180deg);
  }
  span {
    font: 500 14px/18px $font-source-sans-3;
  }
  // Important needed to overide when "back-to-top" is on drug pages if there is a diferent
  // hover for drug pages, remove !important here and copy/past this code is "drugs.scss"
  &:hover,
  &:focus {
    color: $color-text !important;
    div {
      background: $color-text !important;
    }
  }
}
